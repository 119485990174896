import React, {useState, useEffect} from "react";
// import dummyManager from "../../assets/dummy/dummy_manager";
import Button from "@mui/material/Button";
import {TailSpin} from "react-loading-icons";
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

import {TextField} from "@mui/material";
import {centerApi} from "../../../src/api";

import DialogAlert from "../../components/modal/DialogAlert";
import CenterImageEdit from "../../components/center/CenterImageEdit";

const CompanyModify = () => {
  const [centerImageSrc1, setCenterImageSrc1] = useState(null);
  const [centerImageFile1, setCenterImageFile1] = useState(null);

  const [centerImageSrc2, setCenterImageSrc2] = useState(null);
  const [centerImageFile2, setCenterImageFile2] = useState(null);

  const [centerImageSrc3, setCenterImageSrc3] = useState(null);
  const [centerImageFile3, setCenterImageFile3] = useState(null);

  const [pickupEmployeeImageSrc, setPickupEmployeeImageSrc] = useState(null);
  const [pickupEmployeeImageFile, setPickupEmployeeImageFile] = useState(null);

  const [cookies] = useCookies();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    ceo_name: "",
    ceo_phone_number: "",
    address: "",
    address_detail: "",
    center_phone_number: "",
    manager_phone_number: "",
    license_number: "",
    can_inspection_type: [],
    // can_inspection_car_number_hour: 0,
    can_inspection_car_number_day: 0,
    pickup_employee_name: "",
    pickup_employee_phone_number: "",
    pickup_employee_image: null,
    center_image1: null,
    center_image2: null,
    center_image3: null,
    status: "",
  });

  const [loading, setLoading] = useState(true);
  const [modify, setModify] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);

      const {status, data} = await centerApi.getCenterDetail(
        cookies.access_token,
      );

      if (status === 200) {
        console.log(data);
        setInputs(data);
        setCenterImageSrc1(data.center_image1);
        setCenterImageSrc2(data.center_image2);
        setCenterImageSrc3(data.center_image3);
        setPickupEmployeeImageSrc(data.pickup_employee_image);
      }
    } catch (e) {
      alert(e.response);
    } finally {
      setLoading(false);
    }
  };

  const modifyMode = () => {
    setModify(!modify);
  };

  // Handle Input
  const onChange = event => {
    const {value, name} = event.target;
    if (name === "pickup_employee_name") {
      setInputs({
        ...inputs,
        [name]: value,
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value.replace(/[^0-9]/g, ""),
      });
    }
  };

  // submit signup
  const submit = async event => {
    event.preventDefault();

    const formData = new FormData();

    if (
      event.target.center_image1.files &&
      event.target.center_image1.files[0]
    ) {
      formData.append("center_image1", event.target.center_image1.files[0]);
    } else {
      if (!centerImageSrc1) {
        formData.append("center_image1", new File([], ""));
      }
    }
    if (
      event.target.center_image2.files &&
      event.target.center_image2.files[0]
    ) {
      formData.append("center_image2", event.target.center_image2.files[0]);
    } else {
      if (!centerImageSrc2) {
        formData.append("center_image2", new File([], ""));
      }
    }
    if (
      event.target.center_image3.files &&
      event.target.center_image3.files[0]
    ) {
      formData.append("center_image3", event.target.center_image3.files[0]);
    } else {
      if (!centerImageSrc3) {
        formData.append("center_image3", new File([], ""));
      }
    }
    if (
      event.target.pickup_employee_image.files &&
      event.target.pickup_employee_image.files[0]
    ) {
      formData.append(
        "pickup_employee_image",
        event.target.pickup_employee_image.files[0],
      );
    } else {
      if (!pickupEmployeeImageSrc) {
        formData.append("pickup_employee_image", new File([], ""));
      }
    }
    for (let key in inputs) {
      if (
        key !== "center_image1" &&
        key !== "center_image2" &&
        key !== "center_image3" &&
        key !== "username" &&
        key !== "pickup_employee_image" &&
        key !== "can_inspection_type"
      ) {
        formData.append(key, inputs[key]);
      }
    }

    try {
      const res = await centerApi.modifyMypage(formData, cookies.access_token);

      setLoading(true);
      if (res.status === 200) {
        setModalContent("수정이 완료되었습니다.");
      } else {
        setModalContent("잘못된 접근입니다.");
      }
      setModalOpen(true);
    } catch (err) {
      setModalContent("잘못된 접근입니다.");
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={submit}>
        <DialogAlert
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          content={modalContent}
        />
        <main className="max-w-5xl mx-auto mt-[3rem]">
          <div className="">
            <div className="flex flex-row items-center justify-between py-5 border-b-2 border-b-black">
              <p className="text-xl font-bold ">업체정보 설정</p>
            </div>
            <div className="">
              <div className="max-w-4xl mx-auto">
                <div className="border-b-2 border-b-gray">
                  <section className="flex flex-row items-center justify-between py-8 border-b-2 border-b-gray">
                    <p className="text-lg font-bold">{inputs.name}</p>
                    <div className="space-x-2">
                      {!modify ? (
                        <Button variant="outlined" onClick={modifyMode}>
                          수정
                        </Button>
                      ) : (
                        <Button variant="outlined" disabled>
                          수정
                        </Button>
                      )}
                    </div>
                  </section>
                  <section className="py-3">
                    <div className="py-2 text-xs opacity-50 text-black-500">
                      기본정보
                    </div>
                    <div className="flex flex-row font-bold">
                      <div className="flex-initial w-32">
                        <ul>
                          <li className="pb-5 text-sm">담당자 전화번호</li>
                          <li className="pb-3 text-sm">업체 소개사진</li>
                        </ul>
                      </div>
                      <div className="flex-auto">
                        <ul>
                          <li className="pb-1 text-xs">
                            <TextField
                              name="manager_phone_number"
                              className="w-64"
                              size="small"
                              value={inputs.manager_phone_number}
                              onChange={onChange}
                              inputProps={{
                                maxLength: 12,
                                style: {
                                  height: "30px",
                                  paddingTop: "0",
                                  paddingBottom: "0",
                                  margin: "0",
                                  disableUnderline: false,
                                },
                              }}
                            ></TextField>
                          </li>
                          <li>
                            <ul className="flex flex-row w-32 pb-1 mt-4 gap-x-3">
                              <li>
                                <CenterImageEdit
                                  imageSrc={centerImageSrc1}
                                  setImageSrc={setCenterImageSrc1}
                                  imageFile={centerImageFile1}
                                  setImageFile={setCenterImageFile1}
                                  name="center_image1"
                                  setModalContent={setModalContent}
                                  setModalOpen={setModalOpen}
                                />
                              </li>
                              <li>
                                <CenterImageEdit
                                  imageSrc={centerImageSrc2}
                                  setImageSrc={setCenterImageSrc2}
                                  imageFile={centerImageFile2}
                                  setImageFile={setCenterImageFile2}
                                  name="center_image2"
                                  setModalContent={setModalContent}
                                  setModalOpen={setModalOpen}
                                />
                              </li>
                              <li>
                                <CenterImageEdit
                                  imageSrc={centerImageSrc3}
                                  setImageSrc={setCenterImageSrc3}
                                  imageFile={centerImageFile3}
                                  setImageFile={setCenterImageFile3}
                                  name="center_image3"
                                  setModalContent={setModalContent}
                                  setModalOpen={setModalOpen}
                                />
                              </li>
                            </ul>
                            <div className="flex-1 text-xs text-primary">
                              <p>
                                업로드된 사진은 업체 조회 시 대표 사진으로
                                사용됩니다.
                              </p>
                              <p>
                                업로드 가능 용량 5MB이하 (적용파일 JPG, PNG, BMP
                                / 최대 3개 이미지 업로드 가능)
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>

                  <section className="my-3 ">
                    <div className="flex flex-row">
                      <div className="flex-initial w-32 font-bold">
                        <ul>
                          <li className="pb-5 text-sm">픽업직원 이름</li>
                          <li className="pb-5 text-sm">픽업직원 전화번호</li>
                        </ul>
                      </div>
                      <div>
                        <div className="pb-1 text-xs">
                          <TextField
                            className="w-64"
                            size="small"
                            onChange={onChange}
                            name="pickup_employee_name"
                            value={inputs.pickup_employee_name}
                            inputProps={{
                              maxLength: 9,
                              style: {
                                height: "30px",
                                paddingTop: "0",
                                paddingBottom: "0",
                                margin: "0",
                                disableUnderline: false,
                              },
                            }}
                          ></TextField>
                        </div>
                        <div className="pb-1 text-xs">
                          <TextField
                            className="w-64"
                            size="small"
                            onChange={onChange}
                            name="pickup_employee_phone_number"
                            value={inputs.pickup_employee_phone_number}
                            inputProps={{
                              maxLength: 12,

                              style: {
                                height: "30px",
                                paddingTop: "0",
                                paddingBottom: "0",
                                margin: "0",
                                disableUnderline: false,
                              },
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="my-3 ">
                    <div className="flex ">
                      <div className="flex-initial w-32 font-bold">
                        <div className="pb-5 text-sm">픽업직원 사진</div>
                      </div>
                      <div className="justify-self-end">
                        <CenterImageEdit
                          imageSrc={pickupEmployeeImageSrc}
                          setImageSrc={setPickupEmployeeImageSrc}
                          imageFile={pickupEmployeeImageFile}
                          setImageFile={setPickupEmployeeImageFile}
                          name="pickup_employee_image"
                          setModalContent={setModalContent}
                          setModalOpen={setModalOpen}
                        />
                      </div>
                    </div>
                  </section>
                  {/* <section className="my-3 ">
                    <div className="mt-7">
                      <p className="pb-1 text-sm font-bold">
                        검사 종류 및 시간 당 검사기능 차량 수
                      </p>
                      <div className="flex gap-x-5">
                        <section>
                          <ul>
                            <li className="py-3 text-xs">
                              - 자동차 정기 / 종합검사
                            </li>
                            <li className="pb-3 text-xs">
                              - 시간 당 검사기능 차량 수
                            </li>
                          </ul>
                        </section>
                        <section className="flex flex-col justify-end">
                          <ul>
                            <li className="pb-2.5 text-xs">
                              <div className="flex justify-between border-[1px] border-gray-400 w-20">
                                <input
                                  className="w-12 pl-4"
                                  name="can_inspection_car_number_hour"
                                  value={inputs.can_inspection_car_number_hour}
                                  onChange={onChange}
                                  maxLength={4}
                                ></input>
                                <div className="w-10 text-center bg-grayBackground">
                                  /시간
                                </div>
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    </div>
                  </section> */}
                </div>
                {modify ? (
                  <div className="flex flex-row justify-end my-4">
                    <div className="space-x-2">
                      <Link to="/company">
                        <Button variant="outlined" onClick={modifyMode}>
                          취소
                        </Button>
                      </Link>

                      <Button variant="contained" type="submit">
                        저장
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </main>
      </form>
      {loading && (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full">
          <TailSpin className="mx-auto" stroke="#999" />
        </div>
      )}
    </>
  );
};

export default CompanyModify;
