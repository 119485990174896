import dayjs from "dayjs";

export const getDateDiff = (date1, date2) => {
  const diffDate = date1.getTime() - date2.getTime();

  return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
};
export const getDateDiffRound = (date1, date2) => {
  const diffDate = date1.getTime() - date2.getTime();

  return Number((diffDate / (1000 * 60 * 60 * 24)).toFixed(0)); // 밀리세컨 * 초 * 분 * 시 = 일
};
export const getDateDiffAbsRound = (date1, date2) => {
  const diffDate = date1.getTime() - date2.getTime();

  return Number(Math.abs(diffDate / (1000 * 60 * 60 * 24)).toFixed(0)); // 밀리세컨 * 초 * 분 * 시 = 일
};
export const setDateTo00 = date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};
export const setDateTo24 = date => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};

export const getDateFromDaysAgo = daysago => {
  const today = new Date();
  const oldDays = new Date(today - 3600000 * 24 * daysago);
  return oldDays;
};

export const getDateJacarFormat = strDate => {
  if (strDate) {
    const date = new Date(strDate);

    return dayjs(date).format("YYYY-MM-DD");
  }
  return "";
};

export const getDateJacarFormat2 = strDate => {
  if (strDate) {
    const date = new Date(strDate);

    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  }
  return "";
};
