import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

const AlertDialog = ({
  open,
  handleClose,
  title,
  content,
  buttonText1,
  buttonText2,
  handleButton1Click,
  handleButton2Click,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{p: 1, py: 1.5}}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="error" onClick={handleButton1Click}>
            {buttonText1}
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleButton2Click}
            autoFocus
          >
            {buttonText2}
          </Button>
        </DialogActions>
      </Box>
      {loading && (
        <Box
          position={"absolute"}
          sx={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default AlertDialog;
