import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {Link} from "react-router-dom";

const LinkAlert = ({open, handleClose, content, link}) => {
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"JACAR 알림"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{margin: 1, width: "80px"}}
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            취소
          </Button>
          <Link to={link}>
            <Button
              sx={{margin: 1, width: "80px"}}
              variant="contained"
              onClick={handleClose}
              autoFocus
            >
              이동
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkAlert;
