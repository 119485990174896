import {TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider, koKR} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const JacarDatePicker = ({
  date,
  setDate,
  disablePast = false,
  minDate,
  shouldDisableDate,
  localStorageName = null,
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        koKR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <DatePicker
        value={date ? date : null}
        inputFormat={"YYYY-MM-DD"}
        onChange={newValue => {
          if (newValue) {
            const dateStr = dayjs(newValue).format("YYYY-MM-DD");
            setDate(dateStr);

            if (localStorageName) {
              localStorage.setItem(localStorageName, dateStr);
            }
          }
        }}
        minDate={minDate}
        disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
        renderInput={params => {
          return (
            <TextField
              sx={{
                color: "black",
                width: "100%",
                svg: {fontSize: "1.2rem"},
                input: {height: "10px"},
              }}
              {...params}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default JacarDatePicker;
