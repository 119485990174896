import {useEffect, useRef, useState} from "react";

import {Box, Button, TextField, Modal, Typography, Stack} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Logo from "../../assets/logo/logo_nav.png";
import {JACAR_API_URL} from "../../constants";
const ReservationLinkModal = ({
  open,
  setOpen,
  handleClose,
  handleSend,
  driver_phone,
  representative_phone,
  uuid,
}) => {
  const copyTextRef = useRef();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    backgroundColor: "#ffffff",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  const PHONE_TYPE_DRIVER = 0;
  const PHONE_TYPE_REPRESENTATIVE = 1;
  const [phoneType, setPhoneType] = useState(PHONE_TYPE_DRIVER);
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    setPhoneNumber(driver_phone);
    setPhoneType(PHONE_TYPE_DRIVER);
  }, [open]);
  const handleSubmit = e => {
    e.preventDefault();
    handleSend(phoneNumber);
    setOpen(false);
  };

  const copyText = () => {
    setTimeout(async () => {
      await navigator.clipboard.writeText(copyTextRef.current.value);
      alert("Copied the text: " + copyTextRef.current.value);
    }, 100);
  };
  return (
    <Modal open={open} sx={{backgroundColor: "white"}} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={2}
            gap={1}
          >
            <MailOutlineIcon fontSize={"large"} />
            <Typography id="modal-modal-title" variant="h5" component="h6">
              예약링크 전송
            </Typography>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={1}
            gap={1}
          >
            <Typography minWidth={"100px"}>
              {phoneType === PHONE_TYPE_DRIVER ? "운전자 번호" : "대표 번호"}
            </Typography>
            <Stack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              direction={"row"}
              gap={"20px"}
            >
              <TextField
                name="com_ceo_phone_number"
                type="tel"
                variant="outlined"
                size="medium"
                value={phoneNumber ? phoneNumber : ""}
                onChange={event => setPhoneNumber(event.target.value)}
                required
                fullWidth
              />
              {representative_phone && (
                <Button
                  sx={{width: "230px"}}
                  variant="contained"
                  onClick={() => {
                    if (phoneType === PHONE_TYPE_DRIVER) {
                      setPhoneType(PHONE_TYPE_REPRESENTATIVE);
                      setPhoneNumber(representative_phone);
                    } else {
                      setPhoneType(PHONE_TYPE_DRIVER);
                      setPhoneNumber(driver_phone);
                    }
                  }}
                >
                  {phoneType === PHONE_TYPE_DRIVER
                    ? "대표번호로 전환"
                    : "운전자번호로 전환"}
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={4}
            gap={1}
          >
            <Typography minWidth={"100px"}>생성 링크</Typography>

            <Stack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              direction={"row"}
            >
              <TextField
                inputProps={{ref: copyTextRef}}
                type="text"
                value={JACAR_API_URL + "business/?id=" + uuid}
                readOnly
                size="medium"
                sx={{width: "70%"}}
              />

              <Button
                sx={{width: "50px"}}
                variant="contained"
                onClick={copyText}
              >
                복사
              </Button>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <img width={"70px"} src={Logo} alt="logo" />
            <Stack direction={"row"} gap={1}>
              <Button variant="outlined" onClick={handleClose}>
                취소
              </Button>
              <Button type="submit" variant="contained">
                전송
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};

export default ReservationLinkModal;
