import img1 from "../assets/images/notice/img_notice_1.jpg";
import img2 from "../assets/images/notice/img_notice_2.jpg";
import img4 from "../assets/images/notice/img_notice_4.jpg";

import Carousel from "react-material-ui-carousel";
import {Paper, Stack, Typography} from "@mui/material";

import NoticeList from "../components/notice/NoticeTable";

const Main = () => {
  return (
    <>
      <main className="max-w-5xl mx-auto">
        <Stack className="mt-12">
          <Slider />
        </Stack>
        <Stack className="mt-2 notice_wrap">
          <Stack className="notice_title ">
            <Stack className="mt-5 mb-1 text-sm">
              <Typography textAlign="center" variant="h6" fontWeight="600">
                (주)자카에서 협력업체님들께 공지드립니다.
              </Typography>
            </Stack>
          </Stack>
          <NoticeList />
        </Stack>
      </main>
    </>
  );
};

export default Main;

const Slider = () => {
  let images = [img1, img2, img4];

  return (
    <Carousel
      className="h-80"
      animation={"slide"}
      duration={2000}
      interval={6000}
      indicators={false}
    >
      {images.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

const Item = props => {
  return (
    <Paper>
      <img src={props.item} alt={props.item} />
    </Paper>
  );
};
