import React, {useState, useEffect, useContext} from "react";
import {useCookies} from "react-cookie";
// import crypto from "crypto";

import {LoginContext} from "../../components/contexts/LoginContext";
import {
  centerApi,
  pastInspectionApi,
  paymentApi,
  pointApi,
} from "../../../src/api";
import dayjs from "dayjs";

// material-ui
import {
  Stack,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGrid,
  gridClasses,
  koKR,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

// ==================================== PAYMENT ====================================

const PAGE_SIZE = 10;

function getMonthFirstDay(date) {
  return `${date}-01`;
}

function getMonthLastDay(date) {
  return `${date}-${dayjs(date).daysInMonth()}`;
}

const Payment = () => {
  const theme = createTheme(koKR);
  const columns = [
    {
      field: "content",
      headerName: "내역",
      width: 300,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "payment-table-header",
    },
    {
      field: "amount_minus",
      headerName: "차감",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "payment-table-header",
    },
    {
      field: "amount_plus",
      headerName: "충전 및 환급",
      width: 150,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "payment-table-header",
    },
    {
      field: "remaining_point",
      headerName: "잔여 포인트",
      width: 200,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "payment-table-header",
    },
    {
      field: "created_at",
      headerName: "일시",
      width: 220,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "payment-table-header",
    },
  ];
  const {loginInfo, refreshUserInfo} = useContext(LoginContext);
  const [cookies] = useCookies();
  const [pointList, setPointList] = useState([
    {
      point_amount: 0,
      payment_amount: 0,
      point_id: 0,
    },
    {
      point_amount: 0,
      payment_amount: 0,
      point_id: 0,
    },
    {
      point_amount: 0,
      payment_amount: 0,
      point_id: 0,
    },
  ]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM"));
  const [userInfo, setUserInfo] = useState({});
  const [point, setPoint] = useState(0);
  const [pointPrice, setPointPrice] = useState({
    alimtok_consumption_amount: "-",
    inspection_consumption_amount: "-",
  });
  const [chargePoint, setChargePoint] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#e7e9ec",
    },
    [`& .${gridClasses.row}`]: {
      "&.Mui-selected": {
        backgroundColor: "rgba(3, 110, 184, 0.2)",
      },
    },
  }));
  const requestPay = async idx => {
    const {IMP} = window;
    IMP.init("imp60851334");

    setIsLoading(true);

    const preparePayment = await paymentApi.paymentPrepare(
      cookies.access_token,
      pointList[idx].point_id,
    );

    IMP.request_pay(
      {
        pg: "html5_inicis.MOI1040672",
        merchant_uid: preparePayment.data.merchant_uid,
        name: `${pointList[idx].point_amount}포인트`,
        amount: pointList[idx].payment_amount,
        buyer_name: userInfo.ceo_name,
        buyer_tel: userInfo.ceo_phone_number,
        buyer_addr: userInfo.address,
        buyer_email: "",
      },
      async rsp => {
        if (rsp.success) {
          try {
            const payload = {
              imp_uid: rsp.imp_uid,
              merchant_uid: preparePayment.data.merchant_uid,
            };
            await paymentApi.paymentComplete(cookies.access_token, payload);

            refreshUserInfo();
            setChargePoint(!chargePoint);
          } catch (error) {
            if (error.response && error.response.data) {
              alert(
                `결제가 되었지만, 포인트 지급에 실패하였습니다.\n사유 : ${error.response.data}\n고객센터(1522-0029)로 문의 부탁드립니다.`,
              );
            } else {
              alert(
                `결제가 되었지만, 포인트 지급에 실패하였습니다.\n고객센터(1522-0029)로 문의 부탁드립니다.`,
              );
            }
          } finally {
            setIsLoading(false);
          }
        } else {
          alert(
            `결제가 실패되었습니다.\n사유 : ${rsp.error_msg}\n오류가 반복되면 고객센터(1522-0029)로 문의 부탁드립니다.`,
          );
          setIsLoading(false);
        }
      },
    );
  };

  // const changePage = e => {
  //   setPage(page + 1);
  // };

  const changeMonth = e => {
    setDate(e.target.value);
    setPage(1);
  };

  const getPointList = async () => {
    try {
      const {data} = await paymentApi.getPointPaymenList(cookies.access_token);

      setPointList(data.results);
    } catch (error) {}
  };

  const getPointHistory = async () => {
    const params = {
      start_date: getMonthFirstDay(date),
      end_date: getMonthLastDay(date),
      page_size: PAGE_SIZE,
      page,
      center: loginInfo.centerId,
    };
    try {
      const response = await pointApi.getPointList(
        cookies.access_token,
        params,
      );

      if (response.status === 200) {
        console.log(response.data.results);
        const result = response.data.results.map(item => {
          return {
            ...item,
            created_at: dayjs(item.created_at).format("YYYY-MM-DD hh:mm:ss"),
            amount_minus: item.amount < 0 ? item.amount : null,
            amount_plus: item.amount > 0 ? `+${item.amount}` : null,
          };
        });

        setRows(result);
        setRowCount(response.data.count);
      }
    } catch (error) {
      if (error.response.status === 404) {
        return alert("더이상 포인트 변경내역이 없습니다.");
      }
      return alert("포인트 기록을 가져올 수 없습니다.");
    }
  };
  const getPointPrice = async () => {
    try {
      const response = await pastInspectionApi.getInspectionPointPrice(
        cookies.access_token,
      );
      if (response.status === 200) {
        setPointPrice(response.data);
      }
    } catch {}
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{display: "flex", justifyContent: "flex-end"}}>
        <GridToolbarExport
          csvOptions={{
            fileName: `${date} 충전 및 사용내역`,
            delimiter: ",",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  };
  useEffect(() => {
    const getUserPoint = async () => {
      const {data} = await centerApi.myData(cookies.access_token);

      setUserInfo(data);
      setPoint(data.point);
    };
    getUserPoint();
  }, [cookies]);

  useEffect(() => {
    getPointHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page]);

  useEffect(() => {
    getPointList();
    getPointPrice();
  }, []);

  return (
    <>
      {/* {loading && <TailSpin className="mx-auto" stroke="#999" />} */}

      <Stack className="max-w-5xl mx-auto my-[3rem] ">
        <Stack>
          <Box className="flex flex-row items-center justify-between py-8 border-b-2 border-b-black">
            <Typography variant="h5" fontWeight="bold">
              결제관리(업데이트 예정)
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={5} sx={{pt: 5, margin: "auto"}}>
          <Stack
            display="flex"
            justifyContent="center"
            bgcolor="black"
            height="70px"
            sx={{pl: 3, pr: 3, borderRadius: 1}}
          >
            <Grid container>
              <Grid item xs>
                <Typography variant="h6" className="text-white">
                  보유 포인트
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  className="text-white"
                >
                  {`${point ?? 0} P`}
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          <Stack direction="column" spacing={2}>
            <Stack>
              <Typography fontWeight="bold" variant="h6">
                포인트 충전
              </Typography>
              <Typography fontWeight="bold" variant="h7" color="gray">
                ㆍ검사완료 차감(1대당) :{" "}
                {pointPrice.inspection_consumption_amount}P
              </Typography>
              <Typography fontWeight="bold" variant="h7" color="gray">
                ㆍ카카오톡 안내전송(1회당) :{" "}
                {pointPrice.alimtok_consumption_amount}P
              </Typography>
            </Stack>

            <Grid container>
              {pointList.map((list, idx) => (
                <Grid key={idx} item xs={4}>
                  <Box
                    width={280}
                    className="flex flex-col items-center p-5 m-3 space-y-6 bg-gray-200 rounded-lg shadow-md shadow-gray-500"
                  >
                    <Typography mt={2} fontWeight={900} variant="h4">
                      {list.point_amount}P
                    </Typography>
                    <Button
                      sx={{
                        fontSize: "large",
                        fontWeight: "700",
                      }}
                      size="large"
                      color="primary"
                      variant="contained"
                      fullWidth
                      name={idx}
                      onClick={() => requestPay(idx)}
                      disabled={isLoading}
                    >
                      {`${list.payment_amount.toLocaleString()}원`}
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>

          <Box>
            <Typography mb={3}>
              ※ 잔여 포인트가 5,000P 이하일 경우 포인트 충전을 하셔야 서비스
              이용이 가능합니다.
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>취소/환불 규정</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  제 1장 총칙
                  <br />
                  <br /> 제 1조 목적
                  <br />
                  <br /> 본 약관은 주식회사 자카(이하 '포인트'라 함)가 사용하는
                  지불/결제 수단(이하 '포인트'라 함)을 사용함에 있어 포인트와
                  고객간에 포인트에 대한 충전 이용에 관해서 상호간 준수해야 할
                  사항을 규정함을 목적으로 합니다.
                  <br />
                  <br /> 제 2조 용어의 정리
                  <br />
                  <br /> 1. 검사소 : 본 약관에 동의하고 회사가 제공하는 포인트를
                  이용하는 고객을 말합니다.
                  <br /> 2. 포인트 : 포인트는 사이트 및 제휴 사이트의 유료
                  서비스(검사예약 및 진행, 카카오톡 문자서비스)를 이용할 수 있는
                  인터넷상의 결제수단을 의미합니다. <br />
                  3. 충전 : 포인트 일정액을 확보하기 위해 특정 지불수단을
                  이용하여 현금을 포인트에 지불하고, 그 현금의 대가로 포인트가
                  적립됩니다. 각 결제수단을 이용해 포인트로 충전 시에는 최소
                  현금 100,000원당 포인트 100이 충전됩니다. <br />
                  4. 사용내역 취소 : 충전한 포인트로 유료서비스를 이용하는 중
                  장애나 기타 사유로 인해 정상적으로 서비스를 이용하지 못했을
                  경우, 장애 신고를 통해 사용된 포인트를 결제하기 이전 상태로
                  다시 되돌려 받는 것을 말합니다. <br />
                  5. 환불 : 포인트가 제공한 결제수단을 통하여 충전한 포인트를
                  현금으로 되돌려 받거나, 결제 수단별 승인취소 등의 방법으로
                  결제가 이루어지지 않도록 하는 것을 말합니다. 위에서 정의한
                  용어 이외의 사항은 관계 법령 및 상관례에서 정하는 바에
                  따릅니다.
                  <br />
                  <br /> 제 2장 포인트 이용 <br />
                  <br />제 3조 포인트 사용 <br />
                  <br />
                  1. 포인트는 내부 컨텐츠/전자상거래 사용 및 제휴 가맹처에서
                  캐시를 충전하는 데 지불/결제 수단으로 이용이 되며, 포인트
                  충전/사용은 회사 홈페이지에 ID가 있어야 가능합니다. <br />
                  2. 포인트의 사용시 실명 인증 절차를 거치지 않거나 확인이 되지
                  않은 회원의 경우는 일부 서비스 이용에 제한을 받으실 수
                  있습니다. <br />
                  3. 결제 정보를 입력한 후에는 포인트가 회원의 이용 편의성과
                  결제 수단별 본인확인 및 회원에 대한 청구로 인하여 회원의 결제
                  정보를 보관하여도 무방한 것으로 간주하며, 이 때 포인트는
                  회원의 고객정보에 대한 내용을 암호화하여 보관해야 하며 본인의
                  동의없이 절대 외부 유출이 불가능합니다. <br />
                  4. 각 결제 정보의 입력은 포인트를 사용함에 따른 금융정보에
                  해당되므로 도용 및 타인의 금융정보를 임의 기재하였을 때에는
                  서비스의 사용에 제한이 있으며, 법률적 책임도 지셔야 합니다.{" "}
                  <br />
                  5. 포인트는 이용자가 제1항에 따라 포인트를 충전하거나 사용한
                  경우 이용자가 포인트 잔액을 확인할 수 있도록 하며, 이용자는
                  포인트를 충전하거나 사용한 경우 즉시 포인트 잔액을 확인하고
                  이상이 있는 경우에는 포인트에 해당 사실을 통지하여야 합니다.
                  이 경우 포인트는 해당 이용자의 포인트 충전 또는 사용 내역을
                  확인하여 필요한 조치를 취합니다. <br />
                  6. 포인트를 이용할 수 있는 인터넷 서비스와 포인트를 사용하여
                  결제할 수 있는 유료 서비스는 포인트에서 정한 바에 따르며
                  포인트의 정책에 따라 추가, 변경, 삭제될 수 있습니다. <br />
                  7. 포인트는 이용자에 대하여 청소년보호법에 따른 이용 등급 및
                  연령 준수를 위해 인터넷 서비스에 대하여 이용 제한이나 등급별
                  연령 제한을 할 수 있습니다.
                  <br />
                  <br /> 제 4조 포인트 차감
                  <br />
                  <br />
                  충전한 포인트에 대한 차감은 포인트가 제공하는
                  유료서비스(검사예약 및 진행, 카카오톡 문자서비스 및 기타
                  서비스)를 이용하는 시점에서 즉시 이루어집니다. <br />
                  <br />제 5조 포인트
                  <br />
                  <br />
                  사용제한 <br />
                  1. 이용자는 다음 각호에 해당하는 행위를 하여서는 아니 되며,
                  이를 위반할 경우 포인트는 당해 이용자에 대하여 포인트 이용을
                  정지시킬 수 있습니다. <br />
                  1) 타인의 이용ID 및 비밀번호를 부정하게 사용하는 경우 <br />
                  2) 타인의 명의나 개인정보를 도용하여 서비스를 이용하는 경우{" "}
                  <br />
                  3) 타인의 결제정보 등을 도용하거나 부정한 행위로 거래를 하는
                  경우 <br />
                  4) 이용자의 포인트를 허용하지 아니한 곳에서 양도하는 경우{" "}
                  <br />
                  5) 다른 이용자의 포인트 사용을 저해하거나, 포인트 서비스
                  제공에 지장을 초래하는 경우 <br />
                  6) 본 약관에 위반하는 방법으로 포인트 서비스를 이용하는 경우{" "}
                  <br />
                  7) 포인트가 사용되는 포인트의 인터넷 서비스 이용약관에
                  위반하는 방법으로 서비스를 이용하는 경우 <br />
                  8) 기타 법령에 위배되는 행위를 하는 경우 <br />
                  2. 포인트는 포인트 이용을 제한할 경우 이용 제한 내용과 소명
                  절차를 이용자가 지정한 전자우편 혹은 유선통화로 통지하여
                  이용자가 소명할 수 있는 기회를 부여합니다 <br />
                  3. 포인트 잔액을 장기간 사용하지 않을 시 포인트에서는 고객의
                  보호차원에서 사용 중지를 할 수 있습니다. 포인트에서는 사용
                  중지된 포인트를 서비스에서 안전하게 보관하여 고객이 언제든
                  사용을 원할 시, 고객 본인인증을 통하여 사용 가능토록 합니다.
                  <br />
                  <br />제 6조 충전 방법
                  <br />
                  <br /> 1. 포인트는 포인트가 정한 다양한 결제수단을 이용하여
                  충전할 수 있습니다. (단, 각 충전 방법 별로 최소 충전 금액 및
                  월 최대 충전금액이 상이할 수 있습니다.) <br />
                  2. 포인트가 제공하는 시스템상의 장애에 의하여 충전이
                  정상적으로 이루어지지 못한 경우에 포인트로 재충전 받을 수
                  있습니다. <br />
                  <br />제 7조 포인트 충전 종류
                  <br />
                  <br /> 1. 포인트의 충전을 위해 다음과 같은 결제수단 및 방법이
                  제공됩니다. <br />
                  1) 카드결제 ① 본인명의의 카드만 충전되며, 타인카드충전시 모든
                  민형사상의 책임을 져야 합니다. <br />
                  2) 카카오페이 ① 카카오페이를 통하여 입금하여 충전합니다.{" "}
                  <br />
                  2. 포인트가 제공하는 결제 수단은 포인트의 운영방침에 따라
                  이용이 제한 될 수 있습니다. <br />
                  <br />제 8조 포인트 사용내역 취소 요청
                  <br />
                  <br /> 1. 이용자가 포인트를 사용하여 포인트가 차감 처리된
                  이후에는 포인트의 사용 취소를 할 수 없습니다. <br />
                  2. 충전한 포인트로 유료서비스를 이용하는 중 장애나 기타 사유로
                  인해 정상적으로 서비스를 이용하지 못했을 경우 이미 차감된
                  포인트의 반환을 요청할 수 있습니다. <br />
                  3. 위 규정에도 불구하고, 유료서비스를 사용한 후 10일이 경과한
                  경우에는 사용내역 취소가 불가능하므로 사용내역 취소 시
                  주의하셔야 합니다. (단, 제휴 쇼핑몰의 경우
                  '전자상거래표준약관'에 따릅니다.) <br />
                  <br />제 9조 환불규정
                  <br />
                  <br />
                  1. 검사소가 무상으로 지급받은 포인트는 어떠한 경우에도
                  현금으로 환불될 수 없습니다.
                  <br />
                  2. 포인트에 대한 환불이 이루어질 경우, 무상으로 지급받은
                  포인트는 전액 소멸합니다. 충전된 포인트 중 일부에 대한 환불만
                  이루어질 때도 동일하게 전액 소멸합니다.
                  <br />
                  3. 결제일로부터 1년 이상이 경과한 결제건은 결제대행사
                  KG이니시스의 정책에 따라, 결제수단으로의 환불이 불가합니다.
                  <br />
                  1) 결제수단의 취소 대신, 계좌이체의 방식을 통해 환불됩니다.
                  <br />
                  2) 계좌이체를 위한 개인정보(예금주, 은행명, 계좌번호)는 환불
                  신청 이후 별도 요청드립니다.
                  <br />
                  3) 계좌이체를 통한 환불은 개인정보 제출일로부터 영업일 기준
                  7일 이내에 완료됩니다.
                  <br />
                  <br />
                  제 10조 포인트 환불 <br />
                  <br />
                  1. 결제에 사용한 포인트 잔액이 60%이하로 남았을 경우 환불
                  받으실 수 있습니다. <br />
                  2. 카카오페이 등을 이용하여 충전된 포인트의 경우 환불 수수료를
                  제외한 금액을 환불 받을 수 있습니다. 환불수수료는 환불 금액이
                  1만원 이하인 경우 1,000원을, 1만원 초과시 환불 금액의 10%를
                  반영합니다. <br />
                  <br />
                  [환불 수수료 예시] <br />
                  남은 잔액이 6천원인 경우 환불 수수료 1,000원 제외하고 환불
                  가능 <br />
                  남은 잔액이 24,000원인 경우 환불 수수료 2,400원 제외하고 환불
                  가능 <br />
                  4. 3항에도 불구하고 충전에 해당되는 결제수단의 결제 승인의
                  취소 또는 해당 결제수단으로 결제금액을 재 환원하는 것으로
                  환불을 대신할 수 있을 때는 위약금을 징수하지 않습니다. <br />
                  5. 아래의 내용에 해당하는 경우 환불 신청을 통하여 포인트가
                  정한 절차에 의거 환불을 받을 수 있습니다. <br />
                  1) 포인트를 충전했으나 포인트를 사용할 수 있는 서비스가
                  전무하며 그에 대한 책임이 전적으로 포인트에 있는 경우 (단,
                  시스템의 정기점검 등 불가피한 경우는 제외) <br />
                  2) 사용하지 않은 포인트가 남아있는 상태에서 변경된 약관에
                  동의하지 않는다는 이유로 사이트를 탈퇴하거나 환불을 신청하는
                  경우 <br />
                  3) 포인트와 제3자간의 서비스 이용 계약이 계약기간 만료, 계약
                  해지 등의 사유로 종료됨에 따라 포인트가 서비스를 이용자에게
                  제공할 수 없게 된 경우 <br />
                  4) 기타 검사소의 권리보호를 위하여 포인트가 별도로 정한 경우{" "}
                  <br />
                  6. 환불 절차는 아래와 같습니다. <br />
                  1) 제10조 6항의 각 호에 해당하는 경우 고객은 환불 신청을 할 수
                  있으며, 환불 신청을 접수 받은 포인트는 고객의 환불 신청 사유가
                  정당하다고 판단된 경우에 한해 환불합니다. <br />
                  2) 각 호의 규정에 의거하여 포인트는 고객의 환불 신청을 확인한
                  시점에서 10일 이내에 직접 환불 처리합니다. <br />
                  3) 위의 제 10조 6항 2호에 의한 이유로 이용자가 환불을
                  신청하면, 환불 수수료 제외한 금액을 환불 받게 되며, 환불 대상
                  금액이 환불 수수료 이하의 경우에는 환불 받으실 수 없습니다.{" "}
                  <br />
                  4) 외부가맹사이트에 충전된 포인트는 해당 사이트 캐시 정책에
                  의해 처리됩니다. <br />
                  7. 3항 및 4항의 규정에도 불구하고, 아래에 해당하는 경우
                  포인트는 환불이 불가능합니다. <br />
                  1) 타인에게 양도된 포인트 <br />
                  2) 이벤트 등으로 받은 포인트 <br />
                  3) 이용자가 직접 충전하지 않은 포인트 <br />
                  4) 기타 부정한 방법을 통한 환불 <br />
                  <br />
                  제 11조 포인트 양도 <br /> <br />
                  1. 포인트의 양도는 포인트 특성 상 양도가 불가합니다.
                  <br />
                  <br />
                  제 12조 개인정보 수집 및 보호 <br />
                  <br />
                  1. 포인트는 포인트 서비스 제공을 위한 목적으로 이용자의 동의를
                  얻어 수집한 개인정보를 본인의 승낙 없이 타인에게 누설할 수
                  없으며, 포인트 서비스 제공 목적 이외의 용도로 사용하지
                  않습니다. 다만, 관련 법령에 의한 수사상의 목적 등으로 관계
                  기관으로부터 요구 받은 경우나 방송통신심의위원회의 요청이 있는
                  경우 등 법령에 따른 적법한 절차에 의한 경우에는 그러하지
                  아니합니다. <br />
                  2. 포인트는 필요한 경우 이용자의 동의를 얻어 이용자의
                  개인정보를 이용하거나 제3자에게 제공할 수 있습니다. <br />
                  이 경우 그 개인정보의 이용 목적, 제공받는 자, 제공하는 개인
                  정보 항목, 제공 목적, 제공 시기 등에 대해 개별적으로 이용자의
                  동의를 받습니다. <br />
                  3. 포인트는 이용자의 개인정보 보호와 관련하여 정보통신망
                  이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법,
                  전기통신사업법 등 관련 법령을 준수합니다. <br />
                  4. 이용자의 개인정보 열람 요청은 관련 법령에 따라 수사기관의
                  수사 자료제공 절차에 의해서 가능하며 이용자는 자신의
                  개인정보도용 등을 이유로 타인의 개인정보를 열람할 수 없습니다.{" "}
                  <br />
                  5. 본 조에서 정한 사항 이외의 이용자의 개인정보보호에
                  관련사항은 포인트가 제정한 '개인정보 처리방침'에서 정한 바에
                  의합니다.
                  <br />
                  <br />
                  제 13조 포인트 소멸 <br />
                  <br />
                  1. 포인트의 유효기간은 12개월이며, 유효기간이 경과하는 동안
                  포인트를 사용하지 않은 경우 자동 소멸됩니다. <br />
                  2. 아래의 경우에는 해당 사유 발생시점에 포인트가 모두
                  소멸되며, 복원되지 않습니다. <br />
                  1) 회원이 자발적으로 탈퇴한 경우 <br />
                  2) 제10조 제7항에 해당하는 포인트의 경우 <br />
                  3) 비정상적인 충전으로 포인트 및 다른 회원에게 피해를 입혀
                  회원 자격을 상실한 경우 <br />
                  제 3장 손해배상 및 면책조항 <br />
                  <br />
                  제 14조 손해배상 <br />
                  <br />
                  1. 포인트는 포인트의 중대한 과실로 인하여 충전한 포인트가
                  소멸되는 등 회원이 서비스 이용 시 손해를 입는 경우 포인트
                  재충전 등 그에 상응하는 적절한 보상을 제공합니다. <br />
                  2. 포인트는 회원의 귀책 사유나 1항의 내용 외에 발생하는 회원의
                  손실에 대해서는 일체 책임을 지지 않습니다. <br />
                  <br />
                  제 15조 면책조항 <br />
                  <br />
                  1. 포인트는 천재지변 또는 이에 준하는 불가항력으로 인하여
                  서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
                  면제됩니다. <br />
                  2. 포인트는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여
                  책임을 지지 않습니다. <br />
                  3. 포인트는 회원이 서비스를 이용하여 기대하는 수익을 상실한
                  것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은
                  자료로 인한 손해에 관하여 책임을 지지 않습니다. <br />
                  4. 포인트는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도,
                  정확성 등 내용에 관하여는 책임을 지지 않습니다. <br />
                  <br />
                  제 16조 재판권 및 준거법 <br />
                  <br />
                  1. 이 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을
                  경우에는 해당 규정에 따릅니다. <br />
                  2. 요금 등 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될
                  경우 포인트의 본사 소재지를 관할하는 법원을 전속 관할법원으로
                  합니다. <br />
                  <br />
                  [부칙] <br />
                  <br />
                  (공고일) 2024년 01월 01일 <br />
                  (시행일) 이 약관은 2024년 01월 01일부터 시행합니다.
                  <br />
                  <br />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>민원책임문구</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  자카에서 운영되는 사이트 내 판매되는 모든 상품은 자카에서
                  책임지고 있습니다.
                  <br />
                  <br />
                  민원 담당자 : 최경수
                  <br />
                  연락처 : 1522-0029
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Stack spacing={4}>
            <Stack mt={3}>
              <Typography fontWeight="bold" variant="h6">
                충전 및 사용내역
              </Typography>
            </Stack>
            <Stack>
              <TextField
                id="date"
                type="month"
                defaultValue={dayjs(new Date()).format("YYYY-MM")}
                value={date}
                sx={{width: 150}}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={changeMonth}
              />
            </Stack>
            <Box
              sx={{
                height: "800px",
                width: "100%",
                "& .MuiDataGrid-columnHeaderCheckbox": {
                  backgroundColor: "#2d353c",
                },
                "& .MuiDataGrid-columnHeader svg": {
                  color: "#fff",
                },
                "& .payment-table-header": {
                  backgroundColor: "#2d353c",
                  color: "#fff",
                },
                "& .payment-table-header div": {
                  justifyContent: "center",
                },
                ".MuiDataGrid-sortIcon": {
                  color: "#ffffff",
                  opacity: 1,
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <StripedDataGrid
                  disableColumnMenu
                  disableSorting
                  pagination
                  headerHeight={40}
                  rowHeight={40}
                  rows={rows}
                  rowCount={rowCount}
                  columns={columns}
                  page={page - 1}
                  pageSize={pageSize}
                  paginationMode="server"
                  onPageChange={newPage => {
                    setPage(newPage + 1);
                  }}
                  onPageSizeChange={newPageSize => {
                    setPageSize(newPageSize);
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {page, pageSize},
                    },
                  }}
                  pageSizeOptions={[25, 50, 100]}
                  getCellClassName={params => {
                    if (params.field === "amount_minus") {
                      return "text-red-500";
                    }
                    if (params.field === "amount_plus") {
                      return "text-blue-500";
                    }
                  }}
                  components={{Toolbar: CustomToolbar}}
                />
              </ThemeProvider>
            </Box>
            {/* <Stack>
              <TextField
                id="date"
                type="month"
                defaultValue={dayjs(new Date()).format("YYYY-MM")}
                value={date}
                sx={{width: 150}}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={changeMonth}
              />
            </Stack>
            {!pointHistory.length && (
              <Stack textAlign="center">
                <Typography>
                  해당 월에는 포인트 변경 내역이 없습니다.
                </Typography>
              </Stack>
            )}
            {pointHistory.map(row => {
              return (
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs>
                      <Typography>
                        {row.content
                          ? `${row.type} - ${row.content}`
                          : row.type}
                      </Typography>
                      <Typography color="secondary">
                        {`${dayjs(row.created_at).format(
                          "YYYY-MM-DD hh:mm:ss",
                        )}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography color="primary">{`${row.amount} 포인트`}</Typography>
                    </Grid>
                  </Grid>
                  <Stack>
                    <Divider />
                  </Stack>
                </Stack>
              );
            })}
            <Stack display="flex" alignItems="center">
              <Button
                onClick={changePage}
                color="primary"
                variant="contained"
                sx={{width: 200}}
              >
                더보기
              </Button>
            </Stack> */}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Payment;
