import Button from "@mui/material/Button";
import {TextField, Typography} from "@mui/material";
import {useState, useContext} from "react";
import {LoginContext} from "../../components/contexts/LoginContext";
import {authApi} from "../../../src/api";
import DialogAlert from "../../components/modal/DialogAlert";
import {useCookies} from "react-cookie";

const PasswordModal = ({handleClose}) => {
  const inputData = {
    old_password: "",
    new_password1: "",
    new_password2: "",
  };

  const [inputs, setInputs] = useState(inputData);
  const [validPw, setValidPw] = useState(false);
  const [checkPw, setCheckPw] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [cookies] = useCookies();

  const {loginInfo} = useContext(LoginContext);

  const onChange = e => {
    const {value, name} = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const res = await authApi.changePassword(inputs, cookies.access_token);
      if (res.status === 200) {
        setModalContent("비밀번호가 변경되었습니다.");
        setModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      setModalContent("이전(임시) 비밀번호가 올바르지 않습니다.");
      setModalOpen(true);
    }
  };

  const validPassword = event => {
    var regExp = /^(?=.{8,16}$).*/;
    if (regExp.test(event.target.value) || event.target.value === "")
      setValidPw(false);
    else setValidPw(true);

    setInputs({
      ...inputs,
      new_password1: event.target.value,
    });
  };

  const checkPassword = event => {
    if (
      inputs.new_password1 === event.target.value ||
      event.target.value === ""
    )
      setCheckPw(false);
    else setCheckPw(true);

    setInputs({
      ...inputs,
      new_password2: event.target.value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <DialogAlert
          open={modalOpen}
          handleClose={handleClose}
          content={modalContent}
        ></DialogAlert>
        <ul>
          <li className="flex items-center mb-3">
            <p className="w-40">이전(임시) 비밀번호</p>
            <TextField
              required
              size="small"
              inputProps={{maxLength: 16}}
              className="flex-1"
              type="password"
              variant="outlined"
              name="old_password"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">신규 비밀번호</p>
            <TextField
              required
              error={validPw}
              size="small"
              inputProps={{maxLength: 16}}
              className="flex-1"
              type="password"
              variant="outlined"
              name="new_password1"
              onChange={validPassword}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">신규 비밀번호 확인</p>
            <TextField
              required
              error={checkPw}
              size="small"
              className="flex-1"
              inputProps={{maxLength: 16}}
              type="password"
              variant="outlined"
              name="new_password2"
              onChange={checkPassword}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40"></p>
            <div className="flex-1 text-xs text-primary">
              <Typography fontSize="small" color={validPw ? "red" : "primary"}>
                ※ 비밀번호는 영문, 숫자로 최소 8자리 이상입니다.
              </Typography>
              <Typography fontSize="small">
                ※ 비밀번호 재설정 후 재 로그인 하시기 바랍니다.
              </Typography>
            </div>
          </li>
        </ul>
        <div className="absolute flex flex-row justify-end bottom-8 right-8">
          <div className="space-x-2">
            <Button variant="outlined" onClick={handleClose}>
              취소
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                !(
                  !checkPw &&
                  !validPw &&
                  inputs.old_password &&
                  inputs.new_password1 &&
                  inputs.new_password2
                )
              }
            >
              저장
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default PasswordModal;
