import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {pastInspectionApi, centerApi} from "../../api";

import {PATH_PAST_INSPECTION_CREATE} from "../../components/common/Router";
import PastInspectionModal from "../../components/modal/PastInspectionModal";
// material-ui
import {
  Stack,
  Typography,
  Modal,
  Button,
  ButtonGroup,
  Box,
  styled,
} from "@mui/material";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

// 3rd-party
import dayjs from "dayjs";

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#e7e9ec",
  },
  [`& .${gridClasses.row}`]: {
    "&.Mui-selected": {
      backgroundColor: "rgba(3, 110, 184, 0.2)",
    },
  },
}));

const TAB_LIST = ["검사일 도래 목록", "지난 전체 목록"];

const columns = [
  {
    field: "id",
    headerName: "번호",
    width: 80,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },
  {
    field: "car_number",
    headerName: "차량번호",
    width: 120,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },
  {
    field: "user_name",
    headerName: "고객명",
    width: 100,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },
  {
    field: "phone_number",
    headerName: "고객 연락처",
    width: 160,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },
  {
    field: "next_expired_at",
    headerName: "유효기간 만료일",
    width: 160,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },

  {
    field: "previous_inspected_at",
    headerName: "최근 검사일",
    width: 160,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
    valueGetter: ({value}) => value ?? "-",
  },
  {
    field: "last_noticed_at",
    headerName: "최근 알림톡 전송 일시",
    width: 200,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
    valueGetter: ({value}) =>
      value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-",
  },
  {
    field: "noticed_count",
    headerName: "알림톡 전송 횟수",
    width: 120,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
  },
  {
    field: "reservation_complete",
    headerName: "예약 완료 여부",
    width: 130,
    align: "center",
    editable: false,
    headerClassName: "past-inspection-table-header",
    valueGetter: ({value}) => (value ? "완료" : "미완료"),
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 6,
  pb: 3,
};

const PastInspection = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [tabIdx, setTabIdx] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectData, setSelectData] = useState([]);
  const [centerDetail, setCenterDetail] = useState({});
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleTabChange = e => {
    setTabIdx(+e.target.id);
  };

  const onClickAlimtok = () => {
    if (selectData.some(item => item.reservation_complete)) {
      alert("예약 완료된 검사건은 알림톡을 전송할 수 없습니다.");
      return;
    }
    if (selectData.length >= 10) {
      alert("한 번에 최대 10건 까지 알림톡을 전송할 수 있습니다.");
      return;
    }
    if (!selectData.length) {
      alert("알림톡을 전송할 지난 검사 목록을 선택해 주세요.");
      return;
    }

    setModalOpen(true);
  };

  const onClickDelete = () => {
    if (!selectData.length)
      return alert("삭제할 지난 검사 목록을 선택해 주세요.");
    setDeleteModalOpen(true);
  };
  const onClickAdd = () => {
    setAddModalOpen(true);
  };
  /** 지난 검사 리스트 조회 */
  const getPastInspection = async () => {
    let params = {
      page,
      page_size: pageSize,
      order_by: orderBy,
    };
    if (tabIdx === 0) {
      params = {
        ne_start_date: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        ne_end_date: dayjs().add(1, "month").format("YYYY-MM-DD"),
        ...params,
      };
    }

    try {
      const {data} = await pastInspectionApi.getPastInspectionList(
        cookies.access_token,
        params,
      );

      const {data: centerDetail} = await centerApi.getCenterDetail(
        cookies.access_token,
      );

      const result = data.results.map(item => {
        return {
          ...item,
          id: item.pk,
        };
      });

      setCenterDetail(centerDetail);
      setRowCount(data.count);
      setRows(result);
    } catch (error) {
      alert("지난 검사 데이터를 불러올 수 없습니다.");
      throw Error(error);
    }
  };

  /** 지난 검사 리스트 삭제 */
  const deletePastInspection = async () => {
    const payload = selectData.map(item => item.id);

    try {
      const {status} = await pastInspectionApi.deletePastInspectionList(
        cookies.access_token,
        payload,
      );
      if (status === 200) {
        alert(`${payload.length}건의 지난검사 건이 삭제되었습니다.`);
        getPastInspection();
      }
    } catch (error) {
      alert("지난 검사 데이터를 삭제할 수 없습니다.");
      throw Error(error);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  /** 알림톡 보내기 */
  const pushAlimtok = async () => {
    const payload = selectData.map(item => item.id);

    try {
      const {data, status} = await pastInspectionApi.pushPastInspectionAlitok(
        cookies.access_token,
        payload,
      );

      if (status === 200) {
        const alertMsg = `총 ${data.total_msg_req_amount}건의 요청 중 ${data.success_count}건의 알림톡 전송에 성공하였으며, ${data.point_consume_amount}포인트가 차감되었습니다.`;
        if (!data.fail_count) {
          alert(alertMsg);
        }
        if (data.fail_count) {
          alert(alertMsg + `(${data.fail_count}건 전송 실패)`);
        }

        getPastInspection();
      }
    } catch (error) {
      if (error.response.status === 402) {
        alert("포인트가 부족하여 알림톡을 전송할 수 없습니다.");
        return;
      }
      alert("오류 발생(관리자에게 문의 해주세요)");
      throw Error(error);
    } finally {
      setModalOpen(false);
    }
  };
  const handleSortModelChange = React.useCallback(
    sortModel => {
      // Here you save the data you need from the sort model
      let orderBy = "";
      if (sortModel.length > 0) {
        orderBy = (sortModel[0].sort === "asc" ? "" : "-") + sortModel[0].field;
      }
      setOrderBy(orderBy);
    },
    [setOrderBy],
  );
  useEffect(() => {
    getPastInspection();
  }, [tabIdx, page, pageSize, rowCount, orderBy]);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Stack sx={{...style, width: 400}}>
          <Stack sx={{alignItems: "center"}}>
            <p className="my-5 text-lg font-bold" id="parent-modal-title">
              알림톡 전송
            </p>
          </Stack>
          <Stack>
            <Typography>
              총 {selectData.length}건의 지난 검사에 대하여 알림톡을
              전송하시겠습니까? 알림톡 전송 건수에 따라 포인트가 차감됩니다.
              <br />
              <br />
              [알림톡 내용 예시]
              <br />
              <br />
              {centerDetail.name}에서 (홍길동)고객님의 차량번호(111가1111)의
              자동차 검사 기간을 안내 드립니다.
              <br />
              <br />
              ■ 자동차 검사 가능 기간 : 2023년 12월 31일 ~ 2024년 2월 28일(10일
              남음)
              <br />
              <br />[{centerDetail.name}]
              <br />
              <br />
              ■전화번호 : {centerDetail.center_phone_number}
              <br />
              ■주소 : {`${centerDetail.address} ${centerDetail.address_detail}`}
              <br />
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-around" py={3}>
            <Button
              sx={{width: 130}}
              variant="outlined"
              color="inherit"
              onClick={() => setModalOpen(false)}
            >
              닫기
            </Button>
            <Button sx={{width: 130}} variant="contained" onClick={pushAlimtok}>
              전송
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Stack sx={{...style}}>
          <Stack sx={{alignItems: "center"}}>
            <p className="my-5 text-lg font-bold" id="parent-modal-title">
              지난 검사 삭제
            </p>
          </Stack>
          <Stack>
            <Typography>
              {selectData.length}건의 지난 검사 건을 정말 삭제하시겠습니까?
            </Typography>
          </Stack>

          <Stack
            width={300}
            direction="row"
            justifyContent="space-around"
            pt={4}
            pb={2}
          >
            <Button
              sx={{width: 130}}
              variant="outlined"
              color="inherit"
              onClick={() => setDeleteModalOpen(false)}
            >
              취소
            </Button>
            <Button
              sx={{width: 130}}
              variant="contained"
              color="error"
              onClick={deletePastInspection}
            >
              삭제
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <PastInspectionModal
        open={addModalOpen}
        handleClose={() => {
          setAddModalOpen(false);
        }}
      />
      <Stack className="max-w-7xl mx-auto  mt-[1rem] mb-[3rem]" spacing={3}>
        <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
          <Stack>
            <ButtonGroup disableElevation variant="contained">
              {TAB_LIST.map((_tab, idx) => {
                return (
                  <Button
                    key={idx}
                    variant={tabIdx === idx ? "contained" : "outlined"}
                    id={idx}
                    onClick={handleTabChange}
                  >
                    {TAB_LIST[idx]}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>

          <Stack>
            <ButtonGroup sx={{gap: 2}}>
              <Button
                color="error"
                variant="contained"
                sx={{width: 125}}
                onClick={onClickDelete}
              >
                삭제
              </Button>
              {tabIdx === 0 && (
                <Button
                  variant="contained"
                  sx={{width: 125}}
                  onClick={onClickAlimtok}
                >
                  알림톡 전송
                </Button>
              )}
              <Button
                color="warning"
                variant="contained"
                onClick={onClickAdd}
                startIcon={<AddIcon />}
              >
                신규고객 입력
              </Button>
              <Button
                variant="contained"
                sx={{width: 125}}
                onClick={() => navigate(PATH_PAST_INSPECTION_CREATE)}
              >
                엑셀 불러오기
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>

        <Stack>
          <Box
            sx={{
              height: "calc( 100vh - 230px )",
              width: "100%",
              "& .MuiDataGrid-columnHeaderCheckbox": {
                backgroundColor: "#2d353c",
              },
              "& .MuiDataGrid-columnHeader svg": {
                color: "#fff",
              },
              "& .past-inspection-table-header": {
                backgroundColor: "#2d353c",
                color: "#fff",
              },
              "& .past-inspection-table-header div": {
                justifyContent: "center",
              },
              ".MuiDataGrid-sortIcon": {
                color: "#ffffff",
                opacity: 1,
              },
            }}
          >
            <StripedDataGrid
              onSelectionModelChange={newSelection => {
                const selectedRowData = newSelection.map(id =>
                  rows.find(row => row.id === id),
                );
                setSelectData(selectedRowData);
              }}
              disableColumnMenu
              disableSorting
              pagination
              headerHeight={40}
              rowHeight={40}
              rows={rows}
              rowCount={rowCount}
              columns={columns}
              page={page - 1}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={newPage => {
                setPage(newPage + 1);
              }}
              onPageSizeChange={newPageSize => {
                setPageSize(newPageSize);
              }}
              initialState={{
                pagination: {
                  paginationModel: {page, pageSize},
                },
              }}
              onSortModelChange={handleSortModelChange}
              pageSizeOptions={[25, 50, 100]}
              checkboxSelection
              getCellClassName={params => {
                if (params.field === "reservation_complete") {
                  if (params.value === "완료") {
                    return "bg-stepzone-blue text-white";
                  } else {
                    return "bg-stepzone-gray text-white";
                  }
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default PastInspection;
