/* eslint-disable react-hooks/exhaustive-deps */
import {
  TextareaAutosize,
  Button,
  Box,
  Typography,
  Grid,
  styled,
  Stack,
} from "@mui/material";
import {useCookies} from "react-cookie";

import {useEffect} from "react";
import {useState} from "react";
import {assignmentApi, inspectionApi, reservationApi} from "../../api";
import * as InspectionConst from "../../constants/inspection";
import {getDateDiffRound} from "../../utils/dateutil";
import "../../assets/css/inspection.css";
import {getCurrentStepClassName} from "./InspectionTable";
import ReservationModal from "../modal/ReservationModal";
import AlertDialog from "../dialogs/AlretDialog";
import AssignmentCancelModal from "../modal/AssignmentCancelModal";
import ReservationLinkModal from "../modal/ReservationLinkModal";
import InspectionMissedModal from "../modal/InspectionMissedModal";

const TextArea = styled(TextareaAutosize)(({theme}) => ({
  width: "100%",
  height: "100%",
}));
const GridContainer = styled(Grid)(({theme}) => ({
  border: "1px solid black",
  borderBottom: "0",
  marginBottom: "15px",
}));
const GridColumn = styled(Grid)(({theme}) => ({
  "&:nth-of-type(odd) div": {
    backgroundColor: "#ebeae9",
  },
}));
const Item = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  textAlign: "center",
  height: "100%",
  color: "black",
  padding: "5px 0",
  borderBottom: "1px solid black",
}));
const InspectionDetail = ({
  selectedInspection,
  setUpdatedData,
  loading,
  setLoading,
}) => {
  const [authCookies] = useCookies();

  const [siteMemo, setSiteMemo] = useState("");
  //const [selectedStep, setSelectedStep] = useState(-1);
  const [missedModalShow, setMissedModalShow] = useState(false);
  const [assignmentCancelDialogShow, setAssignmentCancelDialogShow] =
    useState(false);
  const [reservationCreateModalShow, setReservationCreateModalShow] =
    useState(false);
  const [reservationChangeModalShow, setReservationChangeModalShow] =
    useState(false);
  const [reservationLinkModalShow, setReservationLinkModalShow] =
    useState(false);
  const [reservationCancelDialogShow, setReservationCancelDialogShow] =
    useState(false);
  const [inspectionCompleteDialogShow, setInspectionCompleteDialogShow] =
    useState(false);
  useEffect(() => {
    //setSelectedStep(selectedInspection.current_step);
    setSiteMemo(selectedInspection.site_memo);
  }, [selectedInspection]);

  const getDiffExpired = () => {
    if (selectedInspection.inspection_expired_date) {
      return getDateDiffRound(
        new Date(selectedInspection.inspection_expired_date),
        new Date(),
      );
    } else {
      return "";
    }
  };
  const handleSaveClick = async e => {
    e.preventDefault();
    if (Object.keys(selectedInspection).length) {
      const update_data = {
        //current_step: selectedStep,
        site_memo: siteMemo ? siteMemo : null,
      };

      try {
        const {status, data} = await inspectionApi.updateInspection(
          authCookies.access_token,
          selectedInspection.id,
          update_data,
        );
        if (status === 200) {
          setUpdatedData(data);
          alert("검사 정보가 변경되었습니다.");
        } else {
          alert("서버 에러");
        }
      } catch (e) {
        alert("서버 에러");
      }
    }
  };

  const handleSend = async phoneNumber => {
    if (Object.keys(selectedInspection).length) {
      try {
        setLoading(true);
        const {status, data} = await inspectionApi.sendNotice(
          authCookies.access_token,
          selectedInspection.id,
          phoneNumber,
        );
        if (status === 200) {
          setUpdatedData(data);
        }
      } catch (e) {
        const {status, data} = e.response;
        if (status === 400) {
          alert(data);
        } else {
          alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Box>
      <Box paddingX={"10px"} sx={{flexGrow: 1}}>
        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          차량정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>고객사</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.customer_company_name}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>차량번호</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.car_number}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>공단차종명</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.car_name}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>유효기간만료일</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>
              <Stack direction={"row"}>
                <Typography marginRight={"3px"}>
                  {`${
                    selectedInspection.inspection_expired_date
                      ? selectedInspection.inspection_expired_date
                      : ""
                  } / `}
                </Typography>

                <Typography
                  fontWeight={"bold"}
                  color={getDiffExpired() < 30 ? "red" : "#E0CA00"}
                >
                  {getDiffExpired() >= 0
                    ? " D-" + getDiffExpired()
                    : " D+" + -getDiffExpired()}
                </Typography>
              </Stack>
            </Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>검사종류</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.inspection_type}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>상담원</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.counselor}</Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>주행거리</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.mileage}km</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item></Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item></Item>
          </GridColumn>
        </GridContainer>
        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          고객정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>계약자명</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.user_name}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>운전자명</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.driver_name}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>대표번호</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.representative_phone_number}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>운전자 연락처</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.driver_phone_number}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>계약주소</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>{selectedInspection.contract_address}</Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>상세히스토리</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: "5px"}}>
              <TextArea
                minRows={2}
                maxRows={10}
                sx={{border: "1px solid black"}}
                value={
                  selectedInspection.detail_history
                    ? selectedInspection.detail_history
                    : ""
                }
                readOnly
                disabled
              ></TextArea>
            </Item>
          </GridColumn>
        </GridContainer>

        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          예약 및 결과정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>Step Zone</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item sx={{backgroundColor: "transparent", padding: "0"}}>
              <div
                className={
                  "stepzone " +
                  getCurrentStepClassName(
                    selectedInspection.current_step_name,
                    selectedInspection.step_updated_at,
                  )
                }
              >
                <div>
                  {selectedInspection.current_step_name
                    ? selectedInspection.current_step_name
                    : ""}
                </div>
              </div>
            </Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>예약유형</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.reservation_type}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>예약일자</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>
              {selectedInspection.reservation_date &&
              selectedInspection.reservation_time
                ? selectedInspection.reservation_date +
                  " " +
                  selectedInspection.reservation_time
                : ""}
            </Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>픽업주소</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>{selectedInspection.pickup_address}</Item>
          </GridColumn>
          {/* <GridColumn item xs={2}>
            <Item>진행사항</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>
              <FormControl sx={{width: "100%", padding: "0 5px"}} size="small">
                <Select
                  value={
                    selectedStep === 0 ? 0 : selectedStep ? selectedStep : -1
                  }
                  onChange={e => {
                    setSelectedStep(e.target.value);
                  }}
                  sx={{
                    width: "100%",
                    height: "25px",
                    border: "1px solid black",
                  }}
                >
                  <MenuItem value={-1}>선택안됨</MenuItem>
                  {Object.keys(InspectionConst.STEP_NAME_FOR_CENTER).map(
                    key => {
                      return (
                        <MenuItem key={key} value={key}>
                          {InspectionConst.STEP_NAME_FOR_CENTER[key]}
                        </MenuItem>
                      );
                    },
                  )}
                </Select>
              </FormControl>
            </Item>
          </GridColumn> */}

          <GridColumn item xs={2}>
            <Item>검사장소</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.center_name}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>검사완료 일자</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.inspection_complete_date}</Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>현장메모</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: " 5px"}}>
              <TextArea
                minRows={2}
                maxRows={10}
                value={siteMemo ? siteMemo : ""}
                onChange={e => {
                  setSiteMemo(e.target.value);
                }}
                sx={{border: "1px solid black"}}
              ></TextArea>
            </Item>
          </GridColumn>
        </GridContainer>

        {Object.keys(selectedInspection).length > 0 && (
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={"10px"}>
              {(selectedInspection.current_step ===
                InspectionConst.STEP_ASSIGNMENT_COMPLETE ||
                selectedInspection.current_step ===
                  InspectionConst.STEP_RESERVATION_CANCEL ||
                selectedInspection.current_step ===
                  InspectionConst.STEP_MISSED) && (
                <>
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => {
                      setAssignmentCancelDialogShow(true);
                    }}
                  >
                    배정 취소
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setReservationCreateModalShow(
                        !reservationCreateModalShow,
                      );
                    }}
                  >
                    검사 예약
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      if (Object.keys(selectedInspection).length) {
                        setReservationLinkModalShow(!reservationLinkModalShow);
                      }
                    }}
                  >
                    예약링크 전송
                  </Button>
                </>
              )}
              {selectedInspection.current_step ===
                InspectionConst.STEP_RESERVATION_COMPLETE && (
                <>
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => {
                      setReservationCancelDialogShow(true);
                    }}
                  >
                    예약 취소
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    color="warning"
                    onClick={() => {
                      setReservationChangeModalShow(
                        !reservationChangeModalShow,
                      );
                    }}
                  >
                    예약 변경
                  </Button>
                </>
              )}

              {selectedInspection.current_step !==
                InspectionConst.STEP_MISSED &&
                selectedInspection.current_step !==
                  InspectionConst.STEP_INSPECTION_COMPELTE && (
                  <Button
                    disabled={loading}
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setMissedModalShow(true);
                    }}
                  >
                    부재 처리
                  </Button>
                )}
            </Stack>

            <Stack direction={"row"} gap={"10px"}>
              {(selectedInspection.current_step ===
                InspectionConst.STEP_RESERVATION_COMPLETE ||
                selectedInspection.current_step ===
                  InspectionConst.STEP_NO_SHOW) && (
                <Button
                  disabled={loading}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setInspectionCompleteDialogShow(true);
                  }}
                >
                  검사 완료 처리
                </Button>
              )}
              <Button
                disabled={loading}
                variant="contained"
                size="small"
                onClick={handleSaveClick}
              >
                저장
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>

      <AlertDialog
        open={inspectionCompleteDialogShow}
        handleClose={() => {
          setInspectionCompleteDialogShow(false);
        }}
        title={"검사 완료"}
        loading={loading}
        content={"해당 검사건을 정말 검사완료 처리하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setInspectionCompleteDialogShow(false);
        }}
        handleButton2Click={async () => {
          setLoading(true);
          const update_data = {
            current_step: InspectionConst.STEP_INSPECTION_COMPELTE,
          };
          try {
            const {status, data} = await inspectionApi.updateInspection(
              authCookies.access_token,
              selectedInspection.id,
              update_data,
            );
            if (status === 200) {
              setUpdatedData(data);
              setInspectionCompleteDialogShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />

      <AlertDialog
        open={reservationCancelDialogShow}
        handleClose={() => {
          setReservationCancelDialogShow(false);
        }}
        title={"예약 취소"}
        loading={loading}
        content={"예약을 정말 취소하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setReservationCancelDialogShow(false);
        }}
        handleButton2Click={async () => {
          setLoading(true);
          try {
            const {status, data} = await reservationApi.cancelReservation(
              authCookies.access_token,
              selectedInspection.id,
            );
            if (status === 200) {
              setUpdatedData({});
              setReservationCancelDialogShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />
      <ReservationModal
        open={reservationCreateModalShow}
        setOpen={setReservationCreateModalShow}
        handleClose={() => {
          setReservationCreateModalShow(false);
        }}
        selectedInspection={selectedInspection}
        setUpdatedData={setUpdatedData}
        authCookies={authCookies}
        isCreate={true}
      />
      <ReservationModal
        open={reservationChangeModalShow}
        setOpen={setReservationChangeModalShow}
        handleClose={() => {
          setReservationChangeModalShow(false);
        }}
        selectedInspection={selectedInspection}
        setUpdatedData={setUpdatedData}
        authCookies={authCookies}
      />
      <InspectionMissedModal
        open={missedModalShow}
        handleClose={() => {
          setMissedModalShow(false);
        }}
        title={"부재 처리"}
        loading={loading}
        content={"해당 검사건을 정말 부재처리 하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setMissedModalShow(false);
        }}
        handleButton2Click={async missedReason => {
          setLoading(true);
          const update_data = {
            current_step: InspectionConst.STEP_MISSED,
            missedReason: missedReason,
          };
          try {
            const {status, data} = await inspectionApi.updateInspection(
              authCookies.access_token,
              selectedInspection.id,
              update_data,
            );
            if (status === 200) {
              setUpdatedData(data);
              setMissedModalShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />
      <AssignmentCancelModal
        open={assignmentCancelDialogShow}
        handleClose={() => {
          setAssignmentCancelDialogShow(false);
        }}
        title={"배정 취소"}
        loading={loading}
        content={"배정을 정말 취소하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setAssignmentCancelDialogShow(false);
        }}
        handleButton2Click={async cancelReason => {
          setLoading(true);
          try {
            const {status, data} = await assignmentApi.cancelAssignment(
              authCookies.access_token,
              selectedInspection.id,
              cancelReason,
            );
            if (status === 200) {
              setUpdatedData(data);
              setAssignmentCancelDialogShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />

      <ReservationLinkModal
        open={reservationLinkModalShow}
        setOpen={setReservationLinkModalShow}
        handleClose={() => {
          setReservationLinkModalShow(false);
        }}
        handleSend={handleSend}
        driver_phone={selectedInspection.driver_phone_number}
        representative_phone={selectedInspection.representative_phone_number}
        uuid={selectedInspection.uuid}
      />
    </Box>
  );
};

export default InspectionDetail;
