import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {policyApi} from "../../../src/api";

const termsEnum = {
  "자카 서비스 이용약관": 1,
  "개인정보 처리방침": 2,
  "자카 위치기반 서비스 이용약관": 3,
  "자카 마케팅 활용 및 광고성 정보 수신동의": 4,
};

const TermsModal = ({handleClose, title, open}) => {
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    getTermsModalContent();
  }, []);

  const getTermsModalContent = async () => {
    try {
      const {status, data} = await policyApi.getPolicy(termsEnum[title]);
      if (status === 200) {
        setModalContent(data.content);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
      <section className="p-2 mb-2 bg-gray-100 border border-gray-300 ">
        <div
          className="pb-2 h-[500px] overflow-auto"
          dangerouslySetInnerHTML={{__html: modalContent}}
        ></div>
      </section>
      <section>
        <div className="absolute flex flex-row justify-end bottom-8 right-8">
          <div className="space-x-2">
            <Button variant="contained" onClick={handleClose}>
              약관확인
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsModal;
