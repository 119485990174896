import React from 'react';
import Router from './Router';
import { ThemeProvider } from '@mui/material';
import theme from '../../material.theme.js';
import LoginContextProvider from '../contexts/LoginContext';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LoginContextProvider>
          <BrowserRouter>
            <Router></Router>
          </BrowserRouter>
        </LoginContextProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
