import {useEffect, useState} from "react";
import dayjs from "dayjs";

import {
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Logo from "../../assets/logo/logo_nav.png";
import {useDaumPostcodePopup} from "react-daum-postcode";

import * as InspectionConst from "../../constants/inspection";

import JacarDatePicker from "../inspection/JacarDatePicker";
import {b2cReservationApi, reservationApi} from "../../api";
import {PAGE_TYPE_B2B} from "../../routes/inspection/Inspection";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
const today = dayjs();

const isHoliday = date => {
  let ret = false;
  const day = date.day();
  const stringDate = dayjs(date).format("YYYY-MM-DD");
  const disableDates = [
    "2023-05-05",
    "2023-06-06",
    "2023-08-15",
    "2023-09-28",
    "2023-09-29",
    "2023-09-30",
    "2023-10-03",
    "2023-10-09",
    "2023-12-25",
  ];
  if (day === 0) {
    ret = true;
  }

  disableDates.forEach(d => {
    if (d === stringDate) {
      ret = true;
    }
  });

  return ret;
};
const ReservationModal = ({
  open,
  setOpen,
  handleClose,
  selectedInspection,
  setUpdatedData,
  authCookies,
  isCreate = false,
  pageType = PAGE_TYPE_B2B,
}) => {
  const [reservationDate, setReservationDate] = useState(null);
  const [isSatSelected, setIsSatSelected] = useState(false);
  const [reservationTime, setReservationTime] = useState(-1);
  const [reservationType, setReservationType] = useState(
    InspectionConst.RESERVATION_TYPE1,
  );
  const [pickupAddress, setPickupAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const postOpen = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
  );
  useEffect(() => {
    setReservationDate(selectedInspection.reservation_date);
    setReservationTime(selectedInspection.reservation_time);
    setPickupAddress(selectedInspection.pickup_address);
    setReservationType(selectedInspection.reservation_type);
    setError("");
  }, [open, selectedInspection]);

  useEffect(() => {
    const day = dayjs(reservationDate).day();
    setIsSatSelected(day === 6);
    setReservationTime(-1);
  }, [reservationDate]);

  useEffect(() => {
    setError("");
  }, [reservationDate, reservationTime, pickupAddress]);
  const handleAddrChange = () => {
    postOpen({
      onComplete: data => {
        let fullAddress = data.address;

        setPickupAddress(fullAddress);
      },
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (!reservationDate) {
      setError("예약일자를 선택해주세요.");
      return;
    }
    if (reservationTime === -1) {
      setError("예약시간을 선택해주세요.");
      return;
    }
    if (!pickupAddress) {
      setError("픽업주소를 설정해주세요.");
      return;
    }
    setLoading(true);

    try {
      if (pageType === PAGE_TYPE_B2B) {
        const {status, data} = await reservationApi.updateReservation(
          authCookies.access_token,
          selectedInspection.id,
          pickupAddress,
          reservationDate,
          reservationTime,
          reservationType,
        );
        if (status === 200) {
          setUpdatedData(data);
          setOpen(false);
        } else {
          setError("서버 에러: status is not 200");
        }
      } else {
        const {status, data} = await b2cReservationApi.updateReservation(
          authCookies.access_token,
          selectedInspection.id,
          pickupAddress,
          reservationDate,
          reservationTime,
          reservationType,
        );
        if (status === 200) {
          setUpdatedData(data);
          setOpen(false);
        } else {
          setError("서버 에러: 상태 값이 성공 값이 아닙니다.");
        }
      }
    } catch (e) {
      const response = e.response;
      const {data, status} = response;
      setError(`서버 에러 ${status}, ${JSON.stringify(data)}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Box position={"relative"} sx={style}>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={"10px"}
            gap={1}
          >
            <EventAvailableIcon fontSize={"large"} />
            <Typography id="modal-modal-title" variant="h6" component="h6">
              {isCreate ? "검사 예약 생성" : "검사 예약 변경"}
            </Typography>
          </Stack>

          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={1}
            gap={1}
          >
            <Typography minWidth={"100px"}>예약유형</Typography>
            <FormControl sx={{width: "100%"}} size="small">
              <RadioGroup
                row
                name="reservation_type"
                value={reservationType}
                onChange={e => {
                  setReservationType(e.target.value);
                }}
              >
                <FormControlLabel
                  value={InspectionConst.RESERVATION_TYPE1}
                  control={<Radio />}
                  label={InspectionConst.RESERVATION_TYPE1}
                />
                <FormControlLabel
                  value={InspectionConst.RESERVATION_TYPE2}
                  control={<Radio />}
                  label={InspectionConst.RESERVATION_TYPE2}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={1}
            gap={1}
          >
            <Typography minWidth={"100px"}>예약일자</Typography>
            <JacarDatePicker
              required
              date={reservationDate}
              setDate={setReservationDate}
              disablePast
              minDate={today}
              shouldDisableDate={isHoliday}
            />
          </Stack>
          <Stack
            alignItems={"center"}
            direction={"row"}
            marginBottom={1}
            gap={1}
          >
            <Typography minWidth={"100px"}>예약시간</Typography>
            <FormControl sx={{width: "100%"}} size="small">
              <Select
                required
                value={reservationTime ? reservationTime : -1}
                onChange={e => {
                  setReservationTime(e.target.value);
                }}
              >
                <MenuItem value={-1}>선택안됨</MenuItem>
                {isSatSelected &&
                  InspectionConst.RESERVATION_SATURDAY_TIME_LIST.map(val => {
                    return (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    );
                  })}
                {!isSatSelected &&
                  InspectionConst.RESERVATION_TIME_LIST.map(val => {
                    return (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Stack>
          {reservationType === InspectionConst.RESERVATION_TYPE1 && (
            <Stack
              alignItems={"center"}
              direction={"row"}
              gap={1}
              marginBottom={1}
            >
              <Typography minWidth={"100px"}>픽업 주소</Typography>
              <Stack
                sx={{width: "100%"}}
                direction={"row"}
                gap="5px"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <TextField
                  sx={{
                    width: "100%",
                    padding: 0,
                  }}
                  maxRows={1}
                  margin={"none"}
                  size="small"
                  value={pickupAddress ? pickupAddress : ""}
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  required
                  onClick={handleAddrChange}
                />
                <Button
                  variant="contained"
                  onClick={handleAddrChange}
                  sx={{width: "50px"}}
                >
                  변경
                </Button>
              </Stack>
            </Stack>
          )}

          <Box marginTop={1} marginBottom={4} textAlign={"center"}>
            <Typography variant="h6" color={"error"}>
              {error}
            </Typography>
          </Box>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <img width={"70px"} src={Logo} alt="logo" />
            <Stack direction={"row"} gap={1}>
              <Button
                disabled={loading}
                sx={{width: "90px"}}
                variant="outlined"
                onClick={handleClose}
              >
                닫기
              </Button>
              <Button
                disabled={loading}
                sx={{width: "90px"}}
                type="submit"
                variant="contained"
              >
                예약
              </Button>
            </Stack>
          </Stack>
          {loading && (
            <Box
              position={"absolute"}
              sx={{
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </form>
    </Modal>
  );
};

export default ReservationModal;
