import React from "react";
import {useLocation} from "react-router-dom";
import {
  PATH_B2B_INSPECTION,
  PATH_B2C_INSPECTION,
  PATH_LOGIN,
  PATH_PASSWORD,
  PATH_SIGNUP,
} from "./Router";
import {VERSION} from "../../constants/index";
const Footer = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === PATH_LOGIN ||
      location.pathname === PATH_PASSWORD ||
      location.pathname === PATH_SIGNUP ? (
        <footer
          className={
            (location.pathname !== PATH_SIGNUP ? "absolute bottom-0 " : null) +
            " flex flex-col items-center w-full border-t-2 border-t-secondary-500"
          }
        >
          <span className="mt-3 text-sm text-gray-500">ⓒJACAR Co.,LTD.</span>
          <span className="text-sm text-gray-500">
            경상남도 김해시 분성로 728(지내동)
          </span>
          <span className="text-sm text-gray-500">Tel: 1522-0029</span>
          <span className="text-sm text-gray-500">
            사업자 등록번호: 888-88-01028 | 대표자: 장명찬 | 상호명: 주식회사
            자카
          </span>
          <span className="text-sm text-gray-500">
            통신판매업신고 : 제2023-경남김해-1499호
          </span>
          <span className="mb-3 text-sm text-gray-500">
            개인정보관리책임자: 최경수
          </span>
          {/* <div className="text-gray-500">{VERSION}</div> */}
        </footer>
      ) : location.pathname === PATH_B2B_INSPECTION ||
        location.pathname === PATH_B2C_INSPECTION ? (
        ""
      ) : (
        <div className="fixed bottom-3 right-6">
          <span className="text-sm text-gray-500">ⓒJACAR Co.,LTD.</span>
        </div>
      )}
    </>
  );
};

export default Footer;
