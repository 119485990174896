import React, {useState, useContext} from "react";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {centerApi} from "../../api";
import {Typography, TextField, FormControlLabel, Checkbox} from "@mui/material";
import {useCookies} from "react-cookie";
import {LoginContext} from "../contexts/LoginContext";

const WithdrawalModal = ({handleClose}) => {
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [cookies] = useCookies();
  const {logout} = useContext(LoginContext);

  const canWithdrawal = e => {
    setChecked(!checked);
  };

  const onChange = e => {
    setPassword(e.target.value);
  };

  const withrawalCenter = async () => {
    try {
      const res = await centerApi.deleteCenter(
        {password: password},
        cookies.access_token,
      );
      if (res.status === 204) {
        setCheckPassword(false);
        handleClose();
        logout();
      } else {
        setCheckPassword(true);
      }
    } catch (err) {
      setCheckPassword(true);
    }
  };

  return (
    <>
      <section className="pb-2 pl-2">
        <Typography sx={{fontSize: 14, fontWeight: 700}}>
          회원님의 계정을 삭제하시겠습니까?
        </Typography>
        <Typography sx={{fontSize: 12}}>
          회원탈퇴를 신청하기 전에 안내 사항을 확인하고 신중하게 선택하시기
          바랍니다.
        </Typography>
      </section>
      <section className="p-2 mb-2 bg-gray-100 border border-gray-300">
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            사용하고 계신 아이디는 탈퇴할 경우 재사용 및 복구가 불가능합니다.
          </Typography>
        </div>
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            탈퇴 후 계정 정보 및 개인형 서비스 이용기록은 모두 삭제됩니다.
          </Typography>
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            단, 아래 항목은 표기된 법률에 따라 특정 기간 동안 보관됩니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            1. 소비자보호에 관한 법률 제6조에 의거, 계약 또는 청약철회 등에 관한
            기록 / 5년
          </Typography>
          <Typography sx={{fontSize: 12}}>
            2. 대급결제 및 재화등의 공급에 관한 기록 / 5년
          </Typography>
          <Typography sx={{fontSize: 12}}>
            3. 소비자의 불만 또는 분쟁처리에 관한 기록 / 3년
          </Typography>
          <Typography sx={{fontSize: 12}}>
            개인정보는 법률에 의한 보유 목적 외에 다른 목적으로는 이용되지
            않습니다.
          </Typography>
        </div>
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            탈퇴 후에도 게시판형 서비스에 등록한 게시물은 그대로 남아있습니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            회원탈퇴 후 게시글 및 댓글 등은 자동 삭제되지 않고 그대로 남아
            있습니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            게시물 삭제를 원하시는 경우에는 먼저 해당 게시물을 삭제 하신 후,
            탈퇴를 신청하시기 바랍니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            탈퇴 후에는 회원정보가 삭제되어 본인 여부를 확인할 수 있는 방법이
            없어, 게시글을 임의로 삭제해드릴 수 없습니다.
          </Typography>
        </div>
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            포인트, 쿠폰은 소멸되며 환불하지 않습니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            회원탈퇴 시 보유한 포인트는 즉시 소멸되어 탈퇴 회원이 재가입하더라도
            기존의 포인트는 이미 소멸되었기 때문에 양도되지 않습니다.
          </Typography>
        </div>
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            소속된 회사가 존재할 경우 '탈퇴'직원으로 조회됩니다.
          </Typography>
          <Typography sx={{fontSize: 12}}>
            회사에 귀속된 데이터에 대해서는 보관됩니다.
          </Typography>
        </div>
      </section>
      <section className="p-2 bg-gray-100 border border-gray-300">
        <div className="pb-2">
          <Typography sx={{fontSize: 14, fontWeight: 700}}>
            비밀번호 확인
          </Typography>
          <Typography sx={{fontSize: 12}}>
            본인확인을 위해서 비밀번호를 입력해 주세요.
          </Typography>
        </div>

        <div className="flex items-center mb-3">
          <div className="flex">
            <Typography
              className="pr-1 text-secondary"
              sx={{fontSize: 14, fontWeight: 700}}
            >
              비밀번호
            </Typography>
            <Typography
              className="pr-4 text-primary"
              sx={{fontSize: 14, fontWeight: 700}}
            >
              (필수)
            </Typography>
          </div>
          <div>
            <TextField
              inputProps={{
                style: {
                  height: 26,
                  padding: "0 4px",
                },
              }}
              className="mt-32 w-50"
              size="small"
              type="password"
              variant="outlined"
              onChange={onChange}
            />
          </div>
        </div>
        <div>
          <Typography
            className="pr-1"
            sx={{color: "red", fontSize: 12, fontWeight: 700}}
            hidden={!checkPassword}
          >
            비밀번호가 일치하지 않습니다.
          </Typography>
        </div>
      </section>
      <section>
        <div>
          <FormControlLabel
            sx={{marginRight: 0.5}}
            label={
              <Typography sx={{fontSize: 12}}>
                안내사항을 모두 확인하였으며, 이에 동의합니다.
              </Typography>
            }
            control={
              <Checkbox
                onChange={canWithdrawal}
                checked={checked}
                sx={{
                  opacity: 0.6,
                  "&.Mui-checked": {
                    color: "primary",
                    opacity: 1,
                  },
                }}
              />
            }
          />
        </div>
        <div className="absolute flex flex-row justify-end bottom-8 right-8">
          <div className="space-x-2">
            <Button variant="outlined" onClick={handleClose}>
              취소
            </Button>
            {checked ? (
              <Button variant="outlined" onClick={withrawalCenter}>
                회원탈퇴
              </Button>
            ) : (
              <Button variant="outlined" disabled>
                회원탈퇴
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default WithdrawalModal;
