/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";

import Navbar from "./Navbar";
import Main from "../../routes/Main";
import Inspection, {
  PAGE_TYPE_B2B,
  PAGE_TYPE_B2C,
} from "../../routes/inspection/Inspection";
import Post from "../../routes/post/Post";
import Mypage from "../../routes/mypage/Mypage";
import Company from "../../routes/company/Company";
import CompanyModify from "../../routes/company/CompanyModify";
import Book from "../Book";
import Payment from "../../routes/payment/Payment";
import InspectionInfo from "../../routes/inspectionInfo/InspectionInfo";
import PastInspection from "../../routes/pastInspection/PastInspection";

import Login from "../../routes/auth/Login";
import Password from "../../routes/auth/Password";
import Signup from "../../routes/auth/Signup";
import Footer from "../common/Footer";
import {
  LoginContext,
  LOGIN_STATUS_PENDING,
  LOGIN_STATUS_LOGIN,
  LOGIN_STATUS_LOGOUT,
} from "../contexts/LoginContext";
import {authApi} from "../../api";
import {useCookies} from "react-cookie";
import PastInspectionSetting from "../../routes/pastInspection/PastInspectionSetting";
import PastInspectionCreate from "../../routes/pastInspection/PastInspectionCreate";

export const PATH_HOME = "/";
export const PATH_B2B_INSPECTION = "/business/inspection";
export const PATH_B2C_INSPECTION = "/customer/inspection";
export const PATH_NOTICE = "/notice";
export const PATH_MYPAGE = "/mypage";
export const PATH_COMPANY = "/company";
export const PATH_COMPANY_MODIFY = "/company_modify";
export const PATH_BOOK = "/book";
export const PATH_LOGIN = "/login";
export const PATH_PASSWORD = "/password";
export const PATH_SIGNUP = "/signup";
export const PATH_PAYMENT = "/payment";
export const PATH_INSPECTION_INFO = "/inspection_info";
export const PATH_PAST_INSTPECTION = "/past_inspection";
export const PATH_PAST_INSPECTION_SETTING = "/past_inspection_setting";
export const PATH_PAST_INSPECTION_CREATE = "/past_inspection_create";

const Router = () => {
  const {loginInfo, checkLogin} = useContext(LoginContext);
  // const [cookies] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loginInfo.isLogin === LOGIN_STATUS_LOGOUT) {
      navigate(PATH_LOGIN);
    } else if (loginInfo.isLogin === LOGIN_STATUS_LOGIN) {
      if (location.pathname === PATH_LOGIN) {
        navigate(PATH_HOME);
      } else {
      }
    } else {
    }
  }, [loginInfo]);

  useEffect(() => {
    if (
      location.pathname !== PATH_LOGIN &&
      location.pathname !== PATH_SIGNUP &&
      location.pathname !== PATH_PASSWORD
    ) {
      if (
        loginInfo.isLogin === LOGIN_STATUS_PENDING ||
        loginInfo.isLogin === LOGIN_STATUS_LOGIN
      )
        checkLogin(true);
      else checkLogin(false);
    }
  }, [location.pathname]);

  // const checkRefresh = async () => {
  //   const {status} = await authApi.verifyToken(cookies.refresh_token);
  //   if (status === 200) return true;
  //   return false;
  // };

  return (
    <>
      <Navbar />
      <Routes>
        <Route path={PATH_HOME} element={<Main />} />
        <Route
          path={PATH_B2B_INSPECTION}
          element={<Inspection pageType={PAGE_TYPE_B2B} />}
        />
        <Route
          path={PATH_B2C_INSPECTION}
          element={<Inspection pageType={PAGE_TYPE_B2C} />}
        />

        <Route path={`${PATH_NOTICE}/:id`} element={<Post />} />

        <Route path={PATH_MYPAGE} element={<Mypage />} />
        <Route path={PATH_COMPANY} element={<Company />} />
        <Route path={PATH_COMPANY_MODIFY} element={<CompanyModify />} />
        <Route path={PATH_BOOK} element={<Book />} />
        <Route path={PATH_PAYMENT} element={<Payment />} />
        <Route path={PATH_INSPECTION_INFO} element={<InspectionInfo />} />
        <Route path={PATH_PAST_INSTPECTION} element={<PastInspection />} />
        <Route
          path={PATH_PAST_INSPECTION_SETTING}
          element={<PastInspectionSetting />}
        />
        <Route
          path={PATH_PAST_INSPECTION_CREATE}
          element={<PastInspectionCreate />}
        />
        <Route path={PATH_LOGIN} element={<Login />} />
        <Route path={PATH_PASSWORD} element={<Password />} />
        <Route path={PATH_SIGNUP} element={<Signup />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
