import axios from "axios";

import {JACAR_API_URL} from "../constants";
import * as B2BInspectionConst from "../constants/inspection";
import * as B2CInspectionConst from "../constants/b2cInspection";

const api = axios.create({
  baseURL: JACAR_API_URL,
});

const AUTH_V1_URL = "api/v1/auth";
const CERTIFICATION_V1_URL = "api/v1/certifications";
const INSPECTION_V1_URL = "api/v1/inspections";
const CENTER_V1_URL = "api/v1/centers";
const POINTHISTORY_V1_URL = "api/v1/centers/me/point_histories";
const PRICE_V1_URL = "api/v1/centers/me/priceb2c";
const NOTICE_V1_URL = "api/v1/notices";
const POLICY_V1_URL = "api/v1/policies";
const RESERVATION_V1_URL = "api/v1/inspections";
const B2C_INSPECTION_V1_URL = "api/v1/b2c_inspections";
const B2C_POINT_V1_URL = "api/v1/points";
const B2C_PAYMENT_V1_URL = "api/v1/payments";
const B2C_PAST_INSPECTION_V1_URL = "api/v1/past_inspections";
const INSPECTION_PRICE_V1_URL = "api/v1/point_prices";

api.defaults.headers.common["Content-Type"] = "application/json";

/** AUTH API */
export const authApi = {
  /** LOGIN */
  login: data => {
    return api.post(`${AUTH_V1_URL}/login/`, data);
  },

  /** REFRESH TOKEN */
  refreshToken: data => api.post(`${AUTH_V1_URL}/token/refresh/`, data),

  /** VERIFY TOKEN(ACCESCC OR REFRESH) */
  verifyToken: data => api.post(`${AUTH_V1_URL}/token/verify/`, data),

  /** SIGNUP */
  signup: formData =>
    api.post(`${AUTH_V1_URL}/registration`, formData, {
      headers: {"Content-Type": "multipart/form-data"},
    }),

  /** CHANGE PASSWORD */
  changePassword: (data, access_token) =>
    api.post(`${AUTH_V1_URL}/password/change/`, data, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  /** LOGOUT */
  logout: data => api.post(`${AUTH_V1_URL}/logout/`, data),
};

/** CERTIFICATION API */
export const certificationApi = {
  /** REQUEST CERTIFICATION NUMBER FOR MOBILE */
  certPhoneNum: phone_number => {
    return api.post(`${CERTIFICATION_V1_URL}/registration/`, {
      phone_number,
    });
  },

  /** RESPONSE CERTIFICATION NUMBER BY MOBILE */
  certNum: (phone_number, certification_number) => {
    return api.post(
      `${CERTIFICATION_V1_URL}/registration/confirm/${phone_number}/`,
      {
        certification_number,
      },
    );
  },

  /** CHANGE PASSWOR TO TEMP PASSWORD BY MOBILE */
  changeTempPassword: body =>
    api.post(`${CERTIFICATION_V1_URL}/password/confirm/${body.phone_number}/`, {
      username: body.username,
      certification_number: body.certification_number,
    }),

  /** CERTIFICATION USER BY MOBILE */
  certificationUser: body =>
    api.post(`${CERTIFICATION_V1_URL}/password/`, {
      phone_number: body.phone_number,
      username: body.username,
    }),

  // 사용 안하고있음
  // certPassword: body =>
  //   api.post(
  //     `${CERTIFICATION_V1_URL}/registration/confirm/${body.phone_number}/`,
  //     {
  //       certification_number: body.certification_number,
  //       username: body.username,
  //     },
  //   ),
};

/** Center API */
export const centerApi = {
  /** GET CUSTOMER DATA */
  myData: access_token => {
    return api.get(`${CENTER_V1_URL}/me/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },

  myPage: access_token =>
    api.get(`${CENTER_V1_URL}/me/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  modifyMypage: (formData, access_token) =>
    api.put(`${CENTER_V1_URL}/me/`, formData, {
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "multipart/form-data",
      },
    }),

  putCenterDetail: (payload, access_token) =>
    api.put(`${CENTER_V1_URL}/me/`, payload, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  getCenterDetail: access_token =>
    api.get(`${CENTER_V1_URL}/me/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  deleteCenter: (data, access_token) =>
    api.delete(`${CENTER_V1_URL}/me/`, {
      data: data,
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),
};

/** POINT API */
export const pointApi = {
  getPointList: (access_token, params) =>
    api.get(`${POINTHISTORY_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params,
    }),
};

/** PRICE API */
export const priceApi = {
  getPriceList: access_token =>
    api.get(`${PRICE_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  putPriceList: (payload, access_token) =>
    api.put(`${PRICE_V1_URL}/`, payload, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),
};

/** NOTICE API */
export const noticeApi = {
  /** GET NOTICE ALL(NON HIDDEN) */
  getNoticeList: access_token =>
    api.get(`${NOTICE_V1_URL}/all_non_hidden/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),

  /** GET NOTICE DETAIL */
  getNoticeDetail: (pageNum, access_token) =>
    api.get(`${NOTICE_V1_URL}/${pageNum}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    }),
};

export const policyApi = {
  getPolicy: termsNum => api.get(`${POLICY_V1_URL}/${termsNum}/`),
};

export const inspectionApi = {
  getInspectionList: function (
    access_token,
    page,
    page_size,
    start_date,
    end_date,
    word,
    steps,
    order_by,
  ) {
    return api.get(`${INSPECTION_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params: {
        page,
        page_size,
        start_date,
        end_date,
        word,
        steps,
        order_by,
      },
    });
  },

  updateInspection: function (access_token, id, data) {
    return api.put(`${INSPECTION_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },
  modifyInspection: body => api.put("api/v1/carinspection_board", body),
  sendNotice: function (access_token, id, phone_number) {
    return api.get(`${INSPECTION_V1_URL}/${id}/send/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params: {
        phone_number,
      },
    });
  },
};

export const notificationApi = {
  notification: (phone, uuid, noticeCount) => {
    api.post("api/v1/jacar_notification/" + uuid, {
      lotte_user_phone_number: phone,
      lotte_user_uuid: uuid,
      "1st_2nd_Division": noticeCount,
    });
  },
};

export const reservationApi = {
  updateReservation: function (
    access_token,
    id,
    pickup_address,
    reservation_date,
    reservation_time,
    reservation_type,
  ) {
    return api.put(
      `${RESERVATION_V1_URL}/${id}/reservation/`,
      {
        pickup_address,
        reservation_date,
        reservation_time,
        reservation_type,
        current_step: B2BInspectionConst.STEP_RESERVATION_COMPLETE,
      },
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      },
    );
  },
  cancelReservation: function (access_token, id) {
    return api.put(
      `${RESERVATION_V1_URL}/${id}/reservation/cancel/`,
      {},
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      },
    );
  },
};

export const assignmentApi = {
  cancelAssignment: function (
    access_token,
    inspection_pk,
    assignment_cancel_reason,
  ) {
    return api.put(
      `${INSPECTION_V1_URL}/${inspection_pk}/assignments/cancel/`,
      {assignment_cancel_reason},
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      },
    );
  },
};

export const b2cInspectionApi = {
  getInspectionList: function (
    access_token,
    page,
    page_size,
    start_date,
    end_date,
    word,
    steps,
    order_by,
  ) {
    return api.get(`${B2C_INSPECTION_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params: {
        page,
        page_size,
        start_date,
        end_date,
        word,
        steps,
        order_by,
      },
    });
  },

  updateInspection: function (access_token, id, data) {
    return api.put(`${B2C_INSPECTION_V1_URL}/${id}/`, data, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },
};

export const b2cReservationApi = {
  updateReservation: function (
    access_token,
    id,
    pickup_address,
    reservation_date,
    reservation_time,
    reservation_type,
  ) {
    return api.put(
      `${B2C_INSPECTION_V1_URL}/${id}/reservation/`,
      {
        pickup_address,
        reservation_date,
        reservation_time,
        reservation_type,
        current_step: B2CInspectionConst.STEP_RESERVATION_COMPLETE,
      },
      {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      },
    );
  },
};

/** PAYMENT POINT LIST */
export const paymentApi = {
  getPointPaymenList: function (access_token) {
    return api.get(`${B2C_POINT_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },

  paymentPrepare: function (access_token, point_id) {
    return api.get(`${B2C_PAYMENT_V1_URL}/prepare/${point_id}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },

  paymentComplete: function (access_token, payload) {
    return api.post(`${B2C_PAYMENT_V1_URL}/complete/`, payload, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },
};

/** PAST INSPECTION LIST */
export const pastInspectionApi = {
  createPastInspectionList: function (access_token, pastInspectionList) {
    return api.post(`${B2C_PAST_INSPECTION_V1_URL}/`, pastInspectionList, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },

  /* 지난 검사 목록 조회 */
  getPastInspectionList: function (access_token, params) {
    return api.get(`${B2C_PAST_INSPECTION_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params,
    });
  },

  /* 지난 검사 목록 삭제 */
  deletePastInspectionList: function (access_token, payload) {
    return api.delete(`${B2C_PAST_INSPECTION_V1_URL}/`, {
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
  },

  /* 지난 검사 알림톡 전송 */
  pushPastInspectionAlitok: function (access_token, payload) {
    return api.put(`${B2C_PAST_INSPECTION_V1_URL}/send_notice_msg/`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    });
  },

  /* 검사 가격 조회 */
  getInspectionPointPrice: function (access_token) {
    return api.get(`${INSPECTION_PRICE_V1_URL}/`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
  },
};
