import {
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {Link, useNavigate} from "react-router-dom";
import DialogAlert from "../../components/modal/DialogAlert";

import axios from "axios";
import {authApi} from "../../../src/api";
import {certificationApi} from "../../../src/api";
// import TermsModal from "../../components/modal/TermsModal";
import NestedModal from "../../components/common/Modal";

const TERMS_PERSON_MODAL = "개인정보 처리방침";
const TERMS_RULSE_MODAL = "자카 서비스 이용약관";
const TERMS_LOCATION_MODAL = "자카 위치기반 서비스 이용약관";
const TERMS_ADVERTISEMENT_MODAL = "자카 마케팅 활용 및 광고성 정보 수신동의";

const Signup = () => {
  const inputData = {
    username: "",
    password1: "",
    password2: "",
    name: "",
    ceo_name: "",
    ceo_phone_number: "",
    center_phone_number: "",
    certification_number: "",
    address: "",
    address_detail: "",
    license_number: "",
  };

  const [inputs, setInputs] = useState(inputData);
  const [allCheck, setAllCheck] = useState(false);
  const [checked, setChecked] = useState({
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
    checkBox4: false,
  });
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [validPw, setValidPw] = useState(true);
  const [checkPw, setCheckPw] = useState(true);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [termsModalContent, setTermsModalContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [checkRegist, setCheckRegist] = useState(true);
  const [certificated, setCertificated] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkTermsOfUse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const certificationPhone = async event => {
    if (inputs.ceo_phone_number === "") {
      setModalContent("대표자 전화번호를 입력해 주세요.");
      setModalOpen(true);
      return;
    }

    try {
      let phone_number = inputs.ceo_phone_number;
      const res = await certificationApi.certPhoneNum(phone_number);
      if (res.status === 200) {
        setCertificated(true);
        setModalContent(event.target.name);
        setModalOpen(true);
      }
    } catch (err) {
      setModalContent("잘못된 전화번호 입니다.");
      setModalOpen(true);
      console.error(err);
    } finally {
    }
  };

  const certificationCertNum = async event => {
    try {
      let phone_number = inputs.ceo_phone_number;
      let certification_number = inputs.certification_number;
      const res = await certificationApi.certNum(
        phone_number,
        certification_number,
      );

      if (res.status === 200) {
        if (res.data === "인증 성공") {
          setCertificated(false);
          setModalContent(event.target.name);
          setModalOpen(true);
        } else {
          setCertificated(true);
          setModalContent("잘못된 인증번호 입니다.");
          setModalOpen(true);
        }
      }
    } catch (err) {
      if (err.response.data === "유효기간 만료됨") {
        setCertificated(true);
        setModalContent("만료된 인증번호 입니다.");
        setModalOpen(true);
      } else {
        setCertificated(true);
        setModalContent("잘못된 인증번호 입니다.");
        setModalOpen(true);
      }
    }
  };

  const navigate = useNavigate();
  const handleClose = () => {
    if (modalContent === "회원가입이 완료되었습니다.") {
      setModalOpen(false);
      navigate("/login");
    }
    setModalOpen(false);
  };

  const certification = async () => {
    const apiKey = process.env.REACT_APP_CERTIFICATION_API_KEY;

    try {
      const res = await axios.get(
        `https://bizno.net/api/fapi?key=${apiKey}&gb=1&q=${inputs.username}&type=json`,
      );
      if (res.status === 200) {
        const name = res.data.items[0].company;
        setInputs({
          ...inputs,
          name,
        });
        setCheckRegist(true);
      } else {
        setCheckRegist(false);
        setInputs({
          ...inputs,
          name: "",
        });
      }
    } catch (err) {
      setCheckRegist(false);
      setInputs({
        ...inputs,
        name: "",
      });
    } finally {
    }
  };

  const checkPassword = event => {
    if (inputs.password1 === event.target.value || event.target.value === "")
      setCheckPw(true);
    else setCheckPw(false);

    setInputs({
      ...inputs,
      password2: event.target.value,
    });
  };

  const validPassword = event => {
    var regExp = /^(?=.{8,16}$).*/;
    if (regExp.test(event.target.value) || event.target.value === "") {
      setValidPw(true);
    } else {
      setValidPw(false);
    }

    setInputs({
      ...inputs,
      password1: event.target.value,
    });
  };

  const checkTermsOfUse = () => {
    if (checked["checkBox1"] && checked["checkBox2"] && checked["checkBox3"]) {
      setTermsOfUse(true);
      if (checked["checkBox4"]) setAllCheck(true);
    } else setTermsOfUse(false);
  };

  const handleAllCheckBox = event => {
    if (event.target.checked) {
      setAllCheck(true);
      setChecked({
        ...checked,
        checkBox1: true,
        checkBox2: true,
        checkBox3: true,
        checkBox4: true,
      });
    } else {
      setAllCheck(false);
      setChecked({
        ...checked,
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        checkBox4: false,
      });
    }
  };

  const handleItemsCheck = (event, checkBoxNum) => {
    if (event.target.checked) {
      setChecked({
        ...checked,
        [checkBoxNum]: true,
      });
    } else {
      setAllCheck(false);
      setChecked({
        ...checked,
        [checkBoxNum]: false,
      });
    }
  };

  // Input adress by Daum API
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
  );

  const handleComplete = data => {
    let fullAddress = data.address;

    setInputs({
      ...inputs,
      address: fullAddress,
    });
  };

  const handleClickAddress = e => {
    open({onComplete: handleComplete});
  };

  // Restrict Input Files
  const checkSize = async event => {
    let max = 5;
    if (event.target.files && event.target.files[0].size > max * 1024 * 1024) {
      setModalContent(`파일 용량은 ${max}MB 이하로 업로드 가능합니다.`);
      setModalOpen(true);
      event.value = null;
    } else {
      setInputs({
        ...inputs,
        [event.target.name]: event.target.files[0].name,
      });
    }
  };

  // Handle Input
  const onChange = event => {
    const {value, name} = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeNum = event => {
    const {value, name} = event.target;
    setInputs({
      ...inputs,
      [name]: value.replace(/[^0-9]/g, ""),
    });
  };

  const termsModal = event => {
    setTermsModalContent(event.target.name);
    setTermsModalOpen(true);
  };

  const termsModalClose = () => {
    setTermsModalOpen(!termsModalOpen);
  };

  // submit signup
  const submit = async event => {
    event.preventDefault();

    if (certificated) {
      setModalContent("휴대폰번호 인증을 완료해주세요.");
      setModalOpen(true);
      return;
    }
    const formData = new FormData();
    if (event.target.com_registration_file_name.files[0]) {
      formData.append(
        "business_card_image",
        event.target.com_registration_file_name.files[0],
      );
    }

    if (event.target.com_license_file_name.files[0]) {
      formData.append(
        "license_card_image",
        event.target.com_license_file_name.files[0],
      );
    }

    for (let key in inputs) {
      formData.append(key, inputs[key]);
    }

    try {
      setLoading(true);
      const res = await authApi.signup(formData);
      if (res.status === 201) {
        setModalContent("회원가입이 완료되었습니다.");
        setModalOpen(true);
      } else {
        setModalContent("잘못된 접근입니다.");
        setModalOpen(true);
      }
    } catch (err) {
      const errMessage = Object.values(err.response.data)[0][0];
      setModalContent(errMessage);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {termsModalOpen ? (
        <NestedModal
          title={termsModalContent}
          open={termsModal}
          handleClose={termsModalClose}
        />
      ) : null}

      <form onSubmit={submit}>
        <main className="max-w-3xl mx-auto min-w-[600px]">
          <DialogAlert
            open={modalOpen}
            handleClose={handleClose}
            content={modalContent}
          />
          <header className="flex justify-center my-10">
            <span className="text-2xl font-bold">회원가입</span>
          </header>
          {/* 로그인 정보 */}
          {/* ==================================================================== */}
          <section className="mb-8 border-2">
            <header className="py-4 pl-4 font-bold text-white text-normal bg-primary">
              1. 로그인 정보
            </header>
            <article className="p-8">
              <ul className="flex flex-col gap-y-2">
                <li className="flex">
                  <div className="self-center ">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">
                        · 사업자 등록번호
                      </Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                    <Typography sx={{pl: 1}} fontWeight="bold">
                      (아이디)
                    </Typography>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full space-x-2">
                      <TextField
                        required
                        className="w-full"
                        variant="outlined"
                        size="small"
                        name="username"
                        onChange={onChangeNum}
                        value={inputs.username}
                      />

                      <Button
                        sx={{width: "140px"}}
                        color="grayBackground"
                        variant="contained"
                        onClick={certification}
                      >
                        인증
                      </Button>
                    </div>

                    {checkRegist ? (
                      <Typography
                        className="w-full"
                        fontSize="small"
                        color="primary"
                      >
                        사업자 등록번호가 관리자 아이디가 되오니 필히 인증을
                        받으셔야 합니다.
                      </Typography>
                    ) : (
                      <Typography
                        className="w-full"
                        fontSize="small"
                        color="red"
                      >
                        유효하지 않은 사업자등록번호입니다.
                      </Typography>
                    )}
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center ">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">· 비밀번호</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    {/* ================================================================================================ */}
                    <TextField
                      required
                      name="password1"
                      type="password"
                      className="w-full"
                      onChange={validPassword}
                      variant="outlined"
                      size="small"
                      inputProps={{maxLength: 16}}
                    ></TextField>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40 pb-8">
                      <Typography fontWeight="bold">· 비밀번호 확인</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full h-20">
                    <TextField
                      required
                      name="password2"
                      type="password"
                      className="w-full"
                      inputProps={{maxLength: 16}}
                      onChange={checkPassword}
                      variant="outlined"
                      size="small"
                    ></TextField>
                    <Typography
                      fontSize="small"
                      color={validPw ? "primary" : "red"}
                    >
                      비밀번호는 8자 이상으로 영문 및 숫자를 조합하여
                      사용해야합니다.
                    </Typography>
                    <Typography
                      className={checkPw ? "hidden" : null}
                      fontSize="small"
                      color="red"
                    >
                      비밀번호가 일치하지 않습니다.
                    </Typography>
                  </div>
                </li>
              </ul>
            </article>
          </section>

          {/* 대표자 및 업체정보 */}
          {/* ==================================================================== */}
          <section className="mb-8 border-2">
            <header className="py-4 pl-4 font-bold text-white text-normal bg-primary">
              2. 대표자 및 업체정보
            </header>
            <article className="p-8">
              <ul className="flex flex-col gap-y-2">
                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">· 상호명</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <TextField
                      required
                      name="name"
                      className="w-full"
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                      value={inputs.name}
                    ></TextField>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">· 대표자명</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <TextField
                      required
                      name="ceo_name"
                      className="w-full"
                      variant="outlined"
                      size="small"
                      onChange={onChange}
                    ></TextField>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">
                        · 대표자 전화번호
                      </Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>

                  <div className="flex w-full space-x-2">
                    <TextField
                      required
                      error={
                        inputs.ceo_phone_number.includes("-") ? true : false
                      }
                      inputProps={{maxLength: 11}}
                      name="ceo_phone_number"
                      className="w-full"
                      variant="outlined"
                      size="small"
                      label="대시(-) 없이 휴대번호를 입력하여 주세요."
                      onChange={onChange}
                    ></TextField>
                    <Button
                      sx={{width: "140px"}}
                      color="grayBackground"
                      variant="contained"
                      name={`${inputs.ceo_phone_number}로 인증번호가 전송되었습니다.`}
                      onClick={certificationPhone}
                    >
                      휴대폰인증
                    </Button>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-end w-40">
                      <Typography fontWeight="bold"></Typography>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div className="flex w-full space-x-2 ">
                      <TextField
                        className="w-full"
                        variant="outlined"
                        size="small"
                        label="인증번호 6자리를 입력하여 주세요."
                        name="certification_number"
                        inputProps={{maxLength: 6}}
                        onChange={onChange}
                      ></TextField>
                      <Button
                        disabled={!certificated}
                        sx={{width: "140px"}}
                        color="grayBackground"
                        variant="contained"
                        name="인증이 완료되었습니다."
                        onClick={certificationCertNum}
                      >
                        인증
                      </Button>
                    </div>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">· 업체 전화번호</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <TextField
                      required
                      name="center_phone_number"
                      error={
                        inputs.center_phone_number.includes("-") ? true : false
                      }
                      className="w-full"
                      variant="outlined"
                      size="small"
                      label="대시(-) 없이 전화번호를 입력하여 주세요."
                      onChange={onChange}
                    ></TextField>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">· 주소명</Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full space-x-2">
                      <TextField
                        required
                        name="address"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        onClick={handleClickAddress}
                        disabled
                        value={inputs.address}
                      ></TextField>
                      <Button
                        sx={{width: "140px"}}
                        color="grayBackground"
                        variant="contained"
                        onClick={handleClickAddress}
                      >
                        주소검색
                      </Button>
                    </div>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-end w-40">
                      <Typography fontWeight="bold">· 상세주소</Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full space-x-2">
                      <TextField
                        name="address_detail"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                      ></TextField>
                    </div>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-center w-40">
                      <Typography fontWeight="bold">
                        · 정비업등록번호
                      </Typography>
                      <Typography
                        color="primary"
                        fontSize="small"
                        sx={{pl: 0.5}}
                      >
                        (필수)
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="w-full space-x-2">
                      <TextField
                        required
                        name="license_number"
                        className="w-full"
                        variant="outlined"
                        size="small"
                        onChange={onChange}
                      ></TextField>
                    </div>
                  </div>
                </li>
              </ul>
            </article>
          </section>

          {/* 3. 검사소 서류 */}
          {/* ==================================================================== */}
          <section className="mb-8 border-2">
            <header className="py-4 pl-4 font-bold text-white text-normal bg-primary">
              3. 검사소 서류
            </header>
            <article className="p-8">
              <section>
                <Typography color="primary">
                  아직 서류가 준비되지 않으셨나요?
                </Typography>
                <Typography>
                  가입완료 후 재로그인 시 서류 제출이 가능하며
                </Typography>
                <Typography>
                  반드시 서류 제출이 완료되어야 모든 기능을 이용하실 수
                  있습니다.
                </Typography>
                <br />
                <Typography>
                  파일은 5MB 이내로 업로드가 가능하며 아래 형식의 파일만
                  허용합니다.
                </Typography>

                <Typography>- JPEG, JPG, PNG, PDF</Typography>
              </section>
              <ul className="flex flex-col mt-8 gap-y-2">
                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-end w-40">
                      <Typography fontWeight="bold">· 사업자등록증</Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex space-x-2">
                      <TextField
                        disabled
                        className="w-full"
                        variant="outlined"
                        size="small"
                        value={inputs.com_registration_file_name}
                      ></TextField>

                      <Button
                        sx={{width: "140px"}}
                        color="grayBackground"
                        component="label"
                        variant="contained"
                        label="registrator_number"
                      >
                        파일선택
                        <input
                          hidden
                          accept=".jpg, .jpeg, .png, .bmp"
                          multiple
                          type="file"
                          name="com_registration_file_name"
                          onChange={checkSize}
                        />
                      </Button>
                    </div>
                  </div>
                </li>

                <li className="flex">
                  <div className="self-center">
                    <div className="flex items-end w-40">
                      <Typography fontWeight="bold">· 정비업등록증</Typography>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full space-x-2">
                      <TextField
                        disabled
                        className="w-full"
                        variant="outlined"
                        size="small"
                        value={inputs.com_license_file_name}
                      ></TextField>

                      <Button
                        sx={{width: "140px"}}
                        color="grayBackground"
                        component="label"
                        variant="contained"
                        label="lisence_number"
                      >
                        파일선택
                        <input
                          hidden
                          accept=".jpg, .jpeg, .png, .bmp"
                          multiple
                          type="file"
                          name="com_license_file_name"
                          onChange={checkSize}
                        />
                      </Button>
                    </div>
                  </div>
                </li>
              </ul>
            </article>
          </section>

          {/* 4. 약관 동의 */}
          {/* ==================================================================== */}
          <section className="mb-8 border-2">
            <header className="py-4 pl-4 font-bold text-white text-normal bg-primary">
              4. 약관 동의
            </header>
            <article className="p-8">
              <div>
                <FormControlLabel
                  label="전체 동의"
                  control={
                    <Checkbox
                      checked={allCheck}
                      // checked={checked[0] !== checked[1]}
                      onChange={handleAllCheckBox}
                      sx={{
                        opacity: 0.6,
                        "&.Mui-checked": {
                          color: "primary",
                          opacity: 1,
                        },
                      }}
                    />
                  }
                />

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FormControlLabel
                      sx={{marginRight: 0.5}}
                      label="개인정보 처리방침"
                      control={
                        <Checkbox
                          required
                          checked={checked["checkBox1"]}
                          onChange={event =>
                            handleItemsCheck(event, "checkBox1")
                          }
                          sx={{
                            color: "red",
                            opacity: 0.6,
                            "&.Mui-checked": {
                              color: "primary",
                              opacity: 1,
                            },
                          }}
                        />
                      }
                    />
                    <Typography color="primary" fontSize="small">
                      (필수)
                    </Typography>
                  </div>
                  <div className="space-x-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{color: "black"}}
                      name={TERMS_PERSON_MODAL}
                      onClick={termsModal}
                    >
                      전문보기
                    </Button>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FormControlLabel
                      sx={{marginRight: 0.5}}
                      label="자카 서비스 이용약관"
                      control={
                        <Checkbox
                          required
                          checked={checked["checkBox2"]}
                          onChange={event =>
                            handleItemsCheck(event, "checkBox2")
                          }
                          sx={{
                            color: "red",
                            opacity: 0.6,
                            "&.Mui-checked": {
                              color: "primary",
                              opacity: 1,
                            },
                          }}
                        />
                      }
                    />
                    <Typography color="primary" fontSize="small">
                      (필수)
                    </Typography>
                  </div>
                  <div className="space-x-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{color: "black"}}
                      name={TERMS_RULSE_MODAL}
                      onClick={termsModal}
                    >
                      전문보기
                    </Button>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FormControlLabel
                      sx={{marginRight: 0.5}}
                      label="자카 위치기반 서비스 이용약관"
                      control={
                        <Checkbox
                          required
                          checked={checked["checkBox3"]}
                          onChange={event =>
                            handleItemsCheck(event, "checkBox3")
                          }
                          sx={{
                            color: "red",
                            opacity: 0.6,
                            "&.Mui-checked": {
                              color: "primary",
                              opacity: 1,
                            },
                          }}
                        />
                      }
                    />
                    <Typography color="primary" fontSize="small">
                      (필수)
                    </Typography>
                  </div>
                  <div className="space-x-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{color: "black"}}
                      name={TERMS_LOCATION_MODAL}
                      onClick={termsModal}
                    >
                      전문보기
                    </Button>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FormControlLabel
                      sx={{marginRight: 0.5}}
                      label="자카 마케팅 활용 및 광고성 정보 수신동의"
                      control={
                        <Checkbox
                          checked={checked["checkBox4"]}
                          onChange={event =>
                            handleItemsCheck(event, "checkBox4")
                          }
                          sx={{
                            opacity: 0.6,
                            "&.Mui-checked": {
                              color: "primary",
                              opacity: 1,
                            },
                          }}
                        />
                      }
                    />
                    <Typography color="gray" fontSize="small">
                      (선택)
                    </Typography>
                  </div>
                  <div className="space-x-2">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{color: "black"}}
                      name={TERMS_ADVERTISEMENT_MODAL}
                      onClick={termsModal}
                    >
                      전문보기
                    </Button>
                  </div>
                </div>
              </div>
              <div className="h-10 mt-5">
                <Typography
                  className={termsOfUse ? "hidden" : null}
                  fontSize="small"
                  color="#ea171a"
                >
                  이용약관에 동의하여 주세요
                </Typography>
              </div>
            </article>
          </section>

          {/* 하단 버튼 */}
          {/* ==================================================================== */}
          <section>
            <div className="flex justify-center mb-10">
              <div className="space-x-8">
                <Link to="/login">
                  <Button variant="contained" color="grayBackground">
                    이전
                  </Button>
                </Link>

                <Button disabled={loading} type="submit" variant="contained">
                  가입
                </Button>
              </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default Signup;
