import React, {useState, useContext, useEffect} from "react";
import {useCookies} from "react-cookie";
import {Link, useLocation, useNavigate} from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import CampaignIcon from "@mui/icons-material/Campaign";
import BookIcon from "@mui/icons-material/Book";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountMenu from "../navbar/NavMenu";
import mainLogo from "../../assets/logo/logo_nav_white.png";
import {
  PATH_HOME,
  PATH_B2B_INSPECTION,
  PATH_NOTICE,
  PATH_B2C_INSPECTION,
  PATH_INSPECTION_INFO,
  PATH_PAST_INSTPECTION,
  PATH_PAYMENT,
} from "./Router";
import {
  LoginContext,
  LOGIN_STATUS_LOGIN,
  COOKIE_REGISTRATION_NUM,
} from "../contexts/LoginContext";
import {centerApi} from "../../api";
import AlertTab from "../dialogs/alertTab";
import LinkAlert from "../modal/linkAlert";
import {HOST_CENTER_ID} from "../../constants";

const COOKIE_B2C_SERVICE_ENABLED = "b2c_service_enabled";

const Navbar = () => {
  const {loginInfo, refreshCount} = useContext(LoginContext);
  const [userData, setUserData] = useState({});
  const [B2CModal, setB2CModal] = useState(false);

  const [isUseB2C, setIsUseB2C] = useState(false);
  const [alertTab, setAlertTab] = useState(false);
  const VISIT_ONE_DAY = localStorage.getItem("visitOneDay");

  const location = useLocation();
  const [cookies] = useCookies([
    COOKIE_B2C_SERVICE_ENABLED,
    COOKIE_REGISTRATION_NUM,
  ]);
  const navigate = useNavigate();

  const moveToB2C = () => {
    if (cookies.com_registration_number === HOST_CENTER_ID) {
      setB2CModal(!B2CModal);
      getUserData();
      if (isUseB2C) {
        navigate(PATH_B2C_INSPECTION);
        setB2CModal(false);
      }
    } else {
      // Todo : b2c런칭 후 조건문 제거
      alert("오픈 예정 입니다.");
    }
  };
  const moveToPastInspection = () => {
    if (cookies.com_registration_number === HOST_CENTER_ID) {
      setB2CModal(!B2CModal);
      getUserData();
      if (isUseB2C) {
        navigate(PATH_PAST_INSTPECTION);
        setB2CModal(false);
      }
    } else {
      // Todo : b2c런칭 후 조건문 제거
      alert("오픈 예정 입니다.");
    }
  };
  const getUserData = async () => {
    const now = Date.now();
    if (!VISIT_ONE_DAY) localStorage.setItem("visitOneDay", now);

    const {data} = await centerApi.myData(cookies.access_token);

    if (data.b2c_service_enabled) {
      setIsUseB2C(true);
    } else {
      setIsUseB2C(false);
    }
    setUserData(data);

    if (
      !data.point &&
      data.b2c_service_enabled &&
      VISIT_ONE_DAY &&
      VISIT_ONE_DAY < now
    ) {
      setAlertTab(true);
    }
  };

  useEffect(() => {
    getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, cookies.b2c_service_enabled]);
  useEffect(() => {
    if (refreshCount > 0) {
      getUserData();
    }
  }, [refreshCount]);
  return (
    <>
      {B2CModal && !isUseB2C && (
        <LinkAlert
          open={B2CModal}
          handleClose={moveToB2C}
          content={
            "자차검사 예약모드는 자차검사모드를 활성화해야합니다. 검사모드 설정 페이지로 이동하시겠습니까?"
          }
          link={PATH_INSPECTION_INFO}
        />
      )}
      <nav className="sticky top-0 z-10 navbar_container">
        <div className=" flex justify-between items-center bg-[#036EB8]">
          <div className="flex pl-5 my-3 text-4xl font-bold text-white">
            <a href="/">
              <img className="w-24" src={mainLogo} alt="Logo" />
            </a>
          </div>

          {loginInfo.isLogin === LOGIN_STATUS_LOGIN && <AccountMenu />}
        </div>

        {loginInfo.isLogin === LOGIN_STATUS_LOGIN && (
          <div className="flex items-center bg-white">
            <ul className="flex flex-1 py-3 pl-5 link_continer">
              <li>
                <Link to={PATH_HOME} onClick={() => {}}>
                  <div
                    className={
                      "mr-10 flex items-center" +
                      (location.pathname === PATH_HOME ||
                      location.pathname.includes(PATH_NOTICE)
                        ? " text-red-500 font-bold"
                        : " text-black-500 opacity-50")
                    }
                  >
                    <CampaignIcon fontSize="medium" className="mr-2" />
                    <span className="text-lg">공지사항</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link to={PATH_B2B_INSPECTION} onClick={() => {}}>
                  <div
                    className={
                      "mr-10 flex items-center" +
                      (location.pathname === PATH_B2B_INSPECTION
                        ? "  text-red-500 font-bold"
                        : " text-black-500 opacity-50")
                    }
                  >
                    <DescriptionIcon fontSize="medium" className="mr-2" />
                    <span className="text-lg">렌터카 예약/검사현황</span>
                  </div>
                </Link>
              </li>
              <li className="cursor-pointer">
                {/* <Link to={PATH_B2C_INSPECTION}> */}
                <div
                  className={
                    "mr-10 flex items-center" +
                    (location.pathname === PATH_B2C_INSPECTION
                      ? "  text-red-500 font-bold"
                      : " text-black-500 opacity-50")
                  }
                  onClick={moveToB2C}
                >
                  <BookIcon fontSize="medium" className="mr-2" />
                  <div className="text-lg">자차 검사/예약 현황</div>
                </div>
                {/* </Link> */}
              </li>
              <li className="cursor-pointer">
                {/* <Link to={PATH_PAST_INSTPECTION}> */}
                <div
                  className={
                    "mr-10 flex items-center" +
                    (location.pathname === PATH_PAST_INSTPECTION
                      ? "  text-red-500 font-bold"
                      : " text-black-500 opacity-50")
                  }
                  onClick={moveToPastInspection}
                >
                  <AssignmentIcon fontSize="medium" className="mr-2" />
                  <div className="text-lg">지난 검사 관리</div>
                </div>
                {/* </Link> */}
              </li>
            </ul>
            <Link to={PATH_PAYMENT}>
              <p className="pr-5 font-bold text-center text-primary" href="#">
                {`잔여포인트 : ${userData.point ?? 0}포인트`}
              </p>
            </Link>
          </div>
        )}
        <AlertTab alertTab={alertTab} setAlertTab={setAlertTab} />
      </nav>
    </>
  );
};

export default Navbar;
