/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";

import {Stack} from "@mui/system";
import {
  Box,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Card,
  Button,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import RefreshIcon from "@mui/icons-material/Refresh";

//시간관련
import {
  getDateJacarFormat,
  getDateFromDaysAgo,
  setDateTo24,
  getDateJacarFormat2,
} from "../../utils/dateutil";
import dayjs from "dayjs";

import * as config from "../../assets/config";
import InspectionTable from "../../components/inspection/InspectionTable";
import InspectionDetail from "../../components/inspection/InspectionDetail";
import {b2cInspectionApi, inspectionApi} from "../../api";
import MainCard from "../../components/MainCard";

import * as InspectionConst from "../../constants/inspection";
import * as B2CInspectionConst from "../../constants/b2cInspection";
import YearMonthSelect from "../../components/inspection/YearMonthSelect";
import SummaryCard from "../../components/inspection/SummaryCard";
import SearchBarItem from "../../components/inspection/SearchBarItem";
import JacarDatePicker from "../../components/inspection/JacarDatePicker";
import {CAR_TYPE} from "../../components/table/costSettingTable";
import B2CInspectionDetail from "../../components/inspection/B2CInspectionDetail";
//시간관련
const LOCAL_INSPECTION_ASSIGNMENT_START_DATE = "assignmentStartDate";
const LOCAL_INSPECTION_ASSIGNMENT_END_DATE = "assignmentEndDate";
const LOCAL_INSPECTION_RESERVATION_START_DATE = "reservationStartDate";
const LOCAL_INSPECTION_RESERVATION_END_DATE = "reservationEndDate";
export const PAGE_TYPE_B2B = 0;
export const PAGE_TYPE_B2C = 1;

const Inspection = ({pageType}) => {
  const [authCookies] = useCookies();
  const [loading, setLoading] = useState(false);

  //Form data - 날짜

  const [inputStartDate, setInputStartDate] = useState(() => {
    let localStartDate = localStorage.getItem(
      pageType === PAGE_TYPE_B2B
        ? LOCAL_INSPECTION_ASSIGNMENT_START_DATE
        : LOCAL_INSPECTION_RESERVATION_START_DATE,
    );

    if (!localStartDate) {
      localStartDate = dayjs(getDateFromDaysAgo(60)).format("YYYY-MM-DD");
    }

    return localStartDate;
  });
  const [inputEndDate, setInputEndDate] = useState(() => {
    let localEndDate = localStorage.getItem(
      pageType === PAGE_TYPE_B2B
        ? LOCAL_INSPECTION_ASSIGNMENT_END_DATE
        : LOCAL_INSPECTION_RESERVATION_END_DATE,
    );

    if (!localEndDate) {
      localEndDate = dayjs().format("YYYY-MM-DD");
    }

    return localEndDate;
  });

  // Form data
  const [selectedStep, setSelectedStep] = useState(-1);

  // API data
  const [inspections, setInspections] = useState([]);
  const [selectedInspection, setSelectedInspection] = useState({});
  const [updatedInspection, setUpdatedInspection] = useState({});

  const [b2bCounts, setB2bCounts] = useState({
    reservation_uncomplete_count: 0,
    total: 0,
    reservation_complete_count: 0,
    inspection_complete_count: 0,
    missed_count: 0,
  });
  const [b2cCounts, setB2cCounts] = useState({
    reservation_complete_count: 0,
    inspection_complete_count: 0,
    noshow_count: 0,
    fail_count: 0,
    reservation_cancel_count: 0,
    total: 0,
  });

  // pagination & params
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [steps, setSteps] = useState("");
  const [word, setWord] = useState("");
  const [orderBy, setOrderBy] = useState("");

  //const [error, setError] = useState(false);

  const getProcessedInspection = inspection => {
    if (pageType === PAGE_TYPE_B2B) {
      inspection.mileage = inspection.mileage.toLocaleString();
      inspection.current_step_name =
        InspectionConst.STEP_NAME[inspection.current_step];
      inspection.customer_company_name = inspection.customer_company.name;
      inspection.center_name = inspection.center.name;

      inspection.inspection_complete_date = getDateJacarFormat(
        inspection.inspection_complete_date,
      );
      inspection.inspection_expired_date = getDateJacarFormat(
        inspection.inspection_expired_date,
      );
      inspection.contract_expired_date = getDateJacarFormat(
        inspection.contract_expired_date,
      );
      inspection.reservation_date = getDateJacarFormat(
        inspection.reservation_date,
      );
      // inspection.contract_address_asterisk = getAsteriskAddress(
      //   inspection.contract_address,
      // );
      inspection.pickup_address_asterisk = getAsteriskAddress(
        inspection.pickup_address,
      );
      inspection.driver_phone_number_asterisk = getAsteriskPhoneNumber(
        inspection.driver_phone_number,
      );
      inspection.user_name_asterisk = getAsteriskName(inspection.user_name);
      inspection.driver_name_asterisk = getAsteriskName(inspection.driver_name);
      inspection.counselor_asterisk = getAsteriskName(inspection.counselor);
      inspection.payment_person_name_asterisk = getAsteriskName(
        inspection.payment_person_name_asterisk,
      );
      inspection.payment_person_contact_asterisk = getAsteriskPhoneNumber(
        inspection.payment_person_contact,
      );
    } else {
      inspection.current_step_name =
        B2CInspectionConst.STEP_NAME[inspection.current_step];
      inspection.car_type = inspection.car
        ? CAR_TYPE[inspection.car.car_type]
        : "";
      inspection.car_registered_at = getDateJacarFormat(
        inspection.car_registered_at,
      );
      inspection.center_name = inspection.center
        ? inspection.center.name
        : null;
      inspection.car_number = inspection.car ? inspection.car.car_number : "";
      inspection.inspection_expired_date = getDateJacarFormat(
        inspection.inspection_expired_date,
      );
      inspection.inspection_complete_date = getDateJacarFormat(
        inspection.inspection_complete_date,
      );
      inspection.reservation_date = getDateJacarFormat(
        inspection.reservation_date,
      );
      inspection.reservation_point = getDateJacarFormat2(
        inspection.reservation_point,
      );
      inspection.updated_at = getDateJacarFormat2(inspection.updated_at);

      inspection.user_name_asterisk = inspection.user
        ? getAsteriskName(inspection.user.name)
        : "";
      inspection.user_phone_number_asterisk = inspection.user
        ? getAsteriskPhoneNumber(inspection.user.phone_number)
        : "";
    }
    return inspection;
  };

  useEffect(() => {
    if (selectedStep !== -1) {
      setSteps(selectedStep + "");
    } else {
      setSteps("");
    }
  }, [selectedStep]);

  useEffect(() => {
    loadData();
  }, [page, pageType]);

  useEffect(() => {
    if (selectedInspection && Object.keys(updatedInspection).length) {
      if (!updatedInspection.center) {
        setSelectedInspection({});
        loadData();
        return;
      }
      setSelectedInspection(getProcessedInspection(updatedInspection));

      setInspections(
        inspections.map(inspection =>
          inspection.id === updatedInspection.id
            ? getProcessedInspection(updatedInspection)
            : inspection,
        ),
      );
    } else if (
      selectedInspection &&
      Object.keys(updatedInspection).length === 0
    ) {
      loadData();
      setSelectedInspection({});
    }
  }, [updatedInspection]);
  useEffect(() => {
    setSelectedInspection({});

    if (page === 1) {
      loadData();
    } else {
      setPage(1);
    }
  }, [pageSize, inputStartDate, inputEndDate, steps, word, orderBy]);

  const loadData = async () => {
    try {
      setLoading(true);

      if (pageType === PAGE_TYPE_B2B) {
        const res = await inspectionApi.getInspectionList(
          authCookies.access_token,
          page,
          pageSize,
          inputStartDate,
          inputEndDate,
          word,
          steps,
          orderBy,
        );

        let {
          data: {
            results,
            count,
            reservation_uncomplete_count,
            reservation_complete_count,
            inspection_complete_count,
            missed_count,
          },
          status,
        } = res;

        if (status === 200) {
          setB2bCounts({
            total: count,
            reservation_uncomplete_count,
            reservation_complete_count,
            inspection_complete_count,
            missed_count,
          });

          // Data formatting
          results.map(inspection => getProcessedInspection(inspection));

          setInspections(results);
        } else {
          //error
        }
      } else {
        const res = await b2cInspectionApi.getInspectionList(
          authCookies.access_token,
          page,
          pageSize,
          inputStartDate,
          inputEndDate,
          word,
          steps,
          orderBy,
        );

        let {
          data: {
            results,
            count,
            fail_count,
            inspection_complete_count,
            reservation_cancel_count,
            reservation_complete_count,
            noshow_count,
          },
          status,
        } = res;

        if (status === 200) {
          setB2cCounts({
            total: count,
            fail_count,
            inspection_complete_count,
            reservation_cancel_count,
            reservation_complete_count,
            noshow_count,
          });

          // Data formatting
          results.map(inspection => getProcessedInspection(inspection));

          setInspections(results);
        } else {
          //error
        }
      }
    } catch {
      //error
    } finally {
      setLoading(false);
    }
  };

  const getAsteriskName = name => {
    if (name && name.length > 2) {
      name = name[0] + "*" + name.slice(2);
    }
    return name;
  };
  const getAsteriskPhoneNumber = phoneNumber => {
    if (phoneNumber && phoneNumber.length > 4) {
      phoneNumber = phoneNumber.slice(0, -4) + "*".repeat(4);
    }
    return phoneNumber;
  };

  const getAsteriskAddress = address => {
    if (address) {
      const addressSplited = address.split(" ");
      if (addressSplited.length > 2) {
        address = [addressSplited[0], addressSplited[1], "****"].join(" ");
      }
    }
    return address;
  };
  return (
    <MainCard sx={{">div": {paddingY: "0px"}}}>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Stack direction={"row"} spacing={1}>
          {pageType === PAGE_TYPE_B2B ? (
            <>
              <SummaryCard
                title={"미예약"}
                value={b2bCounts.reservation_uncomplete_count}
                color={config.stepzoneYellow}
              />

              <SummaryCard
                title={"예약완료"}
                value={b2bCounts.reservation_complete_count}
                color={config.stepzoneGreen}
              />

              <SummaryCard
                title={"검사완료"}
                value={b2bCounts.inspection_complete_count}
                color={config.stepzoneBlue}
              />
              <SummaryCard
                title={"부재"}
                value={b2bCounts.missed_count}
                color={config.stepzoneRed}
              />
            </>
          ) : (
            <>
              <SummaryCard
                title={"예약완료"}
                value={b2cCounts.reservation_complete_count}
                color={config.stepzoneGreen}
              />

              <SummaryCard
                title={"검사완료"}
                value={b2cCounts.inspection_complete_count}
                color={config.stepzoneBlue}
              />

              <SummaryCard
                title={"노쇼"}
                value={b2cCounts.noshow_count}
                color={config.stepzoneRed}
              />
              <SummaryCard
                title={"불합격"}
                value={b2cCounts.fail_count}
                color={config.stepzoneYellow}
              />
              <SummaryCard
                title={"예약취소"}
                value={b2cCounts.reservation_cancel_count}
                color={config.stepzoneGray}
              />
            </>
          )}
        </Stack>
        <Stack>
          <Typography>
            {pageType === PAGE_TYPE_B2B
              ? "기간 설정(선택된 기간 동안 생성된 건들만 표시)"
              : "기간 설정(선택된 기간 동안 생성된 건들만 표시)"}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={"2px"}>
            <JacarDatePicker
              date={inputStartDate}
              setDate={setInputStartDate}
              localStorageName={LOCAL_INSPECTION_ASSIGNMENT_START_DATE}
            />

            <Typography fontSize={"24px"} sx={{fontWeight: "700"}}>
              ~
            </Typography>
            <JacarDatePicker
              date={inputEndDate}
              setDate={setInputEndDate}
              localStorageName={LOCAL_INSPECTION_ASSIGNMENT_END_DATE}
            />
          </Stack>
        </Stack>
      </Stack>

      <Card
        sx={{marginY: "10px", backgroundColor: "#e4e4e4", maxHeight: "auto"}}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          paddingX={"10px"}
          paddingY={"10px"}
          borderRadius={"15px"}
        >
          <Stack flexDirection={"row"} gap={"20px"}>
            <SearchBarItem title="검색어">
              <Box>
                <FormControl sx={{minWidth: 180}} size="small">
                  <OutlinedInput
                    size="small"
                    placeholder="검색어 입력"
                    sx={{
                      height: "36px",
                    }}
                    value={word}
                    onChange={e => setWord(e.target.value)}
                  />
                </FormControl>
              </Box>
            </SearchBarItem>
            <SearchBarItem title="진행사항">
              <FormControl sx={{minWidth: 180}} size="small">
                <Select
                  unselectable="on"
                  onChange={e => {
                    setSelectedStep(e.target.value);
                  }}
                  value={selectedStep}
                  sx={{
                    height: "36px",
                  }}
                >
                  <MenuItem value={-1}>전체</MenuItem>

                  {pageType === PAGE_TYPE_B2B &&
                    Object.keys(InspectionConst.STEP_NAME_FOR_CENTER).map(
                      key => {
                        return (
                          <MenuItem key={key} value={key}>
                            {InspectionConst.STEP_NAME_FOR_CENTER[key]}
                          </MenuItem>
                        );
                      },
                    )}
                  {pageType === PAGE_TYPE_B2C &&
                    Object.keys(B2CInspectionConst.STEP_NAME).map(key => {
                      return (
                        <MenuItem key={key} value={key}>
                          {B2CInspectionConst.STEP_NAME[key]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </SearchBarItem>
          </Stack>

          <Stack
            direction="row"
            alignItems={"center"}
            marginLeft="20px"
            spacing={2}
          >
            <Button
              disabled={loading}
              variant="contained"
              size="small"
              startIcon={<LoopIcon />}
              onClick={() => {
                setSelectedStep(-1);
                setWord("");
                setSteps("");
              }}
            >
              검색조건 초기화
            </Button>

            <Button
              disabled={loading}
              variant="contained"
              size="small"
              startIcon={<RefreshIcon />}
              onClick={() => {
                setSelectedInspection({});
                loadData();
              }}
            >
              목록 새로고침
            </Button>
          </Stack>
        </Stack>
      </Card>

      <Box
        sx={{
          display: "flex",
          marginTop: "10px",
          height: "calc( 100vh - 292px )",
        }}
      >
        <Box sx={{width: "70%"}}>
          <InspectionTable
            setSelectedInspection={setSelectedInspection}
            data={inspections}
            rowCount={b2bCounts.total}
            loading={loading}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setOrderBy={setOrderBy}
            pageType={pageType}
          />
        </Box>
        <Box sx={{width: "30%", height: "100%", overflow: "auto"}}>
          {pageType === PAGE_TYPE_B2B && (
            <InspectionDetail
              selectedInspection={selectedInspection}
              setUpdatedData={setUpdatedInspection}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {pageType === PAGE_TYPE_B2C && (
            <B2CInspectionDetail
              selectedInspection={selectedInspection}
              setUpdatedData={setUpdatedInspection}
              loading={loading}
              setLoading={setLoading}
            />
          )}
        </Box>
      </Box>
    </MainCard>
  );
};

export default Inspection;
