import React, {useState, useEffect} from "react";
import {useCookies} from "react-cookie";
import {centerApi, pastInspectionApi} from "../../api";

// material-ui
import {
  Stack,
  Typography,
  Button,
  Checkbox,
  Box,
  ThemeProvider,
  createTheme,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
});

const PastInspectionSetting = () => {
  const [cookies] = useCookies();
  const [useAlimtok, setUseAlimtok] = useState(false);
  const [pointPrice, setPointPrice] = useState(0);

  const getUseAlimtok = async () => {
    try {
      const {data: alimtokData} = await centerApi.getCenterDetail(
        cookies.access_token,
      );
      const {data: pointData} = await pastInspectionApi.getInspectionPointPrice(
        cookies.access_token,
      );

      setPointPrice(pointData.alimtok_consumption_amount);
      setUseAlimtok(alimtokData.auto_alimtok_past);
    } catch (error) {
      alert("알림톡 설정을 불러오는데 실패했습니다.");
      throw error;
    }
  };

  const pustUseAlimtok = async () => {
    const payload = {
      auto_alimtok_past: useAlimtok,
    };

    try {
      const {status} = await centerApi.putCenterDetail(
        payload,
        cookies.access_token,
      );

      if (status === 200) {
        alert("알림톡 설정이 저장되었습니다.");
      }
    } catch (error) {
      alert("알림톡 설정을 저장하는데 실패했습니다.");
      throw error;
    }
  };

  useEffect(() => {
    getUseAlimtok();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Stack className="max-w-5xl mx-auto mt-[3rem]" spacing={3}>
        <Stack>
          <Box className="flex flex-row items-center justify-between py-8 border-b-2 border-b-black">
            <Typography variant="h5" fontWeight="bold">
              지난 검사 설정
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={2}>
          <Typography className="text-secondary">자동 알림톡 설정</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="large"
                  checked={useAlimtok}
                  onChange={() => setUseAlimtok(prev => !prev)}
                />
              }
              label={
                <Typography variant="h6" fontWeight={700}>
                  자동 알림톡 전송 여부
                </Typography>
              }
            />
          </FormGroup>

          <Typography variant="h6">
            {`자동 알림톡 전송 한 건당 ${pointPrice}포인트가 차감됩니다.`}
          </Typography>
          <Typography variant="h7">
            검사소에서 진행되었던 검사건들에 한해 2년 후 유효기간 만료일 -30일이
            되면 자동으로 고객에게 유효기간 만료일 안내 및 검사 예약 알림톡을
            전송합니다.
          </Typography>
        </Stack>
        <Stack sx={{alignItems: "center", pt: 3}}>
          <Button
            variant="contained"
            sx={{width: "185px"}}
            onClick={pustUseAlimtok}
          >
            저장
          </Button>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default PastInspectionSetting;
