import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  Checkbox,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          width: "150px", // or any other value you need
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        defaultProps: {
          size: "small",
        },
      },
    },
  },
});

function createData(
  divider,
  periodic_inspection,
  comprehensive_inspection_load,
  comprehensive_inspection_no_load,
  comprehensive_inspection_exhaust_exemption,
  business,
) {
  return {
    divider,
    periodic_inspection,
    comprehensive_inspection_load,
    comprehensive_inspection_no_load,
    comprehensive_inspection_exhaust_exemption,
    business,
  };
}

export const CAR_TYPE = {
  0: "경차(경형)",
  1: "소형차(10인승 이하)",
  2: "중형차(11인승 이상 2.5t이하)",
  3: "대형 승합(35인승 이상)",
  4: "대형 화물(10t 미만)",
  5: "대형 화물(10t 이상 15t미만)",
  6: "초대형 승합(대형 버스)",
  7: "초대형 화물(15t 이상)",
};
const TABLE_ROWS_NUM = 8;

function transformTableData(data) {
  delete data.id;
  const costTableData = new Array(TABLE_ROWS_NUM).fill("");
  const result = costTableData.map((_, idx) => {
    const rows = [CAR_TYPE[idx]];
    for (let i = 0; i <= 3; i++) rows.push(data[`car_type_${idx}_price_${i}`]);
    rows.push(data[`car_type_${idx}_enabled`]);
    return createData(...rows);
  });
  return result;
}

const INSPECTION_TITLE = {
  DIVIDER: "구분",
  PERIODIC_INSPECTION: "정기검사",
  COMPREHENSIVE_INSPECTION_LOAD: "종합검사 (부하)",
  COMPREHENSIVE_INSPECTION_NO_LOAD: "종합검사 (무부하)",
  COMPREHENSIVE_INSPECTION_EXHAUST_EXEMPTION: "종합검사 (배출면제)",
  BUSINESS: "영업여부",
};

function CostSettingTable({rows, changeRows}) {
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Box>
          <Table aria-label="simple table">
            <TableHead className="bg-[#FAFAFC]">
              <TableRow>
                <TableCell>{INSPECTION_TITLE.DIVIDER}</TableCell>
                <TableCell align="center">
                  {INSPECTION_TITLE.PERIODIC_INSPECTION}
                </TableCell>
                <TableCell align="center">
                  {INSPECTION_TITLE.COMPREHENSIVE_INSPECTION_LOAD}
                </TableCell>
                <TableCell align="center">
                  {INSPECTION_TITLE.COMPREHENSIVE_INSPECTION_NO_LOAD}
                </TableCell>
                <TableCell align="center">
                  {INSPECTION_TITLE.COMPREHENSIVE_INSPECTION_EXHAUST_EXEMPTION}
                </TableCell>
                <TableCell align="center" sx={{minWidth: 100}}>
                  {INSPECTION_TITLE.BUSINESS}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transformTableData(rows).map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.divider}
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={row.periodic_inspection}
                        name={`car_type_${idx}_price_0`}
                        onChange={changeRows}
                        disabled={!row.business}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        fullWidth
                        type="number"
                        value={row.comprehensive_inspection_load}
                        name={`car_type_${idx}_price_1`}
                        onChange={changeRows}
                        disabled={!row.business}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        fullWidth
                        type="number"
                        value={row.comprehensive_inspection_no_load}
                        name={`car_type_${idx}_price_2`}
                        onChange={changeRows}
                        disabled={!row.business}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        fullWidth
                        type="number"
                        value={row.comprehensive_inspection_exhaust_exemption}
                        name={`car_type_${idx}_price_3`}
                        onChange={changeRows}
                        disabled={!row.business}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={!!row.business}
                        name={`car_type_${idx}_enabled`}
                        onChange={changeRows}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </ThemeProvider>
  );
}

export default CostSettingTable;
