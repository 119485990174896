import {useNavigate} from "react-router-dom";
import {PATH_NOTICE} from "../common/Router";

const Notice = ({notice, currentPage}) => {
  const navigate = useNavigate();

  return (
    <ul className="notice_page">
      <li className="h-10 post_content w-[10%]">{notice.pk}</li>
      <li className="h-10 post_content w-[10%]">{notice.service}</li>
      <li
        className="cursor-pointer post_content hover:font-bold w-[50%]"
        onClick={() => {
          navigate(`${PATH_NOTICE}/${notice.pk}`, {
            state: currentPage,
          });
        }}
      >
        {notice.title}
      </li>
      <li className="post_content w-[15%]">{notice.created_at}</li>
      <li className="post_content w-[15%]">{notice.pk ? "관리자" : ""}</li>
    </ul>
  );
};

export default Notice;
