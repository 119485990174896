import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Button from "@mui/material/Button";

const MAX_IMAGE_SIZE = 1;

const CenterImageEdit = ({
  imageSrc,
  setImageSrc,
  imageFile,
  setImageFile,
  name,
  setModalContent,
  setModalOpen,
}) => {
  const checkFileSize = file => {
    if (!file) {
      console.log("!file");
      return false;
    } else if (file.size > MAX_IMAGE_SIZE * 1024 * 1024) {
      console.log("file size exceed");
      return false;
    } else {
      console.log("treu");
      return true;
    }
  };

  return (
    <div className="flex flex-col ">
      <IconButton
        color="inherit"
        aria-label="upload picture"
        component="label"
        sx={{padding: 0}}
      >
        <div className="flex items-center justify-center w-32 h-32 border-2 border-gray-300">
          {imageSrc ? (
            <img className="w-32 h-32 " alt="imgFile1" src={imageSrc} />
          ) : (
            <PhotoCamera />
          )}

          <input
            type="file"
            accept="image/*"
            name={name}
            value={imageFile ? imageFile : undefined}
            onChange={e => {
              const file = e.target.files[0];
              if (checkFileSize(file)) {
                setImageFile(e.target.value);

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                  setImageSrc(reader.result);
                };
              } else {
                setImageFile(null);

                setModalContent(
                  `사진 용량은 ${MAX_IMAGE_SIZE}MB 이하로 업로드 가능합니다.`,
                );
                setModalOpen(true);
              }
            }}
            hidden
          />
        </div>
      </IconButton>

      {imageSrc && (
        <Button
          color="error"
          onClick={() => {
            setImageSrc(null);
          }}
        >
          삭제
        </Button>
      )}
    </div>
  );
};

export default CenterImageEdit;
