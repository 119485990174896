import {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {Box} from "@mui/material";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {pastInspectionApi} from "../../api";
import {useCookies} from "react-cookie";
import {COOKIE_REGISTRATION_CENTER_ID} from "../contexts/LoginContext";
import JacarDatePicker from "../inspection/JacarDatePicker";
import dayjs from "dayjs";
const inputData = {
  car_name: "",
  car_number: "",
  user_name: "",
  phone_number: "",
  mileage: 0,
  user_address: "",
  user_address_detail: "",
  renewal_months: 0,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const PastInspectionModal = ({open, handleClose}) => {
  const [inputs, setInputs] = useState(inputData);
  const [nextExpiredAt, setNextExpiredAt] = useState(() => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 2);
    return dayjs(date).format("YYYY-MM-DD");
  });
  // eslint-disable-next-line no-unused-vars
  const [authCookies] = useCookies([COOKIE_REGISTRATION_CENTER_ID]);
  const daumOpen = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
  );

  const handleComplete = async data => {
    let fullAddress = data.address;
    setInputs({
      ...inputs,
      user_address: fullAddress,
    });
  };

  const handleClickAddress = e => {
    daumOpen({onComplete: handleComplete});
  };

  const onChange = event => {
    const {value, name} = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const updateData = async event => {
    event.preventDefault();

    inputs.center = Number(authCookies.center_id);
    inputs.next_expired_at = nextExpiredAt;
    const reqData = [inputs];
    try {
      const {status, data} = await pastInspectionApi.createPastInspectionList(
        authCookies.access_token,
        reqData,
      );

      if (status === 200) {
        alert(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    setInputs(inputData);
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{...style, width: 800}}>
        <p className="my-5 text-lg font-bold" id="parent-modal-title">
          신규고객 입력
        </p>
        <form onSubmit={updateData}>
          <ul>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">차량명</p>
              <TextField
                size="small"
                className="flex-1"
                placeholder="차량명(예:그랜저ig 하이브리드)"
                variant="outlined"
                name="car_name"
                value={inputs.car_name}
                onChange={onChange}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">
                차량번호
                <span className="pl-1 text-xs text-primary">(필수)</span>
              </p>
              <TextField
                required
                size="small"
                className="flex-1"
                placeholder="차량번호(예:111거1111)"
                variant="outlined"
                name="car_number"
                value={inputs.car_number}
                onChange={onChange}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">고객명</p>
              <TextField
                size="small"
                className="flex-1"
                placeholder="고객명"
                variant="outlined"
                name="user_name"
                value={inputs.user_name}
                onChange={onChange}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">
                고객 연락처
                <span className="pl-1 text-xs text-primary">(필수)</span>
              </p>
              <TextField
                required
                size="small"
                className="flex-1"
                placeholder="고객 연락처(예:01012345678)"
                variant="outlined"
                name="phone_number"
                value={inputs.phone_number}
                onChange={onChange}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px] min-w-[200px]">
                다음 유효기간 만료일
                <span className="pl-1 text-xs text-primary">(필수)</span>
              </p>
              <JacarDatePicker
                date={nextExpiredAt}
                setDate={setNextExpiredAt}
                localStorageName={null}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px] min-w-[200px]">주소</p>
              <TextField
                size="small"
                className="flex-1 mr-2"
                placeholder="주소"
                variant="outlined"
                value={inputs.user_address}
                name="user_address"
                onChange={onChange}
                onClick={handleClickAddress}
              />
              <Button
                sx={{marginLeft: "10px"}}
                onClick={handleClickAddress}
                variant="contained"
              >
                주소검색
              </Button>
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]"></p>
              <TextField
                size="small"
                className="flex-auto "
                placeholder="상세주소"
                value={inputs.user_address_detail}
                variant="outlined"
                name="user_address_detail"
                onChange={onChange}
              />
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">주행거리</p>
              <TextField
                size="small"
                className="flex-1"
                placeholder="주행거리(숫자만 입력)"
                variant="outlined"
                name="mileage"
                type="number"
                value={inputs.mileage}
                onChange={onChange}
              />
              <span className="ml-3">km</span>
            </li>
            <li className="flex items-center mb-3">
              <p className="w-[200px]">갱신 개월 수</p>
              <TextField
                size="small"
                className="flex-1"
                placeholder="갱신 개월 수(숫자만 입력)"
                variant="outlined"
                name="renewal_months"
                type="number"
                value={inputs.renewal_months}
                onChange={onChange}
              />
              <span className="ml-3">개월</span>
            </li>
          </ul>
          <div className="flex flex-row justify-end pt-3">
            <div className="space-x-2">
              <Button variant="outlined" onClick={handleClose}>
                취소
              </Button>
              <Button type="submit" variant="contained">
                저장
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default PastInspectionModal;
