import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import {TailSpin} from "react-loading-icons";
import NestedModal from "../../components/common/Modal";
import {useCookies} from "react-cookie";
import {centerApi} from "../../../src/api";

const inputData = {
  name: "",
  ceo_name: "",
  ceo_phone_number: "",
  address: "",
  address_detail: "",
  center_phone_number: "",
  username: "",
  license_number: "",
  zip_code: "",
};
const Mypage = () => {
  const [user, setUser] = useState(inputData);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [cookies] = useCookies();
  // const {loginInfo} = useContext(LoginContext);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const loadData = async () => {
    try {
      const res = await centerApi.myPage(cookies.access_token);

      const {
        name,
        ceo_name,
        ceo_phone_number,
        address,
        address_detail,
        center_phone_number,
        username,
        license_number,
        zip_code,
      } = res.data;
      if (res.status === 200) {
        setUser({
          ...user,
          name,
          ceo_name,
          ceo_phone_number,
          address,
          address_detail,
          center_phone_number,
          username,
          license_number,
          zip_code,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changeTitle = e => {
    setModalTitle(e.target.name);
    handleOpen();
  };

  return (
    <>
      {loading && <TailSpin className="mx-auto" stroke="#999" />}

      <main className="max-w-5xl mx-auto mt-[3rem]">
        <NestedModal
          title={modalTitle}
          open={open}
          handleClose={handleClose}
          user={user}
          setUser={setUser}
        />
        <div>
          <div className="flex flex-row items-center justify-between py-8 border-b-2 border-b-black">
            <p className="text-xl font-bold">계정관리</p>
            <Button color="secondary" name="회원 탈퇴" onClick={changeTitle}>
              회원 탈퇴
            </Button>
          </div>
          <div>
            <div className="max-w-4xl mx-auto">
              <div className="border-b-2 border-b-gray">
                <section className="flex flex-row items-center justify-between py-8 mt-10 border-b-2 border-b-gray">
                  <p className="text-xl font-bold">{user.name}</p>
                  <div className="space-x-2">
                    <Button
                      onClick={changeTitle}
                      name="업체정보 수정"
                      variant="outlined"
                      color="secondary"
                    >
                      수정
                    </Button>
                    <Button
                      onClick={changeTitle}
                      name="비밀번호 변경"
                      variant="contained"
                      color="grayBackground"
                      disableElevation={true}
                    >
                      비밀번호 변경
                    </Button>
                  </div>
                </section>
                <section className="py-5 font-bold border-b-2 border-b-gray">
                  <div className="py-2 text-sm opacity-50 text-secondary-500">
                    대표자정보
                  </div>
                  <div className="flex flex-row">
                    <div className="flex-initial w-40">
                      <ul>
                        <li className="pb-1">대표자</li>
                        <li className="pb-1">대표 연락처</li>
                      </ul>
                    </div>
                    <div className="flex-auto">
                      <ul>
                        <li className="pb-1">{user.ceo_name}</li>
                        <li className="pb-1">{user.ceo_phone_number}</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section className="my-3 font-bold">
                  <div className="py-2 text-sm opacity-50 text-black-500">
                    업체정보
                  </div>
                  <div className="flex">
                    <div className="w-40">
                      <ul>
                        <li className="pb-1">주소</li>
                        <li className="pb-1">우편번호</li>
                        <li className="pb-1">검사소 연락처</li>
                        <li className="pb-1">사업자등록번호</li>
                        <li className="pb-1">정비업등록번호</li>
                      </ul>
                    </div>
                    <div className="min-w-fit">
                      <ul>
                        <li className="pb-1">
                          {user.address_detail
                            ? `${user.address}, ${user.address_detail}`
                            : user.address}
                        </li>
                        <li className="pb-1">
                          <span className="inline-block">{user.zip_code}</span>
                        </li>
                        <li className="pb-1">
                          <span className="inline-block">
                            {user.center_phone_number}
                          </span>
                        </li>
                        <li className="pb-1">
                          <span className="inline-block">{user.username}</span>
                        </li>
                        <li className="pb-1">
                          <span className="inline-block">
                            {user.license_number}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
              <div className="flex flex-row justify-end my-4">
                <div className="space-x-2">
                  {/* <Link to="/">
                    <Button variant="outlined">취소</Button>
                  </Link>
                  <Link to="/">
                    <Button variant="contained">저장</Button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Mypage;
