import React, {useState, useEffect, useCallback} from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {noticeApi} from "../../api";
import {useCookies} from "react-cookie";
import dayjs from "dayjs";
import {createBrowserHistory} from "history";

const Post = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const [cookies] = useCookies();
  const params = useParams();
  const pageNum = params.id;
  const history = createBrowserHistory();

  const loadData = useCallback(async () => {
    try {
      const res = await noticeApi.getNoticeDetail(
        pageNum,
        cookies.access_token,
      );
      if (res.status === 200) {
        res.data.created_at = dayjs(res.data.created_at).format("YYYY.MM.DD");
        res.data.service =
          res.data.service === "B2B"
            ? "렌터카검사"
            : res.data.service === "B2C"
            ? "자차검사"
            : "공통";
        setPost(res.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [pageNum, cookies.access_token]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const navigateBack = useCallback(() => {
    navigate("/", {
      state: location.state,
    });
  }, [navigate, location.state]);

  useEffect(() => {
    const unlisten = history.listen(({action}) => {
      if (action === "POP") {
        navigateBack();
      }
    });

    return () => {
      unlisten();
    };
  }, [navigateBack, history]);

  return (
    <>
      <main className="max-w-5xl mx-auto mt-10">
        <div className="notice_list_wrap">
          <div className="notice_list">
            <div className="flex justify-between">
              <p className="flex items-center mb-2">
                <CampaignIcon className="mr-2" />
                <span>공지사항</span>
              </p>
              <div
                className="flex items-center pr-5 mb-2 cursor-pointer"
                onClick={() => {
                  navigate("/", {
                    state: location.state,
                  });
                }}
              >
                <ArrowBackIcon />
                <span className="ml-2 ">뒤로</span>
              </div>
            </div>

            <div className="top	 border-collapse bg-[#FAFAFC] border-t-[0.1rem] border-t-[#000] border-[0.1rem] border-[#E9E8E9]">
              <div className="post_left_header w-[10%]">번호</div>
              <div className="post_header w-[10%]">구분</div>
              <div className="post_header w-[50%]">제목</div>
              <div className="post_header w-[15%]">등록일</div>
              <div className="post_header w-[15%]">등록자</div>
              <div className="post_left_header w-[10%]">{post.pk}</div>
              <div className="post_header w-[10%]">{post.service}</div>
              <div className="post_header w-[50%]">{post.title}</div>
              <div className="post_header w-[15%]">{post.created_at}</div>
              <div className="post_header w-[15%]">관리자</div>
            </div>

            <div className="p-5 border-b-[#E9E8E9] border-l-[#E9E8E9] border-r-[#E9E8E9] border min-h-[500px] whitespace-pre">
              {post.content}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Post;
