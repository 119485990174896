import {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDaumPostcodePopup} from "react-daum-postcode";
import {centerApi} from "../../../src/api";
import {useCookies} from "react-cookie";
import {COOKIE_COMPANY_NAME, COOKIE_CEO_NAME} from "../contexts/LoginContext";

const AccountModal = ({user, handleClose}) => {
  const [inputs, setInputs] = useState(user);
  // eslint-disable-next-line no-unused-vars
  const [authCookies, setAuthCookie] = useCookies([
    COOKIE_COMPANY_NAME,
    COOKIE_CEO_NAME,
  ]);
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
  );

  const handleComplete = async data => {
    let fullAddress = data.address;
    setInputs({
      ...inputs,
      address: fullAddress,
      zip_code: data.zonecode,
    });
  };

  const handleClickAddress = e => {
    open({onComplete: handleComplete});
  };

  const onChange = event => {
    const {value, name} = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const updateData = async event => {
    event.preventDefault();

    try {
      delete inputs.username;
      const {status} = await centerApi.modifyMypage(
        inputs,
        authCookies.access_token,
      );

      if (status === 200) {
        // const date = new Date();
        // const oneWeekFromNow = new Date(date);
        // oneWeekFromNow.setDate(date.getDate() + 7);
        // setAuthCookie(COOKIE_CEO_NAME, inputs.com_ceo_name, {
        //   expires: oneWeekFromNow,
        // });
        // setAuthCookie(COOKIE_COMPANY_NAME, inputs.com_name, {
        //   expires: oneWeekFromNow,
        // });
      }
    } catch (err) {
      console.error(err);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <form onSubmit={updateData}>
        <ul>
          <li className="flex items-center mb-3">
            <p className="w-40">
              상호명
              <span className="pl-1 text-xs text-primary">(필수)</span>
            </p>
            <TextField
              required
              size="small"
              className="flex-1"
              placeholder="상호명"
              variant="outlined"
              defaultValue={inputs.name}
              name="name"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">
              대표자명
              <span className="pl-1 text-xs text-primary">(필수)</span>
            </p>
            <TextField
              required
              size="small"
              className="flex-1"
              placeholder="대표자명"
              variant="outlined"
              defaultValue={inputs.ceo_name}
              name="ceo_name"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">
              대표자 전화번호
              <span className="pl-1 text-xs text-primary">(필수)</span>
            </p>
            <TextField
              required
              size="small"
              className="flex-1"
              placeholder="대표자 전화번호"
              variant="outlined"
              defaultValue={inputs.ceo_phone_number}
              name="ceo_phone_number"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">
              검사소 전화번호
              <span className="pl-1 text-xs text-primary">(필수)</span>
            </p>
            <TextField
              required
              size="small"
              className="flex-1"
              placeholder="검사소 전화번호"
              variant="outlined"
              defaultValue={inputs.center_phone_number}
              name="center_phone_number"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40 min-w-40">
              주소
              <span className="pl-1 text-xs text-primary">(필수)</span>
            </p>
            <TextField
              required
              size="small"
              className="flex-1 mr-2"
              placeholder="주소"
              variant="outlined"
              value={inputs.address}
              name="address"
              onChange={onChange}
              onClick={handleClickAddress}
            />
            <Button
              sx={{marginLeft: "10px"}}
              onClick={handleClickAddress}
              variant="contained"
            >
              주소검색
            </Button>
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40"></p>
            <TextField
              size="small"
              className="flex-auto "
              placeholder="상세주소"
              value={inputs.address_detail}
              variant="outlined"
              name="address_detail"
              onChange={onChange}
            />
          </li>
          <li className="flex items-center mb-3">
            <p className="w-40">우편번호</p>
            <TextField
              size="small"
              className="flex-auto"
              placeholder="우편번호"
              value={inputs.zip_code}
              variant="outlined"
              name="zip_code"
              onChange={onChange}
              disabled
            />
          </li>
        </ul>
        <div className="absolute flex flex-row justify-end bottom-8 right-8">
          <div className="space-x-2">
            <Button variant="outlined" onClick={handleClose}>
              취소
            </Button>
            <Button type="submit" variant="contained">
              저장
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountModal;
