import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

const Item = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("xl")]: {
    flexDirection: "column",
  },
}));
const SearchBarTitleTypo = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "600",
}));
const SearchBarItem = ({title, children}) => {
  return (
    <Item>
      <SearchBarTitleTypo>{title}</SearchBarTitleTypo>
      {children}
    </Item>
  );
};

export default SearchBarItem;
