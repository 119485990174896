export const STEP_RESERVATION_COMPLETE = 0;
export const STEP_NO_SHOW = 1;
export const STEP_FAIL = 2;
export const STEP_INSPECTION_COMPLETE = 10;
export const STEP_RESERVATION_CANCEL = 11;

export const STEP_NAME = {
  [STEP_RESERVATION_COMPLETE]: "예약완료",
  [STEP_NO_SHOW]: "노쇼",
  [STEP_FAIL]: "불합격",
  [STEP_INSPECTION_COMPLETE]: "검사완료",
  [STEP_RESERVATION_CANCEL]: "예약취소",
};

Object.freeze(STEP_NAME);
