import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#036EB8",
    },
    secondary: {
      main: "#a0a0a0",
    },
    grayBackground: {
      main: "#d3d3d3",
    },
    white: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["NanumSquare"].join(","),
  },
});

export default theme;
