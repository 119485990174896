import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import {TailSpin} from "react-loading-icons";
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";
import {centerApi} from "../../../src/api";

import comingSoon from "../../assets/images/coming_soon.jpg";

const Company = () => {
  const [user, setUser] = useState({
    username: "7656765",
    name: "홍길동정비",
    ceo_name: "홍길동",
    ceo_phone_number: "010-1234-1234",
    address: "주소",
    address_detail: "상세주소",
    center_phone_number: "",
    manager_phone_number: "010-1234-1234",
    license_number: "",
    can_inspection_type: [],
    can_inspection_car_number_hour: 0,
    can_inspection_car_number_day: 0,
    pickup_employee_name: "",
    pickup_employee_phone_number: "",
    pickup_employee_image: null,

    center_image1: null,
    center_image2: null,
    center_image3: null,
    status: "대기",
  });

  const [loading, setLoading] = useState(true);
  const [modify, setModify] = useState(false);
  const [cookies] = useCookies();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await centerApi.getCenterDetail(cookies.access_token);

      setUser(res.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const modifyMode = () => {
    setModify(!modify);
  };

  return (
    <>
      {loading && <TailSpin className="mx-auto" stroke="#999" />}

      <main className="max-w-5xl mx-auto mt-[3rem] pb-10">
        <div>
          <div className="flex flex-row items-center justify-between py-8 border-b-2 border-b-black">
            <p className="text-xl font-bold ">업체정보 설정</p>
          </div>
          <div>
            <div className="max-w-4xl mx-auto">
              <div className="border-b-2 border-b-gray">
                <section className="flex flex-row items-center justify-between py-8 mt-10 border-b-2 border-b-gray">
                  <p className="text-xl font-bold">{user.name}</p>
                  <div className="space-x-2">
                    {!modify ? (
                      <Link to="/company_modify">
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={modifyMode}
                        >
                          수정
                        </Button>
                      </Link>
                    ) : (
                      <Button variant="outlined" disabled>
                        수정
                      </Button>
                    )}
                  </div>
                </section>
                <section className="py-5 font-bold border-b-2 border-b-gray">
                  <div className="py-2 text-sm opacity-50 text-secondary-500">
                    기본정보
                  </div>
                  <div className="flex flex-row ">
                    <ul className="flex flex-col gap-2">
                      <li className="flex">
                        <div className="w-40">담당자 전화번호</div>
                        <div>{user.manager_phone_number}</div>
                      </li>
                      <li className="flex ">
                        <div className="w-40">픽업직원 소개</div>
                        <div className="flex flex-col mr-4 w-80">
                          <span>{user.pickup_employee_name}</span>
                          <span>{user.pickup_employee_phone_number}</span>
                        </div>
                        <div className="justify-self-end">
                          <img
                            className="w-40 h-40"
                            src={
                              user.pickup_employee_image
                                ? user.pickup_employee_image
                                : comingSoon
                            }
                            alt="Employee"
                          />
                        </div>
                      </li>
                      <li className="flex">
                        <div className="w-40">업체 소개사진</div>
                        <ul className="flex gap-2">
                          <img
                            className="w-40"
                            src={
                              user.center_image1 === null
                                ? comingSoon
                                : user.center_image1
                            }
                            alt="Company Image1"
                          />
                          <img
                            className="w-40"
                            src={
                              user.center_image2 === null
                                ? comingSoon
                                : user.center_image2
                            }
                            alt="Company Image2"
                          />
                          <img
                            className="w-40"
                            src={
                              user.center_image3 === null
                                ? comingSoon
                                : user.center_image3
                            }
                            alt="Company Image3"
                          />
                        </ul>
                      </li>
                    </ul>
                    <div className="flex-auto">
                      <ul>
                        <li className="pb-1"></li>
                        <li className="flex flex-row w-32 pb-1"></li>
                      </ul>
                    </div>
                  </div>
                </section>
                {/* <section className="pb-5 my-3 border-b-2 border-b-gray">
                  <div className="mt-7">
                    <p className="pb-1 font-bold">
                      검사 종류 및 시간 당 검사기능 차량 수
                    </p>
                    <ul>
                      <li className="pb-1 text-sm">- 자동차 정기 / 종합검사</li>
                      <li className="pb-1 text-sm">
                        {`- 시간 당 검사기능 차량 수 : ${user.can_inspection_car_number_hour}대 / 시간 (해당 검사소에서
                        1시간에 검사완료 할 수 있는 차량 수)`}
                      </li>
                    </ul>
                  </div>
                </section>
                <section className="pb-3 my-3">
                  <div className="mt-7">
                    <p className="pb-1 font-bold">정산 정보</p>
                    <ul>
                      <li className="pb-1 text-sm">
                        종합검사 비용 : {user.total_inspection_price_b2b}원
                      </li>
                      <li className="pb-1 text-sm">
                        정기검사 비용 : {user.regular_inspection_price_b2b}원
                      </li>
                    </ul>
                  </div>
                </section> */}
              </div>
              {modify ? (
                <div className="flex flex-row justify-end my-4">
                  <div className="space-x-2">
                    <Button variant="outlined" onClick={modifyMode}>
                      취소
                    </Button>
                    <Button variant="contained">저장</Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Company;
