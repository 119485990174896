import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {LoginContext} from "../../components/contexts/LoginContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {authApi} from "../../../src/api";
import DialogAlert from "../../components/modal/DialogAlert";

import mainLogoWhite from "../../assets/logo/logo_nav_white.png";

const Login = () => {
  const inputData = {
    username: "",
    password: "",
  };

  const [inputs, setInputs] = useState(inputData);
  const [checkInput, setCheckInput] = useState([true, true]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const {login: contextLogin} = useContext(LoginContext);

  const handleClose = () => {
    if (modalContent === "회원가입이 완료되었습니다.") {
      setModalOpen(false);
    }
    setModalOpen(false);
  };

  const login = async () => {
    if (inputs.username === "") {
      setCheckInput([false, true]);
      return;
    } else if (inputs.password === "") setCheckInput([true, false]);
    else if (inputs.username !== "" && inputs.password !== "") {
      setCheckInput([true, true]);
      try {
        const res = await authApi.login(inputs);
        if (res.status === 200) {
          contextLogin(res);
        }
      } catch (err) {
        setModalContent("사업자번호 또는 비밀번호가 틀립니다.");
        setModalOpen(true);
      }
    }
  };

  // Handle Input
  const onChange = evnet => {
    const {value, name} = evnet.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  return (
    <>
      <main
        className="flex items-center justify-center"
        style={{height: "calc( 100vh - 185px )"}}
      >
        <DialogAlert
          open={modalOpen}
          handleClose={handleClose}
          content={modalContent}
        />
        <Box
          sx={{
            border: 2,
            width: 350,
            mx: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: 4,
            borderColor: "grayBackground.main",
          }}
          variant="outlined"
        >
          <div className="flex flex-col">
            <section className="flex items-center justify-center w-full h-24 pt-3 rounded-t-xl bg-primary">
              <img className="w-24" src={mainLogoWhite} alt="White Logo" />
            </section>

            <section className="flex flex-col mt-5 mb-5 gap-y-3">
              <Typography className="self-center" level="h4" component="h1">
                <span className="text-lg text-primary">
                  자카 오토케어 시스템
                </span>
              </Typography>
              <Typography className="self-center" level="body2">
                고객만족, 안전검사는 자카가 유일합니다.
              </Typography>
            </section>

            <section className="flex flex-col items-center">
              <TextField
                className="w-3/4"
                name="username"
                type="text"
                label="사업자등록번호"
                size="small"
                sx={{mb: 1}}
                onChange={onChange}
              />
              <TextField
                className="w-3/4"
                name="password"
                type="password"
                label="비밀번호"
                size="small"
                onChange={onChange}
              />
              <div className="h-4 pt-2 text-xs">
                <span
                  className={
                    (checkInput[0] ? "hidden " : null) +
                    " text-xs text-point-red  "
                  }
                >
                  사업자번호를 입력하세요.
                </span>
                <span
                  className={
                    (checkInput[1] ? "hidden " : null) +
                    " text-xs text-point-red  "
                  }
                >
                  비밀번호를 입력하세요.
                </span>
              </div>

              <Button
                className="w-3/4"
                variant="contained"
                sx={{mt: 4, mb: 1}}
                onClick={login}
              >
                로그인
              </Button>

              <div className="flex justify-between w-3/4 mb-5">
                <Typography sx={{alignSelf: "center"}}>
                  <Link to="/password">
                    <span className="text-xs font-bold text-primary">
                      비밀번호
                    </span>
                  </Link>
                  <span className="text-xs text-secondary">를 잊으셨나요?</span>
                </Typography>

                <Link to="/signup">
                  <Typography fontSize="sm" sx={{alignSelf: "center"}}>
                    <span className="text-xs font-bold text-primary">
                      회원가입
                    </span>
                  </Typography>
                </Link>
              </div>
            </section>
          </div>
        </Box>
      </main>
    </>
  );
};

export default Login;
