import React, {useContext} from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import InfoIcon from "@mui/icons-material/Info";
import Logout from "@mui/icons-material/Logout";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import {grey} from "@mui/material/colors";
import {Link} from "react-router-dom";
import {
  COOKIE_CEO_NAME,
  COOKIE_COMPANY_NAME,
  COOKIE_REGISTRATION_NUM,
  LoginContext,
} from "../contexts/LoginContext";
import {useCookies} from "react-cookie";
import {HOST_CENTER_ID} from "../../constants";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const {logout} = useContext(LoginContext);
  const [authCookies] = useCookies([
    COOKIE_COMPANY_NAME,
    COOKIE_CEO_NAME,
    COOKIE_REGISTRATION_NUM,
  ]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
  };
  return (
    <React.Fragment>
      <Box sx={{display: "flex", alignItems: "center", textAlign: "center"}}>
        <Tooltip title="계정 관리">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <div className="flex">
              <NoCrashOutlinedIcon sx={{color: grey[300]}} />
              <p className="ml-2 mr-5 text-white">{authCookies.company_name}</p>
            </div>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        <MenuItem>
          <NoCrashIcon sx={{color: grey[600]}} />
          <div className="ml-3">
            <p className="font-bold">{authCookies.ceo_name}</p>
            <p className="mt-0 text-sm text-gray-400">
              {authCookies.company_name}
            </p>
          </div>
        </MenuItem>

        <Divider />
        <Link to="/mypage">
          <MenuItem>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            <p>계정관리</p>
          </MenuItem>
        </Link>
        <Link to="/company">
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <p>업체정보관리</p>
          </MenuItem>
        </Link>
        <Link to="/payment">
          <MenuItem>
            <ListItemIcon>
              <CreditCardIcon fontSize="small" />
            </ListItemIcon>
            <p>결제관리</p>
          </MenuItem>
        </Link>
        <Link to="/inspection_info">
          <MenuItem>
            <ListItemIcon>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
            <p>검사정보관리</p>
          </MenuItem>
        </Link>

        {/* Todo: 상용서버 적용할 때 조건문 제거 필요 */}
        {authCookies.com_registration_number === HOST_CENTER_ID && (
          <>
            <Link to="/past_inspection_setting">
              <MenuItem>
                <ListItemIcon>
                  <AssignmentIcon fontSize="small" />
                </ListItemIcon>
                <p>지난검사설정</p>
              </MenuItem>
            </Link>
            <Link to="/past_inspection_create">
              <MenuItem>
                <ListItemIcon>
                  <BackupTableIcon fontSize="small" />
                </ListItemIcon>
                <p>지난검사등록</p>
              </MenuItem>
            </Link>
          </>
        )}

        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Logout className="text-red-500" fontSize="small" />
          </ListItemIcon>
          <p onClick={handleLogout} className="text-red-500">
            로그아웃
          </p>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
