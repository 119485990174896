export const STEP_WAITING = 0;
export const STEP_ASSIGNMENT_COMPLETE = 19;
export const STEP_RESERVATION_COMPLETE = 20;
export const STEP_INSPECTION_COMPELTE = 21;
export const STEP_NO_SHOW = 22;
export const STEP_RESERVATION_CANCEL = 23;
export const STEP_INSPECTION_STEP_NON_PARTNER_RESERVATION = 24;
export const STEP_ASSIGNMENT_CANCEL = 25;
export const STEP_MISSED = 30;

export const STEP_NAME = {
  [STEP_WAITING]: "대기",
  [STEP_ASSIGNMENT_COMPLETE]: "배정완료",
  [STEP_RESERVATION_COMPLETE]: "예약완료",
  [STEP_INSPECTION_STEP_NON_PARTNER_RESERVATION]: "비지점예약완료",
  [STEP_INSPECTION_COMPELTE]: "검사완료",
  [STEP_ASSIGNMENT_CANCEL]: "배정취소",
  [STEP_RESERVATION_CANCEL]: "예약취소",
  [STEP_MISSED]: "부재",
  [STEP_NO_SHOW]: "노쇼",
};

export const STEP_NAME_FOR_CENTER = {
  [STEP_ASSIGNMENT_COMPLETE]: "배정완료",
  [STEP_RESERVATION_COMPLETE]: "예약완료",
  [STEP_INSPECTION_COMPELTE]: "검사완료",
  [STEP_ASSIGNMENT_CANCEL]: "배정취소",
  [STEP_RESERVATION_CANCEL]: "예약취소",
  [STEP_MISSED]: "부재",
  [STEP_NO_SHOW]: "노쇼",
};

Object.freeze(STEP_NAME);
Object.freeze(STEP_NAME_FOR_CENTER);

export const CONTACT_PERIOD = 2;
export const EXPIRED_YELLOW = 40;
export const EXPIRED_RED = 30;

export const MISSED_REASON1 = "정보 불분명";
export const MISSED_REASON2 = "미귀/도난";
export const MISSED_REASON3 = "고객 비협조";
export const MISSED_REASON4 = "수리/폐차";
export const MISSED_REASON5 = "반납 예정";
export const MISSED_REASON6 = "진행 예정";

export const MISSED_REASON_LIST = [
  MISSED_REASON1,
  MISSED_REASON2,
  MISSED_REASON3,
  MISSED_REASON4,
  MISSED_REASON5,
  MISSED_REASON6,
];

export const RESERVATION_TIME_1 = "09:00~10:00";
export const RESERVATION_TIME_2 = "10:00~11:00";
export const RESERVATION_TIME_3 = "11:00~12:00";
export const RESERVATION_TIME_4 = "12:00~13:00";
export const RESERVATION_TIME_5 = "13:00~14:00";
export const RESERVATION_TIME_6 = "14:00~15:00";
export const RESERVATION_TIME_7 = "15:00~16:00";
export const RESERVATION_TIME_8 = "16:00~17:00";

export const RESERVATION_TIME_LIST = [
  RESERVATION_TIME_1,
  RESERVATION_TIME_2,
  RESERVATION_TIME_3,
  RESERVATION_TIME_4,
  RESERVATION_TIME_5,
  RESERVATION_TIME_6,
  RESERVATION_TIME_7,
  RESERVATION_TIME_8,
];

export const RESERVATION_SATURDAY_TIME_LIST = [
  RESERVATION_TIME_1,
  RESERVATION_TIME_2,
  RESERVATION_TIME_3,
];

export const RESERVATION_TYPE1 = "픽업";
export const RESERVATION_TYPE2 = "방문";

export const ASSIGNMENT_CANCEL_REASON1 = "주소지 상이";
export const ASSIGNMENT_CANCEL_REASON2 = "연락처 변경";
export const ASSIGNMENT_CANCEL_REASON3 = "반납 예정";
export const ASSIGNMENT_CANCEL_REASON4 = "기타";

export const ASSIGNMENT_CANCEL_REASONS = [
  ASSIGNMENT_CANCEL_REASON1,
  ASSIGNMENT_CANCEL_REASON2,
  ASSIGNMENT_CANCEL_REASON3,
  ASSIGNMENT_CANCEL_REASON4,
];
Object.freeze(ASSIGNMENT_CANCEL_REASONS);
