import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Stack,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";

import {useEffect, useState} from "react";
import JacarDatePicker from "../inspection/JacarDatePicker";

const B2CInspectionCompleteModal = ({
  open,
  handleClose,
  title,
  content,
  buttonText1,
  buttonText2,
  handleButton1Click,
  handleButton2Click,
  loading,
  selectedMileage,
}) => {
  const [mileage, setMileage] = useState(0);
  const [nextExpiredAt, setNextExpiredAt] = useState("");
  useEffect(() => {
    setMileage(selectedMileage);
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{p: 1, py: 1.5, width: "600px"}}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} marginBottom={3} gap={1}>
            <Typography minWidth={"100px"}>다음 유효기간 만료일</Typography>
            <FormControl sx={{width: "100%"}} size="small">
              <JacarDatePicker
                date={nextExpiredAt}
                setDate={setNextExpiredAt}
                localStorageName={null}
              />
            </FormControl>
          </Stack>
          <Stack direction={"column"} marginBottom={3} gap={1}>
            <Typography minWidth={"100px"}>주행거리</Typography>
            <FormControl sx={{width: "100%"}} size="small">
              <TextField
                fullWidth
                name={"mileage"}
                id="mileage"
                type="number"
                value={mileage}
                size="small"
                onChange={e => setMileage(e.target.value)}
              />
            </FormControl>
          </Stack>
          <DialogContentText
            whiteSpace={"pre-wrap"}
            id="alert-dialog-description"
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="error" onClick={handleButton1Click}>
            {buttonText1}
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => {
              handleButton2Click(mileage, nextExpiredAt);
            }}
            autoFocus
          >
            {buttonText2}
          </Button>
        </DialogActions>
      </Box>
      {loading && (
        <Box
          position={"absolute"}
          sx={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default B2CInspectionCompleteModal;
