import {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import Pagination from "@mui/material/Pagination";
import {TailSpin} from "react-loading-icons";
import {noticeApi} from "../../api/index";
import {useCookies} from "react-cookie";
import Notice from "./Notice";
import dayjs from "dayjs";
import shortid from "shortid";

const NoticeList = () => {
  const PAGE_SIZE = 6;
  const [notices, setNotices] = useState([]);

  const [shownList, setShownList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [noticePageCount, setNoticePageCount] = useState(1);
  const [cookies] = useCookies();
  const location = useLocation();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (notices.length > 0) {
      const newNotice = notices.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE,
      );

      setShownList(
        newNotice.concat(new Array(PAGE_SIZE - newNotice.length).fill("")),
      );
    }
  }, [notices, currentPage]);

  useEffect(() => {
    if (location.state) {
      setCurrentPage(location.state);
    }
  }, [location]);

  const loadData = async () => {
    try {
      const res = await noticeApi.getNoticeList(cookies.access_token);

      if (res.status === 200) {
        res.data.forEach(notice => {
          notice.created_at = dayjs(notice.created_at).format("YYYY.MM.DD");
          notice.service =
            notice.service === "B2B"
              ? "렌터카검사"
              : notice.service === "B2C"
              ? "자차검사"
              : "공통";
        });
      }

      setNoticePageCount(Math.ceil(res.data.length / PAGE_SIZE));
      setNotices(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {loading && <TailSpin className="mx-auto" stroke="#999" />}
      <div className="mb-20 notice_list_wrap">
        <div className="notice_list">
          <div className="flex items-center mb-2">
            <CampaignIcon className="mr-2" />
            <span className="text-lg">공지사항</span>
          </div>
          <ul className="notice_list_top">
            <li className="post_left_header w-[10%]">번호</li>
            <li className="post_header w-[10%]">구분</li>
            <li className="post_header w-[50%]">제목</li>
            <li className="post_header w-[15%]">등록일</li>
            <li className="post_header w-[15%]">등록자</li>
          </ul>

          {shownList.map(notice => {
            return (
              <Notice
                currentPage={currentPage}
                notice={notice}
                key={shortid.generate()}
              />
            );
          })}

          <div className="flex justify-center mt-8">
            <Pagination
              count={noticePageCount}
              page={currentPage}
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeList;
