import React, {useState} from "react";

import {Link, useNavigate} from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import {certificationApi} from "../../../src/api";

import DialogAlert from "../../components/modal/DialogAlert";

const Password = () => {
  const inputData = {
    username: "",
    phone_number: "",
    certification_number: "",
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [inputs, setInputs] = useState(inputData);
  const [certificated, setCertificated] = useState(true);

  const onChangeNum = event => {
    const {value, name} = event.target;
    setInputs({
      ...inputs,
      //[name]: value.replace(/[^0-9]/g, ""),
      [name]: value,
    });
  };

  const certificationPhone = async event => {
    if (inputs.username === "") {
      setModalContent("사업자 번호를 입력해 주세요.");
      setModalOpen(true);
      return;
    }

    if (inputs.phone_number === "") {
      setModalContent("대표자 전화번호를 입력해 주세요.");
      setModalOpen(true);
      return;
    }

    try {
      const res = await certificationApi.certificationUser(inputs);
      if (res.status === 200) {
        setCertificated(false);
        setModalContent(event.target.name);
        setModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      setModalContent("사업자 번호 및 전화번호를 확인해주세요.");
      setModalOpen(true);
    }
  };

  const certificationCertNum = async () => {
    try {
      const res = await certificationApi.changeTempPassword(inputs);
      console.log("inputs", inputs);
      if (res.status === 200) {
        setModalContent(
          "인증이 완료되었습니다. 요청하신 휴대폰번호로 임시비밀번호가 발송되었습니다.",
        );
        setModalOpen(true);
      }
    } catch (err) {
      console.error(err);
      // setCertificated(true);
      setModalContent("잘못된 인증번호 입니다.");
      setModalOpen(true);
    }
  };

  const handleClickOpen = event => {
    setModalContent(event.target.name);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  // submit signup
  const navigate = useNavigate();
  const submit = async event => {
    event.preventDefault();
    navigate("/login");
  };

  return (
    <>
      <form onSubmit={submit}>
        <main className="flex flex-col justify-center mt-[3rem] relative">
          <DialogAlert
            open={modalOpen}
            handleClose={handleClose}
            content={modalContent}
          />
          <Box
            sx={{
              width: 450,
              mx: "auto",
              my: 4,
              py: 3,
              px: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <section className="flex flex-col gap-y-2">
              <Typography
                className="self-center h-16 pt-3"
                level="h4"
                component="h1"
              >
                <span className="text-2xl font-bold">비밀번호 찾기</span>
              </Typography>

              <Typography className="self-center" level="body2">
                <span className="text-secondary">
                  가입 시 입력하신 휴대폰 번호로 임시 비밀번호를 알려드립니다.
                </span>
              </Typography>
            </section>
            <section className="flex flex-col mt-8 felx gap-y-2">
              <TextField
                error={inputs.username.length < 10 && inputs.username !== ""}
                required
                size="small"
                name="username"
                type="text"
                label="사업자번호를 입력하여 주세요"
                onChange={onChangeNum}
                value={inputs.username}
                sx={{borderRadius: "1px"}}
              />
              <div className="flex gap-x-2">
                <TextField
                  error={
                    inputs.phone_number.length < 11 &&
                    inputs.phone_number !== ""
                  }
                  required
                  size="small"
                  className="w-full"
                  name="phone_number"
                  type="text"
                  label="휴대폰번호를 입력하여주세요(대시(-)없이)"
                  value={inputs.phone_number}
                  onChange={onChangeNum}
                />

                <Button
                  sx={{width: "140px", padding: 0}}
                  color="grayBackground"
                  variant="contained"
                  name="인증번호가 전송되었습니다."
                  onClick={certificationPhone}
                >
                  인증번호 전송
                </Button>
              </div>
              <div className="flex gap-x-2">
                <TextField
                  required
                  error={
                    inputs.certification_number.length < 6 &&
                    inputs.certification_number !== ""
                  }
                  className="w-full"
                  size="small"
                  name="certification_number"
                  type="text"
                  label="인증번호를 입력하여 주세요"
                  value={inputs.certification_number}
                  onChange={onChangeNum}
                />
                <Button
                  sx={{width: "140px", padding: 0}}
                  color="grayBackground"
                  variant="contained"
                  onClick={certificationCertNum}
                  disabled={certificated}
                >
                  확인
                </Button>
              </div>

              <div className="text-sm text-primary">
                <span className="tracking-tight">
                  ※ 인증번호 입력 후 확인을 누르면 휴대폰으로 임시 비밀번호가
                  전송되며,
                </span>
                <br></br>
                <span className="pl-4">
                  다음을 클릭한 후 로그인 화면에서 임시 비밀번호로 로그인
                  하십시요.
                </span>

                <br></br>
                <span>
                  ※ 계정관리에 들어가셔서 비밀번호를 새롭게 만드시길 권장
                  드립니다.
                </span>
              </div>
            </section>
            <section className="flex flex-col gap-y-3">
              <Button
                type="submit"
                className="w-full"
                variant="contained"
                name="비밀번호가 휴대폰으로 전송된 임시비밀번호로 변경되었습니다."
                onClick={handleClickOpen}
              >
                다음
              </Button>
              <Link to="/login">
                <Button className="w-full" variant="outlined">
                  취소
                </Button>
              </Link>
            </section>
          </Box>
        </main>
      </form>
    </>
  );
};

export default Password;
