import React, {useState, useContext} from "react";
import {LoginContext, LOGIN_STATUS_LOGIN} from "../contexts/LoginContext";
import {Link} from "react-router-dom";
import {Stack} from "@mui/material";

const AlertTab = ({alertTab, setAlertTab}) => {
  const {loginInfo} = useContext(LoginContext);

  const closeTabOneDay = () => {
    let expires = new Date();
    expires = expires.setHours(expires.getHours() + 24);
    localStorage.setItem("visitOneDay", expires);

    setAlertTab(false);
  };

  return (
    <Stack>
      {loginInfo.isLogin === LOGIN_STATUS_LOGIN &&
        (alertTab ? (
          <div className="flex items-center h-12 pl-5 pr-5 bg-red-500 font-white">
            <Link to="/payment" className="flex-1">
              <p>
                자차 검사 모드가 활성화 되어있지만 결제가 진행되지 않았습니다.
                결제관리 페이지에서 결제 후 자차 검사 모드를 이용하실 수
                있습니다.
              </p>
            </Link>
            <button onClick={closeTabOneDay} className="font-bold ">
              X
            </button>
          </div>
        ) : null)}
    </Stack>
  );
};

export default AlertTab;
