import React, {useState, useEffect} from "react";
import {useCookies} from "react-cookie";
import {centerApi, priceApi} from "../../api";

import CostSettingTable from "../../components/table/costSettingTable";

// material-ui
import {
  Stack,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  createTheme,
  ThemeProvider,
  Divider,
} from "@mui/material";

const COOKIE_B2C_SERVICE_ENABLED = "b2c_service_enabled";

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
  },
});

const InspectionInfo = () => {
  const [cookies, setAuthCookie] = useCookies();

  const [centerData, setCenterData] = useState({
    b2b_service_enabled: false,
    b2c_service_enabled: false,
    can_inspection_car_number_day: 0,
    total_inspection_price_b2b: 0,
    regular_inspection_price_b2b: 0,
    pickup_enabled: false,
    pickup_price_km: 0,
    maximum_assignment_amount: 0,
  });

  const [rows, setRows] = useState({});

  function changeRows(e) {
    const {name, value, checked, type} = e.target;
    setRows({
      ...rows,
      [name]: type === "checkbox" ? checked : value,
    });
  }

  function changeCenterDetail(e) {
    const {name, value, checked, type} = e.target;
    setCenterData({
      ...centerData,
      [name]: type === "checkbox" ? checked : value,
    });
  }

  useEffect(() => {
    const getCenterCost = async () => {
      try {
        const {data, status} = await priceApi.getPriceList(
          cookies.access_token,
        );

        if (status === 200) {
          setRows(data);
        } else {
          alert("렌터카 검사 모드 설정 데이터를 불러올 수 없습니다.");
        }
      } catch (err) {
        alert("렌터카 검사 모드 설정 데이터를 불러올 수 없습니다.");
        throw Error(err);
      }
    };
    getCenterCost();
  }, []);

  useEffect(() => {
    const getCenterData = async () => {
      try {
        const {data, status} = await centerApi.myData(cookies.access_token);

        if (status === 200) {
          setCenterData(data);
        } else {
          alert("렌터카 데이터를 불러올 수 없습니다.");
        }
      } catch (error) {
        alert("렌터카 데이터를 불러올 수 없습니다.");
      }
    };
    getCenterData();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();

    const centerPayload = {
      b2b_service_enabled: centerData.b2b_service_enabled,
      b2c_service_enabled: centerData.b2c_service_enabled,
      can_inspection_car_number_day: centerData.can_inspection_car_number_day,
      pickup_enabled: centerData.pickup_enabled,
      pickup_price_km: centerData.pickup_price_km,
      maximum_assignment_amount: centerData.maximum_assignment_amount,
    };

    const pricePayload = rows;
    try {
      const {status: detailStatus} = await centerApi.putCenterDetail(
        centerPayload,
        cookies.access_token,
      );

      const {status: priceStatus} = await priceApi.putPriceList(
        pricePayload,
        cookies.access_token,
      );

      if (detailStatus === 200 && priceStatus === 200) {
        alert("검사 정보 관리가 변경되었습니다.");
      } else {
        alert("검사 정보 관리가 변경이 실패하였습니다.");
      }
    } catch (error) {
      alert("검사 정보 관리가 변경이 실패하였습니다.");
      throw Error(error);
    } finally {
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

      setAuthCookie(
        COOKIE_B2C_SERVICE_ENABLED,
        centerData.b2c_service_enabled,
        {
          expires: oneWeekFromNow,
        },
      );
    }
  };

  return (
    <>
      {/* {loading && <TailSpin className="mx-auto" stroke="#999" />} */}

      <Stack className="max-w-5xl mx-auto mt-[3rem]" spacing={3}>
        <Stack>
          <Box className="flex flex-row items-center justify-between py-8 border-b-2 border-b-black">
            <Typography variant="h5" fontWeight="bold">
              검사 정보 관리
            </Typography>
          </Box>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={700}>
            사용중인 모드
          </Typography>
          <Typography className="text-secondary" mb={3}>
            모드 설정
          </Typography>

          <Stack flexDirection="row">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={changeCenterDetail}
                  name="b2b_service_enabled"
                  checked={centerData.b2b_service_enabled}
                />
              }
              label={
                <Typography variant="h7" fontWeight={700}>
                  렌터카 검사 모드
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={changeCenterDetail}
                  name="b2c_service_enabled"
                  checked={centerData.b2c_service_enabled}
                  disabled
                />
              }
              label={
                <Typography variant="h7" fontWeight={700}>
                  자차 검사 모드(업데이트 예정)
                </Typography>
              }
            />
          </Stack>

          <Stack className="my-5 border-b-2 border-b-gray"></Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={700}>
            정산 정보
          </Typography>
          <Typography className="text-secondary" mb={3}>
            렌터카 검사 모드 비용
          </Typography>

          <Grid container width={250}>
            <Grid item xs={6}>
              <Typography>종합검사 비용</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {`${centerData.total_inspection_price_b2b.toLocaleString()}원`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>정기검사 비용</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {`${centerData.regular_inspection_price_b2b.toLocaleString()}원`}
              </Typography>
            </Grid>
          </Grid>
          <Stack className="my-5 border-b-2 border-b-gray"></Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={700}>
            검사 종류 및 시간 당 검사기능 차량 수
          </Typography>

          <Stack gap={1}>
            <Typography className="text-secondary" mb={3}>
              정기 / 종합검사
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography justifySelf="center">
                시간 당 검사기능 차량 수 :
              </Typography>
              <TextField
                onChange={changeCenterDetail}
                name="can_inspection_car_number_day"
                value={centerData.can_inspection_car_number_day}
                sx={{width: 100}}
                inputProps={{
                  style: {textAlign: "center"},
                }}
              />
              <Typography>
                대/ 시간 (1시간에 검사완료할 수 있는 차량 수)
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography justifySelf="center">월 최대 배정 물량 :</Typography>
              <TextField
                onChange={changeCenterDetail}
                name="maximum_assignment_amount"
                value={centerData.maximum_assignment_amount}
                sx={{width: 100}}
                inputProps={{
                  style: {textAlign: "center"},
                }}
              />
              <Typography>
                대/ 월 (한달에 최대로 배정받을 수 있는 검사 건수)
              </Typography>
            </Box>
          </Stack>

          <Stack className="my-5 border-b-2 border-b-gray"></Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={700}>
            자차 검사 모드 비용 설정(업데이트 예정)
          </Typography>
          <Typography className="text-secondary" mb={5}>
            차량별 검사 금액
          </Typography>

          <CostSettingTable rows={rows} changeRows={changeRows} />

          <Stack flexDirection="row-reverse" mt={0}>
            <Typography className="text-secondary">(단위 : 원)</Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography variant="h6" fontWeight={700}>
            차량 픽업
          </Typography>
          <Typography className="text-secondary" mb={3}>
            차량 픽업 설정
          </Typography>
          <Grid container width={400} alignItems="center">
            <Grid item xs={4}>
              <Typography>픽업 진행 여부</Typography>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                onChange={changeCenterDetail}
                name="pickup_enabled"
                checked={centerData.pickup_enabled}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography>픽업비용</Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>1km당</Typography>
                <TextField
                  type="number"
                  onChange={changeCenterDetail}
                  name="pickup_price_km"
                  value={centerData.pickup_price_km}
                  sx={{width: 150}}
                  inputProps={{
                    style: {textAlign: "center"},
                  }}
                />
                <Typography>원</Typography>
              </Box>
            </Grid>
          </Grid>
          <Stack className="mt-5 border-b-2 border-b-gray"></Stack>
        </Stack>

        <Stack display="flex" alignItems="center" pb={5}>
          <Button
            onClick={onSubmit}
            color="primary"
            variant="contained"
            sx={{width: 200}}
          >
            저장
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default InspectionInfo;
