import XLSX from "xlsx/dist/xlsx.full.min.js";

import {useRef, useState, useEffect} from "react";
import {useCookies} from "react-cookie";

import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {Stack, Typography, Button, styled, Box} from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {pastInspectionApi} from "../../api";
import MainCard from "../../components/MainCard";
const SHEET_MAXIMUM_LENGTH = 15000;

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#e7e9ec",
  },
  [`& .${gridClasses.row}`]: {
    "&.Mui-selected": {
      backgroundColor: "rgba(3, 110, 184, 0.2)",
    },
  },
}));

const columns = [
  {
    field: "id",
    headerName: "번호",
    width: 80,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "car_number",
    headerName: "차량번호",
    width: 120,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "user_name",
    headerName: "고객명",
    width: 100,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },

  {
    field: "car_registered_at",
    headerName: "차량등록일",
    width: 150,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "car_name",
    headerName: "차량종류",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "next_expired_at",
    headerName: "유효기간만료일",
    width: 150,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "renewal_months",
    headerName: "갱신 개월 수",
    width: 100,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "phone_number",
    headerName: "고객연락처",
    width: 160,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "user_address",
    headerName: "주소",
    width: 250,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "user_address_detail",
    headerName: "상세 주소",
    width: 200,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
];

const PastInspectionCreate = () => {
  const [cookies] = useCookies();

  const [pastInspectionList, setPastInspectionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pastInspectionFile, setPastInsepctionFile] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [uploadingProgress, setUploadingProgress] = useState(null);
  const fileSelectRef = useRef();

  useEffect(() => {
    if (pastInspectionFile) {
      setLoading(true);
      readXlsx(pastInspectionFile);
    }
  }, [pastInspectionFile]);

  const readXlsx = file => {
    const reader = new FileReader();
    reader.onload = e => {
      const binary = e.target.result;
      const workbook = XLSX.read(binary, {type: "binary"});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetResult = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: false,
        range: 1,
      });

      let pastInspectionsXlsx = [];
      sheetResult.forEach(row => {
        const car_number = row[1];
        const user_name = row[2];
        let car_registered_at = row[5];
        const car_name = row[7];
        let phone_number = row[17];
        const user_address = row[21];
        const user_address_detail = row[22];
        let next_expired_at = row[28];
        const renewal_months = row[31];

        if (!carNumberValidate(car_number)) {
          return;
        }

        car_registered_at = getCleanedDate(car_registered_at, false);

        next_expired_at = getCleanedDate(next_expired_at, true);

        if (!next_expired_at) {
          return;
        }

        const next_expired_at_date = new Date(next_expired_at);
        const now = new Date();
        now.setMonth(now.getMonth() - 2);

        if (next_expired_at_date < now) {
          return;
        }

        const cleanedPhoneNumber = getCleanedPhoneNumber(phone_number);

        if (cleanedPhoneNumber) {
          phone_number = cleanedPhoneNumber;
        } else {
          return;
        }

        const past_inspection = {
          id: pastInspectionsXlsx.length,
          car_number,
          next_expired_at,
          phone_number,
          user_name,
          car_name,
          renewal_months,
          car_registered_at,
          user_address,
          user_address_detail,
        };

        pastInspectionsXlsx.push(past_inspection);
      });

      if (pastInspectionsXlsx.length > SHEET_MAXIMUM_LENGTH) {
        pastInspectionsXlsx = pastInspectionsXlsx.slice(
          pastInspectionsXlsx.length - SHEET_MAXIMUM_LENGTH,
        );

        alert(
          `한 번에 최대 ${SHEET_MAXIMUM_LENGTH}개의 검사까지만 불러올 수 있습니다.`,
        );
      }
      fileSelectRef.current.value = "";
      setPastInsepctionFile(null);
      setLoading(false);
      setPastInspectionList(pastInspectionsXlsx);
    };
    reader.readAsBinaryString(file);
    setLoading(false);
  };

  const carNumberValidate = carNumber => {
    if (carNumber) {
      if (isNaN(carNumber.slice(2, 3))) {
        if (carNumber.length !== 7) {
          return false;
        }
        if (!isNaN(carNumber.slice(0, 2)) && !isNaN(carNumber.slice(3))) {
          return true;
        } else {
          return false;
        }
      } else if (isNaN(carNumber.slice(3, 4))) {
        if (carNumber.length !== 8) {
          return false;
        }
        if (!isNaN(carNumber.slice(0, 3)) && !isNaN(carNumber.slice(4))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const getCleanedDate = (date, isExpiredDate) => {
    if (date) {
      const date_split = date.split("/");
      const year = Number(date_split[2]);
      let month = Number(date_split[0]);
      let day = Number(date_split[1]);

      if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return null;
      }

      try {
        const date = new Date(
          `20${year < 10 ? "0" + year : year}-${month}-${day}`,
        );
        if (isNaN(date)) {
          return null;
        }
        const now = new Date();

        if (date < now && isExpiredDate) {
          return null;
        } else {
          month = ("0" + month).slice(-2);
          day = ("0" + day).slice(-2);

          return `20${year < 10 ? "0" + year : year}-${month}-${day}`;
        }
      } catch {
        return null;
      }
    } else {
      return null;
    }
  };

  const getCleanedPhoneNumber = phoneNumber => {
    if (phoneNumber) {
      phoneNumber = phoneNumber.replaceAll("-", "");

      if (isNaN(phoneNumber)) {
        return null;
      }

      if (phoneNumber.length === 10) {
        if (
          phoneNumber.startsWith("10") ||
          phoneNumber.startsWith("11") ||
          phoneNumber.startsWith("16") ||
          phoneNumber.startsWith("17") ||
          phoneNumber.startsWith("18") ||
          phoneNumber.startsWith("19")
        ) {
          return "0" + phoneNumber;
        } else {
          return null;
        }
      } else if (phoneNumber.length === 11) {
        if (
          phoneNumber.startsWith("010") ||
          phoneNumber.startsWith("011") ||
          phoneNumber.startsWith("016") ||
          phoneNumber.startsWith("017") ||
          phoneNumber.startsWith("018") ||
          phoneNumber.startsWith("019")
        ) {
          return phoneNumber;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const requestPastInspectionCreate = async reqData => {
    const {status, data} = await pastInspectionApi.createPastInspectionList(
      cookies.access_token,
      reqData,
    );
    return {status, data};
  };
  return (
    <MainCard>
      <Stack className="max-w-[1450px] mx-auto ">
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h5" fontWeight="bold">
            지난 검사 등록
          </Typography>
          {uploadingProgress && (
            <Typography variant="body" fontWeight="bold">
              총 {pastInspectionList.length}개 데이터 중 {uploadingProgress}개
              업로드 완료
            </Typography>
          )}

          <Box>
            <Button
              variant="contained"
              sx={{marginRight: "10px"}}
              startIcon={<ListAltIcon />}
              onClick={() => {
                fileSelectRef.current.click();
              }}
              disabled={loading}
            >
              지난검사 엑셀파일 등록
            </Button>
            <input
              ref={fileSelectRef}
              type="file"
              name="pastInspectionFile"
              accept=".xlsx"
              onChange={event => {
                setPastInsepctionFile(event.target.files[0]);
              }}
              hidden
            />
            <Button
              disabled={pastInspectionList.length === 0 || loading}
              variant="contained"
              startIcon={<CloudUploadIcon />}
              onClick={async () => {
                try {
                  setLoading(true);

                  const reqData = pastInspectionList.map(pastInspection => {
                    if (typeof pastInspection.mileage === "string") {
                      pastInspection.mileage = Number(
                        pastInspection.mileage.replace(",", ""),
                      );
                    }
                    pastInspection.center = cookies.center_id;
                    return pastInspection;
                  });

                  const scale = 500;
                  const count = parseInt(reqData.length / scale);
                  let error = false;

                  for (let i = 0; i < count + 1; i++) {
                    const start = i * scale;
                    const end = i === count ? reqData.length : (i + 1) * scale;

                    const {status, data} = await requestPastInspectionCreate(
                      reqData.slice(start, end),
                    );
                    if (status === 200) {
                      setUploadingProgress(end);
                    } else {
                      alert("서버 에러 - 관리자에게 문의해주세요.");
                      error = true;
                      break;
                    }
                  }

                  if (!error) {
                    alert("업로드 완료");
                  }

                  setUploadingProgress(null);
                } catch (e) {
                  const {data, status} = e.response;
                  alert(`${status} : ${JSON.stringify(data)}`);
                } finally {
                  setLoading(false);
                  setUploadingProgress(null);
                  setPastInspectionList([]);
                }
              }}
            >
              서버에 등록하기
            </Button>
          </Box>
        </Stack>

        <Box
          sx={{
            display: "flex",
            height: "calc( 100vh - 220px )",
            width: "100%",
            "& .inspection-table-header": {
              backgroundColor: "#2d353c",
              color: "white",
              textAlign: "center",
              justifyContent: "center",
              borderColor: "#000000",
            },
            ".MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center",
              color: "#ffffff",
            },
            ".MuiDataGrid-selectedRowCount": {
              color: "#ffffff",
            },
            ".MuiDataGrid-sortIcon": {
              color: "#ffffff",
              opacity: 1,
            },
            ".css-204u17-MuiDataGrid-main": {
              overflow: "auto",
            },
          }}
        >
          <StripedDataGrid
            rows={pastInspectionList}
            loading={loading}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100]}
            pageSize={pageSize}
            onPageSizeChange={pageSize => setPageSize(pageSize)}
            // onRowClick={handleRowClick}
            disableColumnMenu
            headerHeight={40}
            rowHeight={40}
            autoHeight
            getRowClassName={params => {
              let className =
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";

              return className;
            }}
            sx={{
              marginTop: "30px",
              overflow: "auto",
            }}
          />
        </Box>
      </Stack>
    </MainCard>
  );
};

export default PastInspectionCreate;
