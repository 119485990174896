/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import {authApi} from "../../../src/api";
import {centerApi} from "../../../src/api";
import {useCookies} from "react-cookie";

export const LoginContext = React.createContext();
export const LOGIN_STATUS_PENDING = 0;
export const LOGIN_STATUS_LOGIN = 1;
export const LOGIN_STATUS_LOGOUT = 2;
export const COOKIE_ACCESS_TOKEN = "access_token";
export const COOKIE_ACCESS_TOKEN_EXPIRATION = "access_token_expiration";
export const COOKIE_REFRESH_TOKEN = "refresh_token";
export const COOKIE_COMPANY_NAME = "company_name";
export const COOKIE_CEO_NAME = "ceo_name";
export const COOKIE_REGISTRATION_NUM = "com_registration_number";
export const COOKIE_REGISTRATION_CENTER_ID = "center_id";
export const COOKIE_B2C_SERVICE_ENABLED = "b2c_service_enabled";
const LoginContextProvider = ({children}) => {
  const initLoginInfo = {
    companyName: "",
    ceoName: "",
    registrationNum: "",
    isLogin: LOGIN_STATUS_PENDING,
    centerId: 0,
  };
  const date = new Date();
  const [authCookies, setAuthCookie, removeAuthCookie] = useCookies([
    COOKIE_ACCESS_TOKEN,
    COOKIE_ACCESS_TOKEN_EXPIRATION,
    COOKIE_REFRESH_TOKEN,
    COOKIE_COMPANY_NAME,
    COOKIE_CEO_NAME,
    COOKIE_REGISTRATION_NUM,
    COOKIE_REGISTRATION_CENTER_ID,
    COOKIE_B2C_SERVICE_ENABLED,
  ]);
  const [loginInfo, setLoginInfo] = useState(initLoginInfo);
  const [refreshCount, setRefreshCount] = useState(0);

  const login = async inputs => {
    try {
      const {
        data: {access_token, refresh_token, user},
        status,
      } = inputs;

      if (status === 200) {
        const {
          data: {name, ceo_name, username, b2c_service_enabled},
          status,
        } = await centerApi.myData(access_token);

        if (status === 200) {
          const oneWeekFromNow = new Date(date);
          oneWeekFromNow.setDate(date.getDate() + 7);
          setAuthCookie(COOKIE_ACCESS_TOKEN, access_token, {
            expires: oneWeekFromNow,
          });

          setAuthCookie(COOKIE_REFRESH_TOKEN, refresh_token, {
            expires: oneWeekFromNow,
          });
          setAuthCookie(COOKIE_CEO_NAME, ceo_name, {
            expires: oneWeekFromNow,
          });
          setAuthCookie(COOKIE_COMPANY_NAME, name, {
            expires: oneWeekFromNow,
          });
          setAuthCookie(COOKIE_REGISTRATION_NUM, username, {
            expires: oneWeekFromNow,
          });
          setAuthCookie(COOKIE_REGISTRATION_CENTER_ID, user.pk, {
            expires: oneWeekFromNow,
          });
          setAuthCookie(COOKIE_B2C_SERVICE_ENABLED, b2c_service_enabled, {
            expires: oneWeekFromNow,
          });

          setLoginInfo({
            companyName: name,
            ceoName: ceo_name,
            registrationNum: username,
            isLogin: LOGIN_STATUS_LOGIN,
            centerId: user.pk,
            isUseB2C: b2c_service_enabled,
          });
        } else {
          //login error
          console.log("login error");
        }
      } else {
        //Auth error
        console.log("auth error");
      }
    } catch {
      console.log("error");
      //error
    } finally {
    }
  };

  const logout = async () => {
    try {
      const refreshToken = authCookies.refresh_token;

      const {status} = await authApi.logout({refresh: refreshToken});

      if (status === 200) {
        setLoginInfo({
          companyName: "",
          ceoName: "",
          isLogin: LOGIN_STATUS_LOGOUT,
          centerId: 0,
          isUseB2C: false,
        });
        removeAuthCookie("ceo_name");
        removeAuthCookie("company_name");
        removeAuthCookie("access_token");
        removeAuthCookie("refresh_token");
        removeAuthCookie("com_registration_number");
        removeAuthCookie("center_id");
        removeAuthCookie("b2c_service_enabled");
        removeAuthCookie("access_token_expiration");
      } else {
        setLoginInfo({
          companyName: "",
          ceoName: "",
          isLogin: LOGIN_STATUS_LOGOUT,
          centerId: 0,
          isUseB2C: false,
        });
        removeAuthCookie("ceo_name");
        removeAuthCookie("company_name");
        removeAuthCookie("access_token");
        removeAuthCookie("refresh_token");
        removeAuthCookie("com_registration_number");
        removeAuthCookie("center_id");
        removeAuthCookie("b2c_service_enabled");
        removeAuthCookie("access_token_expiration");
      }
    } catch (err) {
      setLoginInfo({
        companyName: "",
        ceoName: "",
        isLogin: LOGIN_STATUS_LOGOUT,
        centerId: 0,
        isUseB2C: false,
      });
      removeAuthCookie("ceo_name");
      removeAuthCookie("company_name");
      removeAuthCookie("access_token");
      removeAuthCookie("refresh_token");
      removeAuthCookie("com_registration_number");
      removeAuthCookie("center_id");
      removeAuthCookie("b2c_service_enabled");
      removeAuthCookie("access_token_expiration");
    }
  };

  const goLogin = async (access_token, refresh_token, centerId) => {
    const {
      data: {name, ceo_name, username, b2c_service_enabled},
      status,
    } = await centerApi.myData(access_token);

    if (status === 200) {
      const oneWeekFromNow = new Date(date);
      oneWeekFromNow.setDate(date.getDate() + 7);
      setAuthCookie(COOKIE_ACCESS_TOKEN, access_token, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_REFRESH_TOKEN, refresh_token, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_CEO_NAME, ceo_name, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_COMPANY_NAME, name, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_REGISTRATION_NUM, username, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_REGISTRATION_CENTER_ID, centerId, {
        expires: oneWeekFromNow,
      });
      setAuthCookie(COOKIE_B2C_SERVICE_ENABLED, b2c_service_enabled, {
        expires: oneWeekFromNow,
      });
      setLoginInfo({
        companyName: name,
        ceoName: ceo_name,
        registrationNum: username,
        isLogin: LOGIN_STATUS_LOGIN,
        centerId,
        isUseB2C: b2c_service_enabled,
      });
    } else {
      console.log("login error");
    }
  };

  const checkLogin = async isRefresh => {
    const accessToken = authCookies.access_token;
    const refreshToken = authCookies.refresh_token;
    const accessTokenExperation = authCookies.access_token_expiration;
    const centerId = parseInt(authCookies.center_id);
    // console.log(
    //   "accessTokenExperation <= new Date()",
    //   accessTokenExperation <= new Date(),
    // );

    // access_token_expiration

    // tokne 값을 처음에 받고 vaild 토큰이면 refresh api 보내지 않기
    // 1. checkLogin을 처음으로 실행할 떄 valid token이면 refreshToken API 쏘지 않기
    // 2-1. accessToken expoired가 현재 시간보다 이전이면 다시 토큰을 발급받아서 token넣어주기
    // 2-2. accessToken expoired가 현재 시간보다 이후이면 바로 로그인 처리 해주기

    if (accessToken) {
      try {
        // console.log(
        //   "accessTokenExperation >= new Date()",
        //   new Date(accessTokenExperation).getTime() >= new Date().getTime(),
        // );
        if (
          accessTokenExperation &&
          new Date(accessTokenExperation).getTime() >= new Date().getTime()
        ) {
          return await goLogin(accessToken, refreshToken, centerId);
        }

        const {status} = await authApi.verifyToken({token: accessToken});
        if (status === 200) {
          if (isRefresh) {
            const {status, data} = await authApi.refreshToken({
              refresh: refreshToken,
            });

            const oneWeekFromNow = new Date(date);
            oneWeekFromNow.setDate(date.getDate() + 7);
            setAuthCookie(
              COOKIE_ACCESS_TOKEN_EXPIRATION,
              new Date(data.access_token_expiration),
              {
                expires: oneWeekFromNow,
              },
            );

            if (status === 200) {
              const {access, refresh} = data;
              await goLogin(access, refresh, centerId);
            } else {
              logout();
            }
          } else {
            logout();
          }
        } else {
          logout();
        }
      } catch (err) {
        try {
          const {status} = await authApi.verifyToken({token: refreshToken});
          if (status === 200) {
            const {status, data} = await authApi.refreshToken({
              refresh: refreshToken,
            });
            if (status === 200) {
              const {access, refresh} = data;
              await goLogin(access, refresh, centerId);
            } else {
              logout();
            }
          }
        } catch {
          logout();
        }
      }
    } else {
      logout();
    }
  };
  const refreshUserInfo = () => {
    setRefreshCount(prevState => {
      return prevState + 1;
    });
  };
  return (
    <LoginContext.Provider
      value={{
        loginInfo,
        login,
        logout,
        checkLogin,
        refreshCount,
        refreshUserInfo,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
