import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "160px",
  padding: "5px 10px",
  border: "3px solid",
  borderRadius: "10px",
}));

const CountTitleTypo = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "1",
  fontWeight: "500",
}));
const CountValueTypo = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "700",
}));
const SummaryCard = ({ title, value, color }) => {
  return (
    <Item
      sx={{
        borderColor: color,
        color: color,
      }}
    >
      <CountTitleTypo>{title}</CountTitleTypo>
      <CountValueTypo>{value}대</CountValueTypo>
    </Item>
  );
};

export default SummaryCard;
