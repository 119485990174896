import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  ASSIGNMENT_CANCEL_REASON1,
  ASSIGNMENT_CANCEL_REASONS,
} from "../../constants/inspection";
import {useState} from "react";

const AssignmentCancelModal = ({
  open,
  handleClose,
  title,
  content,
  buttonText1,
  buttonText2,
  handleButton1Click,
  handleButton2Click,
  loading,
}) => {
  const [cancelReason, setCancelReason] = useState(ASSIGNMENT_CANCEL_REASON1);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{p: 1, py: 1.5, width: "600px"}}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Stack direction={"column"} marginBottom={3} gap={1}>
            <Typography minWidth={"100px"}>취소 사유</Typography>
            <FormControl sx={{width: "100%"}} size="small">
              <Select
                required
                value={cancelReason}
                onChange={e => {
                  setCancelReason(e.target.value);
                }}
              >
                {ASSIGNMENT_CANCEL_REASONS.map((reason, idx) => {
                  return (
                    <MenuItem value={reason} key={idx}>
                      {reason}
                    </MenuItem>
                  );
                })}
                ;
              </Select>
            </FormControl>
          </Stack>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} color="error" onClick={handleButton1Click}>
            {buttonText1}
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => {
              handleButton2Click(cancelReason);
            }}
            autoFocus
          >
            {buttonText2}
          </Button>
        </DialogActions>
      </Box>
      {loading && (
        <Box
          position={"absolute"}
          sx={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default AssignmentCancelModal;
