import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import AccountModal from "../modal/AccountModal";
import PasswordModal from "../modal/PasswordModal";
import WithdrawalModal from "../modal/WithdrawalModal";
import TermsModal from "../modal/TermsModal";

const CENTER_MODIFY_MODAL = "업체정보 수정";
const PASSWORD_CHANGE_MODAL = "비밀번호 변경";
const CENTER_WITHDRAWAL_MODAL = "회원 탈퇴";
const TERMS_PERSON_MODAL = "개인정보 처리방침";
const TERMS_RULSE_MODAL = "자카 서비스 이용약관";
const TERMS_LOCATION_MODAL = "자카 위치기반 서비스 이용약관";
const TERMS_ADVERTISEMENT_MODAL = "자카 마케팅 활용 및 광고성 정보 수신동의";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const NestedModal = ({open, handleClose, title, user, setUser}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{...style, width: 800, height: 700}}>
          <p className="my-5 text-lg font-bold" id="parent-modal-title">
            {title}
          </p>
          {title === CENTER_MODIFY_MODAL ? (
            <AccountModal
              user={user}
              setUser={setUser}
              handleClose={handleClose}
            />
          ) : title === PASSWORD_CHANGE_MODAL ? (
            <PasswordModal
              user={user}
              setUser={setUser}
              handleClose={handleClose}
            />
          ) : title === CENTER_WITHDRAWAL_MODAL ? (
            <WithdrawalModal
              handleClose={handleClose}
              user={user}
              setUser={setUser}
            />
          ) : title === TERMS_RULSE_MODAL ? (
            <TermsModal title={TERMS_RULSE_MODAL} handleClose={handleClose} />
          ) : title === TERMS_PERSON_MODAL ? (
            <TermsModal title={TERMS_PERSON_MODAL} handleClose={handleClose} />
          ) : title === TERMS_LOCATION_MODAL ? (
            <TermsModal
              title={TERMS_LOCATION_MODAL}
              handleClose={handleClose}
            />
          ) : title === TERMS_ADVERTISEMENT_MODAL ? (
            <TermsModal
              title={TERMS_ADVERTISEMENT_MODAL}
              handleClose={handleClose}
            />
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default NestedModal;
