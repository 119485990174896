import * as React from "react";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {Box, styled} from "@mui/material";

import * as InspectionConst from "../../constants/inspection";
import * as InspectionConstB2C from "../../constants/b2cInspection";
import "../../assets/css/inspection.css";
import {getDateDiffRound} from "../../utils/dateutil";
import {PAGE_TYPE_B2B} from "../../routes/inspection/Inspection";

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#e7e9ec",
  },
  [`& .${gridClasses.row}`]: {
    "&.Mui-selected": {
      backgroundColor: "rgba(3, 110, 184, 0.2)",
    },
  },
}));
const columnsB2B = [
  {
    field: "id",
    headerName: "번호",
    width: 70,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "current_step_name",
    headerName: "진행사항",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "reservation_type",
    headerName: "예약유형",
    width: 100,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "reservation_date",
    headerName: "예약일자",
    width: 100,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "inspection_complete_date",
    headerName: "검사일자",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "inspection_expired_date",
    headerName: "유효기간만료일",
    width: 140,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "customer_company_name",
    headerName: "고객사",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "user_name_asterisk",
    headerName: "계약자명",
    width: 90,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "driver_name_asterisk",
    headerName: "운전자명",
    width: 90,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "driver_phone_number_asterisk",
    headerName: "운전자연락처",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "pickup_address_asterisk",
    headerName: "픽업주소",
    width: 200,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "car_number",
    headerName: "차량번호",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "mileage",
    headerName: "주행거리(km)",
    width: 110,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "car_name",
    headerName: "공단차종명",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "car_registered_at",
    headerName: "차량등록일",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "inspection_type",
    headerName: "검사종류",
    width: 80,
    align: "center",
    editable: false,
    headerClassName: "inspection-table-header",
    sortable: false,
  },

  {
    field: "counselor_asterisk",
    headerName: "상담원",
    width: 90,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "payment_person_name_asterisk",
    headerName: "결제담당자",
    width: 90,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "payment_person_contact_asterisk",
    headerName: "결제담당자연락처",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "updated_at",
    headerName: "마지막수정일자",
    width: 170,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
];
const columnsB2C = [
  {
    field: "id",
    headerName: "번호",
    width: 65,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "current_step_name",
    headerName: "진행사항",
    width: 120,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "reservation_date",
    headerName: "예약일자",
    width: 100,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "reservation_type",
    headerName: "예약유형",
    width: 80,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
  {
    field: "car_number",
    headerName: "차량번호",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "car_type",
    headerName: "차량종류",
    width: 150,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "inspection_type",
    headerName: "검사종류",
    width: 80,
    align: "center",
    editable: false,
    headerClassName: "inspection-table-header",
    sortable: false,
  },

  {
    field: "user_phone_number_asterisk",
    headerName: "고객연락처",
    width: 100,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },
  {
    field: "user_name_asterisk",
    headerName: "고객명",
    width: 90,
    align: "center",
    editable: false,
    sortable: false,
    headerClassName: "inspection-table-header",
  },

  {
    field: "center_name",
    headerName: "검사소",
    width: 150,
    align: "center",
    editable: false,
    headerClassName: "inspection-table-header",
    sortable: false,
  },

  {
    field: "updated_at",
    headerName: "마지막수정일자",
    width: 170,
    align: "center",
    editable: false,
    sortable: true,
    headerClassName: "inspection-table-header",
  },
];
export const getCurrentStepClassName = (
  currentStepValue,
  stepUpdatedAt,
  pageType = PAGE_TYPE_B2B,
) => {
  let className = "";
  className += "font-bold ";

  if (pageType === PAGE_TYPE_B2B) {
    if (
      currentStepValue ===
        InspectionConst.STEP_NAME[InspectionConst.STEP_ASSIGNMENT_COMPLETE] ||
      currentStepValue ===
        InspectionConst.STEP_NAME[InspectionConst.STEP_RESERVATION_CANCEL]
    ) {
      className += "bg-stepzone-yellow bg-stepzone-yellow-6";
    } else if (
      currentStepValue ===
      InspectionConst.STEP_NAME[InspectionConst.STEP_RESERVATION_COMPLETE]
    ) {
      className += "bg-stepzone-green text-white font-white";
    } else if (
      currentStepValue ===
      InspectionConst.STEP_NAME_FOR_CENTER[
        InspectionConst.STEP_INSPECTION_COMPELTE
      ]
    ) {
      className += "bg-stepzone-blue font-white";
    } else if (
      currentStepValue ===
        InspectionConst.STEP_NAME_FOR_CENTER[InspectionConst.STEP_MISSED] ||
      currentStepValue ===
        InspectionConst.STEP_NAME_FOR_CENTER[InspectionConst.STEP_NO_SHOW]
    ) {
      className += "bg-stepzone-red font-white";
    } else {
      className += "bg-stepzone-gray";
    }
  } else {
    if (
      currentStepValue ===
      InspectionConstB2C.STEP_NAME[InspectionConstB2C.STEP_RESERVATION_COMPLETE]
    ) {
      className += "bg-stepzone-green font-white";
    } else if (
      currentStepValue ===
      InspectionConstB2C.STEP_NAME[InspectionConstB2C.STEP_INSPECTION_COMPLETE]
    ) {
      className += "bg-stepzone-blue font-white";
    } else if (
      currentStepValue ===
      InspectionConstB2C.STEP_NAME[InspectionConstB2C.STEP_NO_SHOW]
    ) {
      className += "bg-stepzone-red font-white";
    } else if (
      currentStepValue ===
      InspectionConstB2C.STEP_NAME[InspectionConstB2C.STEP_FAIL]
    ) {
      className += "bg-stepzone-yellow bg-stepzone-yellow-6";
    } else {
      className += "bg-stepzone-gray";
    }
  }

  return className;
};
const InspectionTable = ({
  setSelectedInspection,
  data,
  rowCount,
  loading,
  page,
  setPage,
  pageSize,
  setPageSize,
  setOrderBy,
  pageType,
}) => {
  const [rowCountState, setRowCountState] = React.useState(rowCount);
  React.useEffect(() => {
    setRowCountState(prevRowCountState =>
      rowCount !== undefined ? rowCount : prevRowCountState,
    );
  }, [rowCount, setRowCountState]);

  const handleRowClick = event => {
    setSelectedInspection(event.row);
  };
  const handleSortModelChange = React.useCallback(
    sortModel => {
      // Here you save the data you need from the sort model
      let orderBy = "";
      if (sortModel.length > 0) {
        orderBy = (sortModel[0].sort === "asc" ? "" : "-") + sortModel[0].field;
      }
      setOrderBy(orderBy);
    },
    [setOrderBy],
  );
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .inspection-table-header": {
          backgroundColor: "#2d353c",
          color: "white",
          textAlign: "center",
          justifyContent: "center",
          borderColor: "#000000",
        },
        ".MuiDataGrid-columnHeaderTitleContainer": {
          justifyContent: "center",
          color: "#ffffff",
        },
        ".MuiDataGrid-selectedRowCount": {
          color: "#ffffff",
        },
        ".MuiDataGrid-sortIcon": {
          color: "#ffffff",
          opacity: 1,
        },
      }}
    >
      <StripedDataGrid
        rows={data}
        rowCount={rowCountState}
        loading={loading}
        columns={pageType === PAGE_TYPE_B2B ? columnsB2B : columnsB2C}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        page={page - 1}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={newPage => {
          setPage(newPage + 1);
        }}
        onPageSizeChange={newPageSize => {
          setPageSize(newPageSize);
        }}
        onRowClick={handleRowClick}
        onSortModelChange={handleSortModelChange}
        disableColumnMenu
        headerHeight={30}
        rowHeight={30}
        experimentalFeatures={{newEditingApi: true}}
        getCellClassName={params => {
          let className = "";

          if (params.field === "current_step_name") {
            className = getCurrentStepClassName(
              params.value,
              params.row.step_updated_at,
              pageType,
            );
          }

          if (params.field === "inspection_expired_date") {
            if (
              params.row.current_step !==
                InspectionConst.STEP_INSPECTION_COMPELTE &&
              params.row.current_step !==
                InspectionConst.STEP_RESERVATION_COMPLETE
            ) {
              const diff = getDateDiffRound(new Date(params.value), new Date());
              if (diff < InspectionConst.EXPIRED_YELLOW) {
                className = "expired-point expired-yellow";
              }
              if (diff < InspectionConst.EXPIRED_RED) {
                className = "expired-point expired-red";
              }
            }
          }
          return className;
        }}
        getRowClassName={params => {
          let className =
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";

          return className;
        }}
      />
    </Box>
  );
};

export default InspectionTable;
