/* eslint-disable react-hooks/exhaustive-deps */
import {
  Select,
  MenuItem,
  TextareaAutosize,
  Button,
  Box,
  Typography,
  Grid,
  styled,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import {useCookies} from "react-cookie";

import {useContext, useEffect} from "react";
import {useState} from "react";
import {b2cInspectionApi} from "../../api";
import * as InspectionConst from "../../constants/inspection";
import * as InspectionConstB2C from "../../constants/b2cInspection";
import "../../assets/css/inspection.css";
import {getCurrentStepClassName} from "./InspectionTable";
import ReservationModal from "../modal/ReservationModal";
import AlertDialog from "../dialogs/AlretDialog";
import JacarDatePicker from "./JacarDatePicker";
import {PAGE_TYPE_B2C} from "../../routes/inspection/Inspection";
import B2CInspectionCompleteModal from "../modal/B2CInspectionCompleteModal";
import {LoginContext} from "../contexts/LoginContext";

const TextArea = styled(TextareaAutosize)(({theme}) => ({
  width: "100%",
  height: "100%",
}));
const GridContainer = styled(Grid)(({theme}) => ({
  border: "1px solid black",
  borderBottom: "0",
  marginBottom: "15px",
}));
const GridColumn = styled(Grid)(({theme}) => ({
  "&:nth-of-type(odd) div": {
    backgroundColor: "#ebeae9",
  },
}));
const Item = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  textAlign: "center",
  height: "100%",
  color: "black",
  padding: "5px 0",
  borderBottom: "1px solid black",
}));
const B2CInspectionDetail = ({
  selectedInspection,
  setUpdatedData,
  loading,
  setLoading,
}) => {
  const {refreshUserInfo} = useContext(LoginContext);
  const [authCookies] = useCookies();

  const [siteMemo, setSiteMemo] = useState("");
  const [mileage, setMileage] = useState(0);
  const [carRegisteredAt, setCarRegisteredAt] = useState("");
  //const [selectedStep, setSelectedStep] = useState(-1);
  const [selectedReservationType, setSelectedReservationType] =
    useState("미예약");
  const [noshowModalShow, setNoshowModalShow] = useState(false);
  const [failedModalShow, setFailedModalShow] = useState(false);

  const [reservationChangeModalShow, setReservationChangeModalShow] =
    useState(false);

  const [inspectionCompleteDialogShow, setInspectionCompleteDialogShow] =
    useState(false);
  useEffect(() => {
    //setSelectedStep(selectedInspection.current_step);
    setSiteMemo(selectedInspection.site_memo);
    setMileage(selectedInspection.mileage);
    setSelectedReservationType(selectedInspection.reservation_type);
  }, [selectedInspection]);

  const handleSaveClick = async e => {
    e.preventDefault();
    if (Object.keys(selectedInspection).length) {
      const update_data = {
        //current_step: selectedStep,
        site_memo: siteMemo ? siteMemo : null,
        mileage: mileage,
        car_registered_at: carRegisteredAt ? carRegisteredAt : null,
        reservation_type: selectedReservationType,
      };

      try {
        const {status, data} = await b2cInspectionApi.updateInspection(
          authCookies.access_token,
          selectedInspection.id,
          update_data,
        );
        if (status === 200) {
          setUpdatedData({...selectedInspection, ...data});
          alert("검사 정보가 변경되었습니다.");
        } else {
          alert("서버 에러");
        }
      } catch (e) {
        alert("서버 에러");
      }
    }
  };

  return (
    <Box>
      <Box paddingX={"10px"} sx={{flexGrow: 1}}>
        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          차량정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>차량번호</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>{selectedInspection.car_number}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>차량종류</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.car_type}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>검사종류</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>{selectedInspection.inspection_type}</Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>차량등록일</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: "5px"}}>
              <JacarDatePicker
                date={carRegisteredAt}
                setDate={setCarRegisteredAt}
                localStorageName={null}
              />
            </Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>주행거리</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: "5px"}}>
              <TextField
                fullWidth
                name={"mileage"}
                id="mileage"
                type="number"
                value={mileage}
                size="small"
                onChange={e => setMileage(e.target.value)}
              />
            </Item>
          </GridColumn>
        </GridContainer>
        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          고객정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>고객명</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>
              {selectedInspection.user ? selectedInspection.user.name : ""}
            </Item>
          </GridColumn>
          <GridColumn item xs={2}>
            <Item>고객연락처</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>
              {selectedInspection.user
                ? selectedInspection.user.phone_number
                : ""}
            </Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>상세히스토리</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: "5px"}}>
              <TextArea
                minRows={2}
                maxRows={10}
                sx={{fieldset: {borderColor: "black"}}}
                value={
                  selectedInspection.detail_history
                    ? selectedInspection.detail_history
                    : ""
                }
                disabled
              ></TextArea>
            </Item>
          </GridColumn>
        </GridContainer>

        <Typography variant="body1" fontWeight={"700"} marginBottom={"3px"}>
          예약 및 결과정보
        </Typography>
        <GridContainer container>
          <GridColumn item xs={2}>
            <Item>Step Zone</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item sx={{backgroundColor: "transparent", padding: "0"}}>
              <div
                className={
                  "stepzone " +
                  getCurrentStepClassName(
                    selectedInspection.current_step_name,
                    selectedInspection.step_updated_at,
                    PAGE_TYPE_B2C,
                  )
                }
              >
                <div>
                  {selectedInspection.current_step_name
                    ? selectedInspection.current_step_name
                    : ""}
                </div>
              </div>
            </Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>예약유형</Item>
          </GridColumn>
          <GridColumn item xs={4}>
            <Item>
              <FormControl sx={{width: "100%", padding: "0 5px"}} size="small">
                <Select
                  value={selectedReservationType}
                  onChange={e => {
                    setSelectedReservationType(e.target.value);
                  }}
                  sx={{
                    width: "100%",
                    height: "30px",
                    fieldset: {
                      borderColor: "black",
                    },
                  }}
                >
                  <MenuItem value={"미예약"}>미예약</MenuItem>
                  <MenuItem value={InspectionConst.RESERVATION_TYPE1}>
                    {InspectionConst.RESERVATION_TYPE1}
                  </MenuItem>
                  <MenuItem value={InspectionConst.RESERVATION_TYPE2}>
                    {InspectionConst.RESERVATION_TYPE2}
                  </MenuItem>
                </Select>
              </FormControl>
            </Item>
          </GridColumn>

          <GridColumn item xs={2}>
            <Item>예약일시</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>
              {selectedInspection.reservation_date &&
              selectedInspection.reservation_time
                ? selectedInspection.reservation_date +
                  " " +
                  selectedInspection.reservation_time
                : ""}
            </Item>
          </GridColumn>
          {selectedInspection.reservation_type === "픽업" &&
            selectedInspection.pickup_address && (
              <>
                <GridColumn item xs={2}>
                  <Item>픽업주소</Item>
                </GridColumn>
                <GridColumn item xs={10}>
                  <Item>{selectedInspection.pickup_address}</Item>
                </GridColumn>
              </>
            )}

          <GridColumn item xs={2}>
            <Item>검사완료 일자</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item>{selectedInspection.inspection_complete_date}</Item>
          </GridColumn>
          {selectedInspection.assignment_cancel_reason && (
            <>
              <GridColumn item xs={2}>
                <Item>배정 취소 사유</Item>
              </GridColumn>
              <GridColumn item xs={10}>
                <Item>{selectedInspection.assignment_cancel_reason}</Item>
              </GridColumn>
            </>
          )}
          <GridColumn item xs={2}>
            <Item>현장메모</Item>
          </GridColumn>
          <GridColumn item xs={10}>
            <Item sx={{padding: " 5px"}}>
              <TextArea
                minRows={2}
                maxRows={10}
                value={siteMemo ? siteMemo : ""}
                onChange={e => {
                  setSiteMemo(e.target.value);
                }}
                sx={{border: "1px solid black", padding: 1}}
              ></TextArea>
            </Item>
          </GridColumn>
        </GridContainer>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={"5px"}>
            {(selectedInspection.current_step ===
              InspectionConstB2C.STEP_RESERVATION_COMPLETE ||
              selectedInspection.current_step ===
                InspectionConstB2C.STEP_NO_SHOW ||
              selectedInspection.current_step ===
                InspectionConstB2C.STEP_FAIL) && (
              <>
                {/* <Button
                  disabled={loading}
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => {
                    setReservationCancelDialogShow(true);
                  }}
                >
                  예약 취소
                </Button> */}
                <Button
                  disabled={loading}
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => {
                    setReservationChangeModalShow(!reservationChangeModalShow);
                  }}
                >
                  예약 변경
                </Button>
              </>
            )}

            {(selectedInspection.current_step ===
              InspectionConstB2C.STEP_RESERVATION_COMPLETE ||
              selectedInspection.current_step ===
                InspectionConstB2C.STEP_FAIL) && (
              <Button
                disabled={loading}
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  setNoshowModalShow(true);
                }}
              >
                노쇼 처리
              </Button>
            )}

            {(selectedInspection.current_step ===
              InspectionConstB2C.STEP_RESERVATION_COMPLETE ||
              selectedInspection.current_step ===
                InspectionConstB2C.STEP_NO_SHOW) && (
              <Button
                disabled={loading}
                sx={{backgroundColor: "#E0CA00", color: "#000000"}}
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => {
                  setFailedModalShow(true);
                }}
              >
                불합격 처리
              </Button>
            )}
          </Stack>
          {Object.keys(selectedInspection).length > 0 && (
            <Stack direction={"row"} justifyContent={"right"} gap={"5px"}>
              {selectedInspection.current_step !==
                InspectionConstB2C.STEP_RESERVATION_CANCEL &&
                selectedInspection.current_step !==
                  InspectionConstB2C.STEP_INSPECTION_COMPLETE && (
                  <Button
                    disabled={loading}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setInspectionCompleteDialogShow(true);
                    }}
                  >
                    검사 완료 처리
                  </Button>
                )}

              {selectedInspection.current_step !==
                InspectionConstB2C.STEP_RESERVATION_CANCEL && (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSaveClick}
                  >
                    저장
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Box>

      <B2CInspectionCompleteModal
        open={inspectionCompleteDialogShow}
        handleClose={() => {
          setInspectionCompleteDialogShow(false);
        }}
        title={"검사 완료"}
        loading={loading}
        content={
          "다음 유효기간 만료일을 입력하셔야 해당 고객의 다음 자동차 검사 도래일에 재방문유도 알림톡을 전송할 수 있습니다. \n해당 검사건을 정말 검사완료 처리하시겠습니까?"
        }
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setInspectionCompleteDialogShow(false);
        }}
        handleButton2Click={async (mileage, nextExpiredAt) => {
          setLoading(true);
          const update_data = {
            current_step: InspectionConstB2C.STEP_INSPECTION_COMPLETE,
            mileage: mileage,
            next_expired_at: nextExpiredAt,
          };
          try {
            const {status, data} = await b2cInspectionApi.updateInspection(
              authCookies.access_token,
              selectedInspection.id,
              update_data,
            );
            if (status === 200) {
              refreshUserInfo();
              setUpdatedData(data);
              setInspectionCompleteDialogShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            if (status === 402) {
              alert(data);
            } else {
              alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
            }
          } finally {
            setLoading(false);
          }
        }}
        mileage={mileage}
      />

      {/* <AlertDialog
        open={reservationCancelDialogShow}
        handleClose={() => {
          setReservationCancelDialogShow(false);
        }}
        title={"예약 취소"}
        loading={loading}
        content={"예약을 정말 취소하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setReservationCancelDialogShow(false);
        }}
        handleButton2Click={async () => {
          setLoading(true);
          try {
            const {status, data} = await reservationApi.cancelReservation(
              authCookies.access_token,
              selectedInspection.id,
            );
            if (status === 200) {
              setUpdatedData({});
              setReservationCancelDialogShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      /> */}

      <ReservationModal
        open={reservationChangeModalShow}
        setOpen={setReservationChangeModalShow}
        handleClose={() => {
          setReservationChangeModalShow(false);
        }}
        selectedInspection={selectedInspection}
        setUpdatedData={setUpdatedData}
        authCookies={authCookies}
        pageType={PAGE_TYPE_B2C}
      />
      <AlertDialog
        open={noshowModalShow}
        handleClose={() => {
          setNoshowModalShow(false);
        }}
        title={"노쇼 처리"}
        loading={loading}
        content={"해당 검사건을 정말 노쇼처리 하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setNoshowModalShow(false);
        }}
        handleButton2Click={async missedReason => {
          setLoading(true);
          const update_data = {
            current_step: InspectionConstB2C.STEP_NO_SHOW,
          };
          try {
            const {status, data} = await b2cInspectionApi.updateInspection(
              authCookies.access_token,
              selectedInspection.id,
              update_data,
            );
            if (status === 200) {
              setUpdatedData(data);
              setNoshowModalShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />

      <AlertDialog
        open={failedModalShow}
        handleClose={() => {
          setFailedModalShow(false);
        }}
        title={"불합격 처리"}
        loading={loading}
        content={"해당 검사건을 정말 불합격처리 하시겠습니까?"}
        buttonText1={"닫기"}
        buttonText2={"확인"}
        handleButton1Click={() => {
          setFailedModalShow(false);
        }}
        handleButton2Click={async missedReason => {
          setLoading(true);
          const update_data = {
            current_step: InspectionConstB2C.STEP_FAIL,
          };
          try {
            const {status, data} = await b2cInspectionApi.updateInspection(
              authCookies.access_token,
              selectedInspection.id,
              update_data,
            );
            if (status === 200) {
              setUpdatedData(data);
              setFailedModalShow(false);
            }
          } catch (e) {
            const response = e.response;
            const {data, status} = response;

            alert(`서버 에러 ${status}, ${JSON.stringify(data)}`);
          } finally {
            setLoading(false);
          }
        }}
      />
    </Box>
  );
};

export default B2CInspectionDetail;
